import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, PhotoSessionTable, UploadModal } from "../../../components";
import { Spinner } from "../../../components/Spinner";
import { useDisclosure } from "../../../hooks";
import { AdminService } from "../../../network/services";
import { AdminSecHeader, StyledAddButton } from "../components/AdminSecHeader";
import { useTranslation } from "react-i18next";

const PhotoSession = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [prev, setPrev] = useState(null);

  const queryClient = useQueryClient();

  const { mutate } = useMutation((id) => AdminService.deletePhoto(id), {
    onSuccess: (data) => {
      toast.success(`${data.message}`, {
        position: "top-right",
      });
      onClose();
      queryClient.invalidateQueries(["all_photo_sessions"]);
    },
    onError: (err) => {
      toast.error(err.response.data, {
        position: "top-right",
      });
    },
  });

  const onDeleteSubmit = (e, id) => {
    e.preventDefault();
    mutate(id);
  };

  const { data, isLoading } = useQuery(["all_photo_sessions"], () =>
    AdminService.getAllPhotoSession(),
  );

  return (
    <div>
      <AdminSecHeader title={t("admin.sidebar.allPhotoSessions")} />
      <StyledAddButton>
        <Button
          onClick={() => {
            onOpen();
            setPrev(null);
          }}
        >
          {t("buttons.adminTitles.uploadNewPhoto")}
        </Button>
      </StyledAddButton>
      {isLoading ? (
        <Spinner />
      ) : (
        <PhotoSessionTable
          data={data?.photos}
          isScreen
          onEditClick={(obj) => {
            setPrev(obj);
            onOpen();
          }}
          onDeleteClick={(e, id) => onDeleteSubmit(e, id)}
        />
      )}
      <UploadModal isOpen={isOpen} onClose={onClose} prev={prev} />
    </div>
  );
};

export default PhotoSession;

import styled from "styled-components";

export const ScrollToTopButton = styled.button`
  width: 3rem;
  height: 3rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  justify-content: center;
  background: ${({ theme }) => theme.primary};
  border: none;
  cursor: pointer;
  color: #fff;
  /* fill: #fff; */
  :hover {
    border-radius: 10px 3px 10px 3px;
    background: ${({ theme }) => theme.primary_bold};
  }
`;

export const ThemeWrapper = styled.div`
  position: fixed;
  top: 3rem;
  right: 0;
`;

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../../../components";
import { SITE } from "../../../../../utils/settings";
import {
  StyledHomeInstructorItem,
  StyledHomeInstructorsContainer,
  StyledINstructorsScrollHolder,
} from "./styles";

const Instructors = ({ data }) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  // let scrollToken = null;

  // const scroll = (scrollOffset) => {
  //   ref.current.scrollLeft += scrollOffset;
  // };

  // const onMouseOver = (direction) => {
  //   scrollToken = setInterval(() => {
  //     if (direction === "left") {
  //       /**
  //        * calculate width to scrool:
  //        *
  //        * width on individual container for image
  //        * to margin left and right
  //        * multiplied by 2(for srolling 2 images at a time)
  //        *  348.146 + 32px (2rem) * 2
  //        *
  //        */
  //       scroll(-760.292);
  //     } else {
  //       scroll(760.292);
  //     }
  //   }, 1000);
  // };

  // const onMouseLeave = () => {
  //   clearInterval(scrollToken);
  // };

  return (
    <>
      <SectionHeader title={t("headers.ourInstructors")} />
      <StyledINstructorsScrollHolder>
        {/* <StyledArrowContainer>
          <StyledArrow
            left
            onMouseEnter={() => {
              onMouseOver("left");
            }}
            onMouseLeave={onMouseLeave}
          >
            <BiChevronLeft />
          </StyledArrow>
          <StyledArrow
            right
            onMouseEnter={() => {
              onMouseOver("right");
            }}
            onMouseLeave={onMouseLeave}
          >
            <BiChevronRight />
          </StyledArrow>
        </StyledArrowContainer> */}
        <StyledHomeInstructorsContainer ref={ref}>
          {data.map((ins, _idx) => (
            <StyledHomeInstructorItem key={ins._id}>
              <img src={`${SITE.url}${ins.profileImage}`} alt='instructor' />
              <div className='about'>
                <p className='title'>{ins.fullName}</p>
              </div>
            </StyledHomeInstructorItem>
          ))}
        </StyledHomeInstructorsContainer>
      </StyledINstructorsScrollHolder>
    </>
  );
};

export default Instructors;

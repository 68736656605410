import React from "react";
import { Button, Input } from "../../../components";
import { StyledButtonLeft } from "../../../components/ModalComponents/comp.styles";
import { LoginPageContainer, StyledLoginForm } from "./styles";
import { useVerifyCode } from "./useVerifyCode";

const VerifyAccount = () => {
  const { handleFieldChange, fields, errors, onSubmit, isLoading, isDisabled } =
    useVerifyCode();
  return (
    <LoginPageContainer>
      {/* <PageHeader title='Login' /> */}
      <StyledLoginForm>
        <h1 className='formTitle'>Verify account.</h1>
        <Input
          name='code'
          type='text'
          placeholder='Enter verification code sent to your email'
          isRequired
          label='Verification Code'
          onChange={handleFieldChange}
          error={errors.email}
          value={fields.email}
        />
        <StyledButtonLeft>
          <Button
            isLoading={isLoading}
            onClick={onSubmit}
            isDisabled={isDisabled}
          >
            VERIFY
          </Button>
        </StyledButtonLeft>
      </StyledLoginForm>
    </LoginPageContainer>
  );
};

export default VerifyAccount;

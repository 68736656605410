import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AdminService } from "../../../../network/services";
import { validators } from "../../../../utils/validator";

export const useAddEvent = (onClose, prev) => {
  const FORMAT = "yyyy-MM-DD";
  const initialFields = {
    title: "",
    date: "",
    documentLink: "",
  };
  const [previewImage, setPreviewImage] = useState(undefined);
  const [prevImage, setPrevImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleEditorStateChange = (editorState) => setEditorState(editorState);

  const [fields, setFields] = useState(initialFields);
  const [errors, setFormError] = useState(initialFields);

  useEffect(() => {
    setFields(
      prev
        ? { ...prev, date: moment(prev.date).format(FORMAT) }
        : initialFields,
    );
    setPrevImage(prev ? prev.bannerImage : "");
    const blocksFromHtml = htmlToDraft(prev ? prev.content : "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    setEditorState(
      prev
        ? EditorState.createWithContent(contentState)
        : EditorState.createEmpty(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    const error = validators[name](value);
    setFields({ ...fields, [name]: value });
    setFormError((formError) => ({ ...formError, [name]: error }));
  };

  const onSelectedImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  useEffect(() => {
    if (!selectedImage) {
      setPreviewImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  const { title, date, documentLink } = fields;

  const queryClient = useQueryClient();
  const formData = new FormData();
  formData.append("image", selectedImage);
  formData.append("title", title);
  formData.append("documentLink", documentLink);
  formData.append(
    "content",
    draftToHtml(convertToRaw(editorState.getCurrentContent())),
  );
  formData.append("date", date !== "" ? new Date(date).toISOString() : "");

  const { isLoading: isSaveLoading, mutate } = useMutation(
    () => AdminService.addEvent(formData),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        onClose();
        setFields(initialFields);
        setSelectedImage(null);
        setPreviewImage(null);
        setEditorState(EditorState.createEmpty());

        queryClient.invalidateQueries(["all_events"]);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const { isLoading: isLoadingUpdate, mutate: update } = useMutation(
    () => AdminService.updateEvent(formData, prev?._id),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        onClose();
        setFields(initialFields);
        setSelectedImage(null);
        setPreviewImage(null);
        setEditorState(EditorState.createEmpty());

        queryClient.invalidateQueries(["all_events"]);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };
  const onUpdateSubmit = (e) => {
    e.preventDefault();
    update();
  };

  const isDisabled = Object.values(errors).some(Boolean);
  const isLoading = isSaveLoading || isLoadingUpdate;

  return {
    fields,
    onSubmit,
    handleFieldChange,
    errors,
    isDisabled,
    isLoading,
    editorState,
    handleEditorStateChange,
    previewImage,
    onSelectedImage,
    prevImage,
    onUpdateSubmit,
  };
};

import React from "react";
import { NavBar } from "../Navbar";
import { SiteHeaderSettings } from "../SiteHeaderSettings";
import { StyledHeader } from "./Header.styles";

const Header = () => {
  return (
    <StyledHeader>
      <SiteHeaderSettings />
      <NavBar />
    </StyledHeader>
  );
};

export default Header;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { AdminService } from "../../../../network/services";
import { validators } from "../../../../utils/validator";

export const useVideo = (onClose, prev) => {
  const initialFields = {
    videoUrl: "",
  };

  const [fields, setFields] = useState(initialFields);
  const [errors, setFormError] = useState(initialFields);

  useEffect(() => {
    setFields(prev ? prev : initialFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    const error = validators[name](value);
    setFields({ ...fields, [name]: value });
    setFormError((formError) => ({ ...formError, [name]: error }));
  };

  const queryClient = useQueryClient();

  const { isLoading: isSaveLoading, mutate } = useMutation(
    () => AdminService.createVideo(fields),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        queryClient.invalidateQueries(["all_videos"]);
        setFields(initialFields);
        onClose();
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const { isLoading: isUpdateLoading, mutate: update } = useMutation(
    () => AdminService.editVideo(fields, prev._id),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        onClose();
        setFields(initialFields);

        queryClient.invalidateQueries(["all_videos"]);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };
  const onUpdateSubmit = (e) => {
    e.preventDefault();
    update();
  };

  const isLoading = isSaveLoading || isUpdateLoading;
  const isDisabled = Object.values(errors).some(Boolean);

  return {
    fields,
    isDisabled,
    isLoading,
    onUpdateSubmit,
    onSubmit,
    errors,
    handleFieldChange,
  };
};

import React from "react";
import AlternateHeader from "../../../components/AlternateHeader/AlternateHeader";
import {
  StyledEvent,
  StyledEventPage,
  StyledEventsContainer,
} from "./EventsPage.styles";
import { NoResult, SectionHeader, SEO } from "../../../components";
// import draftToHtml from "draftjs-to-html";
import moment from "moment";
import { useEvents } from "./useEvents";
import { useTranslation } from "react-i18next";
import { SITE } from "../../../utils/settings";

const EventsPage = () => {
  const { t } = useTranslation();
  const { data } = useEvents();

  const upcoming =
    data?.events.length === 1
      ? []
      : data?.events.sort((a, b) => {
          return (
            Math.abs(Date.now() - new Date(a.date)) -
            Math.abs(Date.now() - new Date(b.date))
          );
        });

  const createMarkup = (tags) => {
    return {
      __html: tags,
    };
  };

  // const defaultSettings = createMarkup(draftToHtml(convertToRaw(editorState.getCurrentContent())))

  return (
    <StyledEventPage>
      <SEO title={t("meta.title.events")} />
      <AlternateHeader title={t("headers.events")} />
      <StyledEventsContainer>
        <div className='wrapper'>
          {(upcoming ?? []).length > 0 && (
            <StyledEvent to={`/events/${upcoming[0]?._id}`} w='100%'>
              <div className='img_holder'>
                <img
                  src={`${SITE.url}${upcoming[0]?.bannerImage}`}
                  alt='event_image'
                />
              </div>
              <div className='evt_date'>
                <p className='date'>{moment(upcoming[0]?.date).format("DD")}</p>
                <p className='month'>
                  {moment(upcoming[0]?.date).format("MMM")}
                </p>
                <p className='year'>
                  {moment(upcoming[0]?.date).format("YYYY")}
                </p>
              </div>
              <div className='bottom'>
                <p className='title'>{upcoming[0]?.title}</p>
                <div
                  className='content'
                  dangerouslySetInnerHTML={createMarkup(
                    upcoming[0]?.content.length > 300
                      ? upcoming[0]?.content.substring(0, 300)
                      : upcoming[0]?.content,
                  )}
                />
              </div>
            </StyledEvent>
          )}
        </div>
        {data?.events.length > 1 && (
          <div className='sec_header'>
            <SectionHeader title={t("headers.upComing")} />
          </div>
        )}
        {data?.events.length === 0 && (
          <NoResult message='No Results Found for your query.' />
        )}
        <div className='container'>
          {data?.events.length > 1
            ? data?.events.map((evt, _idx) => (
                <StyledEvent to={`/events/${evt?._id}`} key={_idx}>
                  <div className='img_holder'>
                    <img
                      src={`${SITE.url}${evt.bannerImage}`}
                      alt='event_image'
                    />
                  </div>
                  <div className='evt_date'>
                    <p className='date'>{moment(evt.date).format("DD")}</p>
                    <p className='month'>{moment(evt.date).format("MMM")}</p>
                    <p className='year'>{moment(evt.date).format("YYYY")}</p>
                  </div>
                  <div className='bottom'>
                    <p className='title'>{evt.title}</p>
                    <div
                      className='content'
                      dangerouslySetInnerHTML={createMarkup(
                        evt.content.length > 300
                          ? evt.content.substring(0, 300)
                          : evt.content,
                      )}
                    />
                  </div>
                </StyledEvent>
              ))
            : data?.events.map((evt, idx) => (
                <StyledEvent to={`/events/${evt?._id}`} w='100%' key={idx}>
                  <div className='img_holder'>
                    <img
                      src={`${SITE.url}${evt.bannerImage}`}
                      alt='event_image'
                    />
                  </div>
                  <div className='evt_date'>
                    <p className='date'>{moment(evt.date).format("DD")}</p>
                    <p className='month'>{moment(evt.date).format("MMM")}</p>
                    <p className='year'>{moment(evt.date).format("YYYY")}</p>
                  </div>
                  <div className='bottom'>
                    <p className='title'>{evt.title}</p>
                    <div
                      className='content'
                      dangerouslySetInnerHTML={createMarkup(
                        evt.content.length > 300
                          ? evt.content.substring(0, 300)
                          : evt.content,
                      )}
                    />
                  </div>
                </StyledEvent>
              ))}
        </div>
      </StyledEventsContainer>
    </StyledEventPage>
  );
};

export default EventsPage;

import { t } from "i18next";
import React from "react";
import { Button } from "../../../Button";
import { Input } from "../../../Input";
import Modal from "../../../Modal/Modal";
import { StyledButtonLeft } from "../../comp.styles";
import { ImagesContainer } from "../AddNewEvent/event.styles";
import { useLocation } from "./useLocation";
import { useTranslation } from "react-i18next";
import { SITE } from "../../../../utils/settings";

const AddNewLocationModal = ({ isOpen, onClose, prev }) => {
  const { t } = useTranslation();
  const {
    fields,
    handleFieldChange,
    errors,
    isLoading,
    isDisabled,
    onUpdateSubmit,
    onSubmit,
    previewImage,
    onSelectedImage,
    prevImage,
  } = useLocation(onClose, prev);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("buttons.adminTitles.addNewLocation")}
    >
      <Input
        placeholder={t("placeholders.enterCity")}
        type='text'
        label={t("admin.table.city")}
        isRequired
        name='city'
        value={fields.city}
        onChange={handleFieldChange}
        error={errors.city}
      />
      <Input
        placeholder={t("placeholders.enterAddress")}
        type='text'
        label={t("admin.table.address")}
        isRequired
        name='address'
        value={fields.address}
        onChange={handleFieldChange}
        error={errors.address}
      />
      <Input
        placeholder={t("admin.photo.uploadPhoto")}
        type='file'
        label={t("admin.table.image")}
        isRequired
        onChange={onSelectedImage}
      />
      <ImagesContainer>
        {previewImage && (
          <div className='container'>
            <p>{t("admin.photo.previewImage")}</p>
            <img src={previewImage} alt='preview' />
          </div>
        )}
        {prevImage && (
          <div className='container'>
            <p>{t("admin.photo.previewImage")}</p>
            <img src={`${SITE.url}${prevImage}`} alt='preview' />
          </div>
        )}
        {/* <img src={prevImage} /> */}
      </ImagesContainer>

      <StyledButtonLeft>
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={prev ? onUpdateSubmit : onSubmit}
        >
          {t("buttons.save")}
        </Button>
      </StyledButtonLeft>
    </Modal>
  );
};

export default AddNewLocationModal;

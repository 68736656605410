import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  AddNewLocationModal,
  Button,
  LocationTable,
} from "../../../components";
import { Spinner } from "../../../components/Spinner";
import { useDisclosure } from "../../../hooks";
import { AdminService, GeneralService } from "../../../network/services";
import { AdminSecHeader, StyledAddButton } from "../components/AdminSecHeader";
import { useTranslation } from "react-i18next";

const LocationScreen = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery(["all_locations"], () =>
    GeneralService.getAllLocations(),
  );
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [prev, setPrev] = useState();

  const queryClient = useQueryClient();

  const { mutate } = useMutation((id) => AdminService.deleteLocation(id), {
    onSuccess: (data) => {
      toast.success(`${data.message}`, {
        position: "top-right",
      });
      onClose();
      queryClient.invalidateQueries(["all_locations"]);
    },
    onError: (err) => {
      toast.error(err.response.data, {
        position: "top-right",
      });
    },
  });

  const handleDelete = (e, id) => {
    e.preventDefault();
    mutate(id);
  };

  return (
    <div>
      <AdminSecHeader title={t("admin.table.location_address")} />
      <StyledAddButton>
        <Button
          onClick={() => {
            onOpen();
            setPrev(null);
          }}
        >
          {t("buttons.adminTitles.addNewLocation")}
        </Button>
      </StyledAddButton>
      {isLoading ? (
        <Spinner />
      ) : (
        <LocationTable
          data={data?.locations}
          isScreen
          onEditClick={(data) => {
            onOpen();
            setPrev(data);
          }}
          onDeleteClick={(e, id) => handleDelete(e, id)}
        />
      )}
      <AddNewLocationModal isOpen={isOpen} onClose={onClose} prev={prev} />
    </div>
  );
};

export default LocationScreen;

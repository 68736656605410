import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "../../../Button";
import { Input, Select } from "../../../Input";
import Modal from "../../../Modal/Modal";
import { StyledButtonLeft } from "../../comp.styles";
import { StyledContainerGroup, StyledTable } from "./styles";
import { usePricing } from "./usePricing";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "../../../../utils/constants";

const AddNewPricingModal = ({ isOpen, onClose, prev }) => {
  const { t } = useTranslation();
  const {
    fields,
    isDisabled,
    isLoading,
    onUpdateSubmit,
    onSubmit,
    onDeleteClick,
    errors,
    handleFieldChange,
    onAddClick,
    addedCategories,
    cityOptions,
  } = usePricing(onClose, prev);

  const pricingOptions = [
    { label: "12 yrs old", value: CONSTANTS.twelveYrsOld },
    { label: "Formations", value: CONSTANTS.formations },
    { label: "Full Pass", value: CONSTANTS.fullPass },
    { label: "Over 12 yrs old", value: CONSTANTS.overTwelveYrs },
    { label: "Individual Classes", value: CONSTANTS.individual },
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t("prices.title")}>
      <Select
        placeholder={t("placeholders.selectPriceCategory")}
        label={t("prices.priceCategory")}
        value={fields.pricingType}
        isRequired
        name='pricingType'
        onChange={handleFieldChange}
        options={pricingOptions}
        error={errors.pricingType}
      />
      <Input
        placeholder={t("placeholders.enterPriceTitle")}
        type='text'
        label={t("prices.priceLabel")}
        isRequired
        name='category'
        value={fields.category}
        onChange={handleFieldChange}
        error={errors.category}
      />
      <Select
        placeholder={t("placeholders.selectLocation")}
        label={t("fields.add/loc")}
        value={fields.location}
        isRequired
        name='location'
        onChange={handleFieldChange}
        options={cityOptions}
        error={errors.location}
      />
      {fields.pricingType !== CONSTANTS.formations && (
        <>
          <Input
            placeholder={t("placeholders.enterOneTimePrice")}
            type='text'
            label={t("prices.oneTimePrice")}
            isRequired
            name='oneTimePrice'
            value={fields.oneTimePrice}
            onChange={handleFieldChange}
            error={errors.oneTimePrice}
          />
          <Input
            placeholder={t("placeholders.enterPercentageSaved")}
            type='text'
            label={t("prices.save%")}
            isRequired
            name='percentageSaved'
            value={fields.percentageSaved}
            onChange={handleFieldChange}
            error={errors.percentageSaved}
          />
        </>
      )}
      {fields.pricingType !== CONSTANTS.fullPass &&
        fields.pricingType !== CONSTANTS.individual && (
          <>
            <Input
              placeholder={t("placeholders.enterContractHeaderTitle")}
              type='text'
              label={t("prices.price_contract")}
              isRequired
              name='categoryHeader'
              value={fields.categoryHeader}
              onChange={handleFieldChange}
              error={errors.categoryHeader}
            />
            <Input
              placeholder={t("placeholders.contractTableTitle")}
              type='text'
              label={t("prices.contractTableTitle")}
              isRequired
              name='categoryTitle'
              value={fields.categoryTitle}
              onChange={handleFieldChange}
              error={errors.categoryTitle}
            />
            <Input
              placeholder={t("placeholders.contractPriceTitle")}
              type='text'
              label={t("prices.contractPriceTitle")}
              isRequired
              name='categoryPriceTitle'
              value={fields.categoryPriceTitle}
              onChange={handleFieldChange}
              error={errors.categoryPriceTitle}
            />
            {/* <p>{fields.categoryHeader}</p> */}
            {fields.categoryPriceTitle && fields.categoryTitle && (
              <StyledTable>
                <div className='tr'>
                  <div className='th'>
                    <p>{fields.categoryTitle}</p>
                  </div>
                  <div className='th'>
                    <p>{fields.categoryPriceTitle}</p>
                  </div>
                </div>
                {addedCategories.map((cat) => (
                  <div className='tr' key={cat.id}>
                    <div className='td'>
                      <p>{cat.title}</p>
                    </div>
                    <div className='td'>
                      <p>{cat.price}</p>
                    </div>
                    <AiOutlineClose
                      className='delete_icon'
                      onClick={() => onDeleteClick(cat.id)}
                    />
                  </div>
                ))}
              </StyledTable>
            )}
            <StyledContainerGroup>
              <div className='content'>
                <Input
                  placeholder={t("placeholders.enterContractTitle")}
                  type='text'
                  label={t("prices.contractTitle")}
                  isRequired
                  name='contractTitle'
                  value={fields.contractTitle}
                  onChange={handleFieldChange}
                  error={errors.contractTitle}
                />
                <Input
                  placeholder={t("placeholders.enterContractPrice")}
                  type='text'
                  label={t("prices.contractPrice")}
                  isRequired
                  name='contractPrice'
                  value={fields.contractPrice}
                  onChange={handleFieldChange}
                  error={errors.contractPrice}
                />
              </div>
              <Button onClick={() => onAddClick()}>
                {t("prices.addContract")}
              </Button>
            </StyledContainerGroup>
          </>
        )}
      <StyledButtonLeft>
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={prev ? onUpdateSubmit : onSubmit}
        >
          {t("prices.save")}
        </Button>
      </StyledButtonLeft>
    </Modal>
  );
};

export default AddNewPricingModal;

import React from "react";
import styled from "styled-components";
import Modal from "../Modal/Modal";

const StyledImageContainer = styled.div`
  width: 55%;
  height: 55%;
  img {
    width: 100%;
    height: 100%;
  }
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImagePreviewModal = ({ isOpen, onClose, preview }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContainer>
        <StyledImageContainer>
          <img src={preview ?? ""} alt='studio-tanca-p-session' />
        </StyledImageContainer>
      </ModalContainer>
    </Modal>
  );
};

export default ImagePreviewModal;

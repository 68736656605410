import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "../../../Button";
import { Input, Select } from "../../../Input";
import Modal from "../../../Modal/Modal";
import { StyledButtonLeft } from "../../comp.styles";
import { StyledFlex, StyledFlexContainer } from "./styles";
import { useAddClass } from "./useAddClass";
import { useTranslation } from "react-i18next";

const AddNewDanceStyleModal = ({ isOpen, onClose, prev }) => {
  const { t } = useTranslation();
  const {
    fields,
    onSubmit,
    isPoznan,
    handleFieldChange,
    errors,
    isDisabled,
    selectedInstructors,
    disabledSelect,
    instructorOptions,
    handleInstructorRemove,
    isLoading,
    onUpdateSubmit,
    getInsName,
    cityOptions,
  } = useAddClass(onClose, prev);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        prev
          ? `${t("buttons.edit")} ${prev.name}`
          : `${t("buttons.adminTitles.addNewClass")}`
      }
    >
      <Input
        placeholder={t("placeholders.enterClassName")}
        type='text'
        label={t("headers.classes")}
        isRequired
        error={errors.name}
        onChange={handleFieldChange}
        name='name'
        value={fields.name}
      />
      {selectedInstructors.length > 0 && (
        <p>{selectedInstructors.length} selected instructors</p>
      )}

      <StyledFlexContainer>
        {selectedInstructors.map((instructor, index) => (
          <StyledFlex key={index}>
            <p>{getInsName(instructor) ?? ""}</p>
            <AiOutlineClose
              onClick={() => handleInstructorRemove(instructor)}
            />
          </StyledFlex>
        ))}
      </StyledFlexContainer>

      <Select
        placeholder={t("placeholders.selectInstructors")}
        name='instructors'
        isRequired
        label={t("select.selectInstructors")}
        onChange={handleFieldChange}
        error={errors.instructors}
        disabled={disabledSelect}
        options={instructorOptions}
      />
      <Select
        placeholder={t("placeholders.selectLocation")}
        name='location'
        value={fields.location}
        isRequired
        label={t("placeholders.selectLocation")}
        onChange={handleFieldChange}
        error={errors.location}
        options={cityOptions}
      />
      <Select
        name='day'
        label={t("select.label")}
        placeholder={t("placeholders.selectDay")}
        onChange={handleFieldChange}
        value={fields.day}
        isRequired
        error={errors.day}
        options={
          isPoznan
            ? [
                { value: "monday", label: `${t("days.monday")}` },
                { value: "tuesday", label: `${t("days.tuesday")}` },
                { value: "wednesday", label: `${t("days.wednesday")}` },
                { value: "thursday", label: `${t("days.thursday")}` },
                { value: "friday", label: `${t("days.friday")}` },
              ]
            : [
                { value: "tuesday", label: `${t("days.tuesday")}` },
                { value: "friday", label: `${t("days.friday")}` },
              ]
        }
      />

      <Input
        placeholder={t("placeholders.enterStartTime")}
        type='time'
        label={t("admin.table.beginTime")}
        onChange={handleFieldChange}
        isRequired
        error={errors.beginTime}
        name='beginTime'
        value={fields.beginTime}
      />
      <Input
        placeholder={t("placeholders.enterEndTime")}
        type='time'
        label={t("admin.table.endTime")}
        error={errors.endTime}
        onChange={handleFieldChange}
        isRequired
        name='endTime'
        value={fields.endTime}
      />
      <StyledButtonLeft>
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={prev ? onUpdateSubmit : onSubmit}
        >
          {t("buttons.save")}
        </Button>
      </StyledButtonLeft>
    </Modal>
  );
};

export default AddNewDanceStyleModal;

export const sizes = {
  xs: "0.6rem",
  sm: "0.9rem",
  md: "1.2rem",
  lg: "1.7rem",
};

const breakpointes = {
  fold: "285px",
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const devices = {
  fold: `(max-width: ${breakpointes.fold})`,
  mobileS: `(max-width: ${breakpointes.mobileS})`,
  mobileM: `(max-width: ${breakpointes.mobileM})`,
  mobileL: `(max-width: ${breakpointes.mobileL})`,
  tablet: `(max-width: ${breakpointes.tablet})`,
  laptop: `(max-width: ${breakpointes.laptop})`,
  laptopL: `(max-width: ${breakpointes.laptopL})`,
  desktop: `(max-width: ${breakpointes.desktop})`,
  desktopL: `(max-width: ${breakpointes.desktop})`,
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Input, Select } from "../Input";
import Modal from "../Modal/Modal";
import {
  StyledButtonLeft,
  StyledCheckBoxesContainer,
  StyledLabel,
} from "./comp.styles";
import { useJoinUsNow } from "./useJoinUsNow";

const JoinUsModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const {
    fields,
    isLoading,
    onSubmit,
    handleFieldChange,
    errors,
    isDisabled,
    danceStylesOptions,
    cityOptions,
    handleCheckboxChange,
    checkedState,
  } = useJoinUsNow(onClose);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t("hero.joinNow")}>
      <Input
        placeholder={t("placeholders.entFullName")}
        type='text'
        label={t("fields.fullName")}
        isRequired
        name='fullName'
        value={fields.fullName}
        onChange={handleFieldChange}
        error={errors.fullName}
      />
      <Input
        placeholder={t("placeholders.entEmailAddress")}
        type='email'
        label={t("fields.email")}
        isRequired
        name='email'
        value={fields.email}
        onChange={handleFieldChange}
        error={errors.email}
      />
      <Input
        placeholder={t("placeholders.entPhoneNumber")}
        type='tel'
        label={t("fields.phoneNumber")}
        isRequired
        name='phoneNumber'
        value={fields.phoneNumber}
        onChange={handleFieldChange}
        error={errors.phoneNumber}
      />
      <Select
        placeholder={t("placeholders.selectLocation")}
        label={t("fields.add/loc")}
        isRequired
        name='location'
        onChange={handleFieldChange}
        options={cityOptions}
        error={errors.location}
      />
      {/* {fetchDanceStyledLoading ? (
        <Spinner />
      ) : ( */}
      <StyledCheckBoxesContainer>
        {danceStylesOptions?.map((style, idx) => (
          <StyledLabel key={idx}>
            <input
              value={style.value}
              onChange={() => handleCheckboxChange(idx)}
              type='checkbox'
              name={style.label}
              checked={checkedState[idx]}
              style={{ marginRight: "1rem" }}
            />
            {style.label}
          </StyledLabel>
        ))}
      </StyledCheckBoxesContainer>
      {/* )} */}

      <StyledButtonLeft>
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={onSubmit}
        >
          {t("hero.joinNow")}
        </Button>
      </StyledButtonLeft>
    </Modal>
  );
};

export default JoinUsModal;

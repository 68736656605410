import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import { useTheme } from "./hooks";
import AppRouter from "./routers/AppRouter";
import { darkTheme, GlobalStyle, lightTheme } from "./styles";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const queryClient = new QueryClient();
  const { theme } = useTheme();
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
          <GlobalStyle />
          <ToastContainer />
          <ReactQueryDevtools initialIsOpen={false} />
          <AppRouter />
        </ThemeProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;

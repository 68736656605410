import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "../../../components/Button";
import { Input, TextArea } from "../../../components/Input";
import { StyledButtonLeft } from "../../../components/ModalComponents/comp.styles";
import { AdminService, GeneralService } from "../../../network/services";
import { validators } from "../../../utils/validator";
import { AdminSecHeader } from "../components/AdminSecHeader";
import { StyledSettingsPage } from "./styles";

const Settings = () => {
  const initialState = {
    email: "",
    aboutUs: "",
    instagramLink: "",
    facebookLink: "",
    twitterLink: "",
    phoneNumber: "",
    linkedInLink: "",
  };

  const { data } = useQuery(["settings"], () =>
    GeneralService.getContactDetails(),
  );

  useEffect(() => {
    setFields(data ? data : initialState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [fields, setFields] = useState(initialState);
  const [errors, setFormError] = useState(initialState);

  const queryClient = useQueryClient();

  const handleFIeldChange = (e) => {
    const { name, value } = e.target;

    const error = validators[name](value);
    setFormError((formError) => ({ ...formError, [name]: error }));
    setFields((prev) => ({ ...prev, [name]: value }));
  };

  const { isLoading, mutate } = useMutation(
    () => AdminService.editSettings(fields),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        queryClient.invalidateQueries(["settings"]);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };
  const isDisabled = Object.values(errors).some(Boolean);

  return (
    <StyledSettingsPage>
      <AdminSecHeader title='Edit Site Settings' />
      <div className='content'>
        <Input
          label='Email'
          type='email'
          placeholder='Enter company email address'
          name='email'
          value={fields.email}
          error={errors.email}
          onChange={handleFIeldChange}
        />
        <TextArea
          label='AboutUs'
          type='text'
          placeholder='Enter company about us'
          name='aboutUs'
          value={fields.aboutUs}
          error={errors.aboutUs}
          onChange={handleFIeldChange}
        />
        <Input
          label='Phone Number'
          type='text'
          placeholder='Enter company phone number'
          name='phoneNumber'
          value={fields.phoneNumber}
          error={errors.phoneNumber}
          onChange={handleFIeldChange}
        />
        <Input
          label='Instagram Link'
          type='text'
          placeholder='Enter company Instagram Link'
          name='instagramLink'
          value={fields.instagramLink}
          error={errors.instagramLink}
          onChange={handleFIeldChange}
        />
        <Input
          label='Facebook link'
          type='text'
          placeholder='Enter company Facebook Link'
          name='facebookLink'
          value={fields.facebookLink}
          error={errors.facebookLink}
          onChange={handleFIeldChange}
        />
        <Input
          label='LinkedIn link'
          type='text'
          placeholder='Enter company LinkedIn Link'
          name='linkedInLink'
          value={fields.linkedInLink}
          error={errors.linkedInLink}
          onChange={handleFIeldChange}
        />
        <Input
          label='Twitter Link'
          type='text'
          placeholder='Enter company twitter Link'
          value={fields.twitterLink}
          error={errors.twitterLink}
          name='twitterLink'
          onChange={handleFIeldChange}
        />

        <StyledButtonLeft>
          <Button
            isLoading={isLoading}
            onClick={onSubmit}
            isDisabled={isDisabled}
          >
            Save
          </Button>
        </StyledButtonLeft>
      </div>
    </StyledSettingsPage>
  );
};

export default Settings;

import styled from "styled-components";

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.container};
  border-radius: 0.5rem;
  p {
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.text};
  }

  svg {
    color: ${({ theme }) => theme.mainRed};
    opacity: 0.8;
    cursor: pointer;
    :hover {
      opacity: 1;
    }
  }
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

// color='primaryRed'
// opacity='0.8'
// _hover={{ opacity: 1 }}
// cursor='pointer'

import styled, { css } from "styled-components";
import bg from "../../assets/images/group_pose.png";
import { devices } from "../../styles";

export const StyledpageHeaderContainer = styled.section`
  /* 1idth:; */
  width: 90%;
  margin: 0 auto;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  max-height: 40vh;
  /* padding: 0px 6rem; */
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  /* overflow: hidden; */
  @media ${devices.tablet} {
    position: relative;
    width: 100%;
    padding: 2rem 5%;
    z-index: 0;
    flex-direction: column;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: contain;
    /* padding: 0; */
    background-attachment: fixed;
    /* margin: 0; */
    &:before {
      background: rgba(0, 0, 0, 0.8);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }
`;

export const StyledPageHeaderLeftContainer = styled.div`
  width: ${({ noImage }) => (noImage ? "100%" : "30%")};
  ${({ center }) =>
    center &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  @media ${devices.tablet} {
    width: 100%;
  }
  h1 {
    font-size: 2.7rem;
    color: ${({ theme }) => theme.primary_bold};
    text-transform: uppercase;
    font-weight: bold;
    /* font-family: "Bubblegum Sans", cursive; */
    margin: 1rem 0px;
    @media ${devices.tablet} {
      font-size: 1.7rem;
      font-weight: 100;
    }
  }
  .content {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .section_description {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.gray};
    line-height: 1.1rem;
    font-weight: 100;
    @media ${devices.tablet} {
      font-size: 0.7rem;
      line-height: 1.2rem;
    }
  }

  .section_small_container {
    /* background: red; */
    width: 50%;
    position: relative;
    margin-bottom: 1.1rem;
    .section_small_title {
      font-size: 1.1rem;
      text-transform: uppercase;
      color: ${({ theme }) => theme.primary_bold};
      @media ${devices.tablet} {
        font-size: 0.8rem;
        font-weight: 100;
      }
    }
    :after {
      content: "";
      position: absolute;
      left: 10%;
      top: 100%;
      margin-top: 0.3rem;
      /* margin-top: 2rem; */
      width: 6rem;
      height: 0.05rem;
      background: ${({ theme }) => theme.primary};
      @media ${devices.tablet} {
        left: 0;
        width: 4rem;
      }
    }
  }
`;

export const StyledPageHeaderRightContainer = styled.div`
  width: 69%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  @media ${devices.tablet} {
    width: 100%;
  }
  /* align-items: center; */
  /* background: red; */
  img {
    width: 75%;
    height: 100%;
    object-fit: cover;
    &.contain {
      object-fit: contain;
    }
    object-position: top;
    /* opacity: 0.5; */
    @media ${devices.tablet} {
      width: 100%;
      display: none;
    }
    /* filter: blur(10px); */
  }
`;

import React from "react";
import { useTranslation } from "react-i18next";
import { SEO } from "../../../components";
import { Bar } from "../../../components/Bar";
import { useGetLocations } from "../../../network/queries";
import { SITE } from "../../../utils/settings";
import StudioItem from "./StudioItem/StudioItem";

const Studio = () => {
  const { t } = useTranslation();
  const { data } = useGetLocations();

  const filterLocations = data?.locations.filter(
    (loc) => loc.imageUrl !== ("" || undefined),
  );

  return (
    <div>
      <SEO title={t("meta.title.studio")} />
      {filterLocations?.map((loc, _idx) => (
        <>
          <StudioItem city={loc.city} image={`${SITE.url}${loc.imageUrl}`} />
          <Bar />
        </>
      ))}
    </div>
  );
};

export default Studio;

import React from "react";
import { StyledContainer } from "./adminsec.styles";

const AdminSecHeader = ({ title, small }) => {
  return (
    <StyledContainer small={small}>
      <h3>{title}</h3>
    </StyledContainer>
  );
};

export default AdminSecHeader;

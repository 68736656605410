import styled from "styled-components";
import { devices } from "../../../styles";

export const InstructorsContainer = styled.section`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  /* background: yellow; */
  grid-gap: 1.4rem;
  width: 90%;
  margin: 10rem auto;
  @media ${devices.tablet} {
    flex-direction: column;
    margin: 4rem auto;
  }
`;

export const StyledInstructorContainer = styled.section`
  width: 80%;
  margin: 5rem auto;
  /* background: ${({ theme }) => theme.white_inverse}; */
  border-radius: 4px;
  padding: 3rem;
  @media ${devices.tablet} {
    margin: 0 auto;
    padding: 1.5rem;
  }
`;

export const StyledAboutInstructor = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: space-between;
  @media ${devices.laptop} {
    flex-direction: column;
  }
  /* background: red; */
  width: 100%;
  .image_holder {
    /* background: ${({ theme }) => theme.white_inverse}; */
    /* background: white; */
    padding: 2rem;
    /* transform: rotate(0deg); */
    width: 55%;
    height: 40rem;
    filter: grayscale(60%);
    :hover {
      /* transform: rotate(-3deg); */
      filter: grayscale(0);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media ${devices.tablet} {
      width: 100%;
      margin: 0 auto;
      /* display: none; */
    }
  }
  .about_instructor {
    width: 45%;

    .instructor_name {
      color: ${({ theme }) => theme.primary_bold};
      font-weight: bold;
      font-size: 2.2rem;
      text-transform: capitalize;
    }
    .instructor_style {
      font-weight: 100;
      font-size: 1.1rem;
      opacity: 0.4;
      text-transform: capitalize;
      margin-top: 0.9rem;
    }
    .instructor_desc {
      margin-top: 1rem;
      font-size: 1.1rem;
      font-weight: 100;
      line-height: 1.8rem;
      color: ${({ theme }) => theme.gray};
      @media ${devices.laptop} {
        dont-size: 1rem;
      }
    }
    @media ${devices.tablet} {
      width: 100%;
      margin: 0 auto;
      /* display: none; */
    }
  }
`;

export const InstructorsSocial = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0px;
`;
export const InstructorSocialIcon = styled.a`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  padding: 0.7rem;
  cursor: pointer;
  :hover {
    svg {
      &.facebook {
        fill: #4267b2;
      }
      &.instagram {
        fill: #d33e52;
      }
      &.tiktok {
        fill: #00f2ea;
      }
      fill: ${({ theme }) => theme.primary_bold};
    }
  }
  svg {
    margin: 0px 0.3rem;
    transition: 0.4s ease;
    color: ${({ theme }) => theme.gray_50};
    width: 1.3rem;
    height: 1.3rem;
  }
`;

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { SuzProfile } from "../../../assets/images";
import { Button, Input, PageHeader, SEO, TextArea } from "../../../components";
import { useGetLocations } from "../../../network/queries";
import {
  StyledAboutUsContainer,
  StyledContactContainer,
  StyledIconFlex,
} from "./Contact.styles";
import { useContactUs } from "./useContactUs";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const ContactPage = () => {
  const position = [52.4094872, 16.8753097];
  // const poznanLocation = [52.0887, 17.0151];
  const poznanLocation = [52.4094872, 16.8753097];

  const {
    fields,
    errors,
    data,
    isDisabled,
    isLoading,
    onSubmit,
    handleFieldChange,
  } = useContactUs();
  const { t } = useTranslation();

  const { data: locations } = useGetLocations();

  return (
    <div>
      <SEO title={t("meta.title.contact")} description="Contact us" />
      <PageHeader title={t("navbar.contact")} />
      <StyledContactContainer>
        <div className='leftContent'>
          <Input
            placeholder={t("placeholders.entFullName")}
            type='text'
            label={t("fields.fullName")}
            isRequired
            alt
            name='fullName'
            value={fields.fullName}
            onChange={handleFieldChange}
            error={errors.fullName}
          />
          <Input
            placeholder={t("placeholders.entEmailAddress")}
            type='email'
            alt
            label={t("fields.email")}
            isRequired
            name='email'
            value={fields.email}
            onChange={handleFieldChange}
            error={errors.email}
          />
          <Input
            placeholder={t("placeholders.entSubject")}
            type='text'
            alt
            label={t("fields.subject")}
            isRequired
            name='subject'
            value={fields.subject}
            onChange={handleFieldChange}
            error={errors.subject}
          />
          <TextArea
            placeholder={t("placeholders.entMessage")}
            type='text'
            label={t("fields.message")}
            alt
            isRequired
            name='message'
            value={fields.message}
            onChange={handleFieldChange}
            error={errors.message}
          />
          <Button
            onClick={onSubmit}
            isLoading={isLoading}
            isDisabled={isDisabled}
          >
            {t("send")}
          </Button>
        </div>
        <div className='rightContent'>
          <StyledAboutUsContainer>
            <div className='top'>
              <div className='image_holder'>
                <img src={SuzProfile} alt='profile' />
              </div>
              <div>
                <p className='title'>{t("contact.text1")}</p>
                <p>{t("contact.text2")}</p>
              </div>
            </div>
            <p className='description'>{data?.aboutUs ?? ""}</p>
            {locations?.locations.map((loc, _idx) => (
              <div className='info_container' key={loc._id}>
                <p className='title'>
                  {t("address")} ({loc.city}):{" "}
                </p>
                <p>{loc.address}</p>
              </div>
            ))}
            {data?.phoneNumber && (
              <div className='info_container'>
                <p className='title'>{t("phone")}: </p>
                <p>{data?.phoneNumber ?? ""}</p>
              </div>
            )}
            {data?.email && (
              <div className='info_container'>
                <p className='title'>{t("email_address")}: </p>
                <p>{data?.email ?? ""}</p>
              </div>
            )}
            <StyledIconFlex>
              {data?.facebookLink && (
                <a
                  href={data?.facebookLink ?? ""}
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiOutlineFacebook />
                </a>
              )}
              {data?.instagramLink && (
                <a
                  href={data?.instagramLink ?? ""}
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiOutlineInstagram />
                </a>
              )}
              {data?.twitterLink && (
                <a
                  href={data?.twitterLink ?? ""}
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiOutlineTwitter />
                </a>
              )}
              {data?.linkedInLink && (
                <a
                  href={data?.linkedInLink ?? ""}
                  target='_blank'
                  rel='noreferrer'
                >
                  <AiOutlineLinkedin />
                </a>
              )}
            </StyledIconFlex>
          </StyledAboutUsContainer>
        </div>
      </StyledContactContainer>
      <MapContainer
        center={position}
        zoom={20}
        style={{ height: "50vh" }}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {/* <Marker position={sremLocation}>
          <Popup>Srem Location</Popup>
        </Marker> */}
        <Marker position={poznanLocation}>
          <Popup>ul. Świt 34/36, 60-376 Poznan Poland</Popup>
        </Marker>
      </MapContainer>
      ,
    </div>
  );
};

export default ContactPage;

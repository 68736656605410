import { useQuery } from "@tanstack/react-query";
import { GeneralService } from "../services";

export const useGetDanceStylesByFilter = (filter) => {
  const { data, isLoading, refetch } = useQuery(
    ["dance_styles_by_filter", filter ?? "poznan"],
    () => GeneralService.getDanceStylesByFilter(filter ?? "poznan"),

    {
      enabled: false,
      retry: 1,
      onSuccess: (data) => {
        console.log("Done");
      },
      onError: (err) => {
        console.log(err);
      },
    },
  );
  return { data, isLoading, refetch };
};

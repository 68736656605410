import styled from "styled-components";
import { devices } from "../../../../../styles";

export const StyledVideoSection = styled.section`
  width: 80%;
  margin: 0 auto;
  .vid_gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .video {
      width: 30%;
      margin: 1.5rem 1rem;
      iframe {
        width: 100%;
        height: 15rem;
      }
      @media ${devices.tablet} {
        width: 100%;
      }
    }
  }
`;

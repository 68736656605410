import { t } from "i18next";
import React from "react";
import { Button } from "../Button";
import {
  BtnContainer,
  STD,
  STH,
  STR,
  StyledTable,
  StyledTBody,
  StyledTHead,
} from "./table.styles";
import { useTranslation } from "react-i18next";

const VideoTable = ({ isScreen, data, onEditClick, onDeleteClick }) => {
  const { t } = useTranslation();
  return (
    <StyledTable>
      <StyledTHead>
        <STR>
          <STH>NO</STH>
          <STH>Url</STH>
          {isScreen && <STH>{t("admin.table.actions")}</STH>}
        </STR>
      </StyledTHead>
      <StyledTBody>
        {(data ?? []).map((vid, _idx) => (
          <STR key={vid?._id}>
            <STD>{_idx + 1}</STD>
            <STD>{vid?.videoUrl ?? "-/-"}</STD>

            {isScreen && (
              <STD asFlex>
                <BtnContainer>
                  <Button onClick={() => onEditClick(vid)}>
                    {t("buttons.edit")}
                  </Button>
                </BtnContainer>
                <BtnContainer className='delete'>
                  <Button onClick={(e) => onDeleteClick(e, vid._id)}>
                    {t("buttons.delete")}
                  </Button>
                </BtnContainer>
              </STD>
            )}
          </STR>
        ))}
      </StyledTBody>
    </StyledTable>
  );
};

export default VideoTable;

import { useRef, useState } from "react";
import {
  SDivider,
  SLayout,
  SLink,
  SLinkContainer,
  SLinkIcon,
  SLinkLabel,
  SLinkNotification,
  SLogo,
  SMain,
  SSearch,
  SSearchIcon,
  SSidebar,
  SSidebarButton,
  StyledHeader,
} from "./sidebar.styles";

import {
  AiOutlineHome,
  AiOutlineLeft,
  AiOutlineSearch,
  AiOutlineSetting,
  AiOutlineUser,
  AiOutlineVideoCameraAdd,
} from "react-icons/ai";
import { BsCloudUpload, BsPeople } from "react-icons/bs";
import {
  MdEventNote,
  MdLogout,
  MdOutlineAttachMoney,
  MdOutlineEmojiPeople,
  MdOutlineFamilyRestroom,
} from "react-icons/md";

import { Outlet, useLocation } from "react-router-dom";
// import { useGetCurrentUser } from "../../network";
import { FiMapPin } from "react-icons/fi";
import { logo, SuzProfile } from "../../../assets/images";
import { useTheme } from "../../../hooks";
import { Switch } from "../../Toggle";

const Sidebar = () => {
  const searchRef = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  const paths = pathname.split("/");
  const currentPage = paths[paths.length - 1];
  const { theme, changeTheme } = useTheme();

  //   const { user } = useGetCurrentUser();

  const searchClickHandler = () => {
    if (!sidebarOpen) {
      setSidebarOpen(true);
      if (searchRef.current) {
        searchRef.current.focus();
      }
    } else {
      // search functionality
    }
  };

  const linksArray = [
    {
      label: "Home",
      icon: <AiOutlineHome />,
      to: "/admin/dashboard",
      notification: 0,
    },
    {
      label: "Students",
      icon: <BsPeople />,
      to: "/admin/students",
      notification: 0,
    },
    {
      label: "Class",
      icon: <MdOutlineEmojiPeople />,
      to: "/admin/classes",
      notification: 0,
    },
    {
      label: "Home Classes",
      icon: <MdOutlineFamilyRestroom />,
      to: "/admin/home-class",
      notification: 0,
    },
    {
      label: "Events",
      icon: <MdEventNote />,
      to: "/admin/events",
      notification: 0,
    },
    {
      label: "Instructors",
      icon: <AiOutlineUser />,
      to: "/admin/instructors",
      notification: 0,
    },
    {
      label: "Photo Sessions",
      icon: <BsCloudUpload />,
      to: "/admin/photo-session",
      notification: 0,
    },
    {
      label: "Locations",
      icon: <FiMapPin />,
      to: "/admin/locations",
      notification: 0,
    },
    {
      label: "Pricing",
      icon: <MdOutlineAttachMoney />,
      to: "/admin/pricing",
      notification: 0,
    },
    {
      label: "Videos",
      icon: <AiOutlineVideoCameraAdd />,
      to: "/admin/videos",
      notification: 0,
    },
  ];

  const secondaryLinksArray = [
    {
      label: "Settings",
      icon: <AiOutlineSetting />,
      to: "/admin/settings",
    },
    {
      label: "Logout",
      icon: <MdLogout />,
      to: "/",
      onclick: () => {
        localStorage.clear();
      },
    },
  ];

  return (
    <SLayout>
      <SSidebar isOpen={sidebarOpen}>
        <>
          <SSidebarButton
            isOpen={sidebarOpen}
            onClick={() => setSidebarOpen((p) => !p)}
          >
            <AiOutlineLeft />
          </SSidebarButton>
        </>
        <SLogo isOpen={sidebarOpen}>
          <img src={logo} alt='logo' />
        </SLogo>
        <SSearch
          onClick={searchClickHandler}
          style={!sidebarOpen ? { width: `fit-content` } : {}}
        >
          <SSearchIcon>
            <AiOutlineSearch />
          </SSearchIcon>
          <input
            ref={searchRef}
            placeholder='Search'
            style={!sidebarOpen ? { width: 0, padding: 0 } : {}}
          />
        </SSearch>
        <SDivider />
        {linksArray.map(({ icon, label, notification, to }) => (
          <SLinkContainer key={label} isActive={pathname === to}>
            <SLink to={to} style={!sidebarOpen ? { width: `fit-content` } : {}}>
              <SLinkIcon>{icon}</SLinkIcon>
              {sidebarOpen && (
                <>
                  <SLinkLabel>{label}</SLinkLabel>
                  {/* if notifications are at 0 or null, do not display */}
                  {!!notification && (
                    <SLinkNotification>{notification}</SLinkNotification>
                  )}
                </>
              )}
            </SLink>
          </SLinkContainer>
        ))}
        <SDivider />
        {secondaryLinksArray.map((item, _idx) => (
          <SLinkContainer key={item.label}>
            <SLink
              to={item.to}
              onClick={item?.onclick}
              style={!sidebarOpen ? { width: `fit-content` } : {}}
            >
              <SLinkIcon>{item.icon}</SLinkIcon>
              {sidebarOpen && <SLinkLabel>{item.label}</SLinkLabel>}
            </SLink>
          </SLinkContainer>
        ))}
        <SDivider />

        <Switch
          isChecked={theme === "dark"}
          onChange={() => changeTheme(theme === "light" ? "dark" : "light")}
        />
      </SSidebar>
      <SMain isOpen={sidebarOpen}>
        <StyledHeader>
          <p>{currentPage}</p>
          <div className='admin'>
            {/* <small>{user.type ?? "Admin"}</small> */}
            <small>Admin</small>
            <img src={SuzProfile} alt='admin_img' />
          </div>
        </StyledHeader>
        <Outlet />
      </SMain>
    </SLayout>
  );
};

export default Sidebar;

export const lightTheme = {
  body: "#f2f4f6",
  white_inverse: "#b8b8b8",
  primary: "rgba(27, 184, 201)",
  container: "#fafafa",
  secContainer: "#F2F4F6",
  primary_bold: "rgb(27, 184, 201)",
  text: "#011627",
  gray: "rgba(102,102,102, 0.7)",
  gray_50: "rgba(102,102,102, 0.4)",
  border: "rgba(102,102,102, 0.7)",
  border_inverse: "#fff",
  lime: "#c5dc50",
  shadow: "rgba(0,0,0,0.4)",
  footer: "transparent",
  body_inverse: "rgb(41,41,41)",
  blue: "rgb(138,164,127)",
  olive: "#0277bd",

  bg: "rgb(255,255,255)",
  bgAlpha: "rgba(250,250,250,.3)",
  bg2: "rgb(245,245,245)",
  bg3: "rgb(230,230,230)",
  white: "rgb(255, 255, 255)",
  mainRed: "#f05742",
  primary_30: "rgba(27, 184, 201, 0.3)",
};
export const darkTheme = {
  body: "#2b2b2b",
  white_inverse: "rgba(0,0,0,0.6)",
  container: "#343838",
  secContainer: "#2B2B2B",
  primary: "rgba(27, 184, 201)",
  primary_bold: "rgba(27, 184, 201)",
  text: "#FAFAFA",
  gray: "#d3d3d3",
  gray_50: "rgba(211, 211, 211, 0.4)",
  border: "rgba(102,102,102, 0.29)",
  border_inverse: "rgba(0,0,0, 0.4)",
  lime: "#c5dc50",
  shadow: "rgba(0,0,0,0.4)",
  footer: "rgba(0,0,0,0.7)",
  body_inverse: "#FFF",
  blue: "rgb(2, 119, 189)",
  olive: "rgb(138,164,127)",
  pink: "rgb(254,108,197)",

  bg: "rgb(15,15,15)",
  bgAlpha: "rgba(0,0,0,.3)",
  bg2: "rgb(30,30,30)",
  bg3: "rgb(50,50,50)",
  white: "rgb(255, 255, 255)",
  mainRed: "#f05742",
  primary_30: "rgba(27, 184, 201, 0.3)",
};
// "rgb(138,164,127)",olive
// "rgb(2, 119, 189)" blue,
// rgba(233, 155, 141, 0.884); primary-pink
// #472D30

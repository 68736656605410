import React from "react";
import { useTranslation } from "react-i18next";
import { SITE } from "../../../../utils/settings";
import { Button } from "../../../Button";
import { Input, Select, TextArea } from "../../../Input";
import Modal from "../../../Modal/Modal";
import { StyledButtonLeft } from "../../comp.styles";
import { ImagesContainer } from "../AddNewEvent/event.styles";
import { useAddNewInstructor } from "./useAddNewInstructor";

const AddNewInstructorModal = ({ isOpen, onClose, prev }) => {
  const { t } = useTranslation();
  const {
    onSubmit,
    isLoading,
    errors,
    fields,
    handleFieldChange,
    isDisabled,
    // remappedStylesOptions,
    // checkedState,
    // handleCheckboxChange,
    previewImage,
    onSelectedImage,
    prevImage,
    // fetchDanceStyledLoading,
    onUpdateSubmit,
    cityOptions,
  } = useAddNewInstructor(onClose, prev);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        prev
          ? `${t("buttons.edit")} ${prev.fullName}`
          : `${t("buttons.adminTitles.addNewInstructor")}`
      }
    >
      <Input
        placeholder={t("admin.instructor.enterInstructorName")}
        type='text'
        label={t("admin.instructor.fullName")}
        isRequired
        name='fullName'
        value={fields.fullName}
        onChange={handleFieldChange}
        error={errors.fullName}
      />
      <Input
        placeholder={t("admin.instructor.enterInstructorEmail")}
        type='email'
        label={t("admin.table.email")}
        isRequired
        name='email'
        value={fields.email}
        onChange={handleFieldChange}
        error={errors.email}
      />
      <Input
        placeholder="Select instructor's image"
        type='file'
        label={t("admin.table.image")}
        isRequired
        onChange={onSelectedImage}
      />
      <ImagesContainer>
        {previewImage && (
          <div className='container'>
            <p>{t("admin.photo.previewImage")}</p>
            <img src={previewImage} alt='preview' />
          </div>
        )}
        {prevImage && (
          <div className='container'>
            <p>{t("admin.photo.previousImage")}</p>
            <img src={`${SITE.url}${prevImage}`} alt='preview' />
          </div>
        )}
        {/* <img src={prevImage} /> */}
      </ImagesContainer>
      <Input
        placeholder={t("admin.instructor.pasteFacebook")}
        type='text'
        label={t("admin.instructor.facebook")}
        isRequired
        name='facebookLink'
        value={fields.facebookLink}
        onChange={handleFieldChange}
        error={errors.facebookLink}
      />
      <Input
        placeholder={t("admin.instructor.pasteTiktok")}
        type='text'
        label={t("admin.instructor.tiktok")}
        isRequired
        name='tiktokLink'
        value={fields.tiktokLink}
        onChange={handleFieldChange}
        error={errors.tiktokLink}
      />
      <Input
        placeholder={t("admin.instructor.pasteInstagram")}
        type='text'
        label={t("admin.instructor.instagram")}
        isRequired
        name='instagramLink'
        value={fields.instagramLink}
        onChange={handleFieldChange}
        error={errors.instagramLink}
      />
      <Select
        placeholder={t("placeholders.selectLocation")}
        label={t("admin.table.location_address")}
        isRequired
        name='location'
        value={fields.location}
        onChange={handleFieldChange}
        options={cityOptions}
        error={errors.location}
      />
      {/* {fetchDanceStyledLoading ? (
        <Spinner />
      ) : (
        <StyledCheckBoxesContainer>
          {remappedStylesOptions.map((style, idx) => (
            <StyledLabel key={idx}>
              <input
                value={style.value}
                onChange={() => handleCheckboxChange(idx)}
                type='checkbox'
                name={style.label}
                checked={checkedState[idx]}
                style={{ marginRight: "1rem" }}
              />
              {style.label}
            </StyledLabel>
          ))}
        </StyledCheckBoxesContainer>
      )} */}
      <TextArea
        placeholder={t("placeholders.aboutInstructor")}
        type='text'
        label={t("admin.instructor.aboutInstructor")}
        isRequired
        name='about'
        value={fields.about}
        onChange={handleFieldChange}
        error={errors.about}
      />

      <StyledButtonLeft>
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={prev ? onUpdateSubmit : onSubmit}
        >
          {t("buttons.save")}
        </Button>
      </StyledButtonLeft>
    </Modal>
  );
};

export default AddNewInstructorModal;

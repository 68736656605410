import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useSpring } from "react-spring";
import {
  ModalBlock,
  ModalBody,
  ModalClose,
  ModalContainer,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from "./modal.styles";

const Modal = ({ title, children, isOpen, onClose }) => {
  const modalRef = useRef(null);

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && isOpen) {
        onClose();
      }
    },
    [isOpen, onClose],
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (isOpen) {
      body?.classList.add("modal-open");
    } else {
      body?.classList.remove("modal-open");
    }

    return () => body?.classList.remove("modal-open");
  }, [isOpen]);

  const animation = useSpring({
    config: {
      duration: 200,
    },
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? `translateY(0%)` : `translateY(100%)`,
  });

  return (
    <Fragment>
      {isOpen && (
        <ModalBlock ref={modalRef}>
          <ModalOverlay onClick={() => onClose()} />
          <ModalContainer style={animation}>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              <ModalClose onClick={() => onClose()}>
                <AiOutlineClose />
              </ModalClose>
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
          </ModalContainer>
        </ModalBlock>
      )}
    </Fragment>
  );
};

export default Modal;

import React from "react";
import { Button } from "../../../Button";
import { Input } from "../../../Input";
import Modal from "../../../Modal/Modal";
import { StyledButtonLeft } from "../../comp.styles";
import { Flex } from "./styles";
import { useUploadPhotos } from "./useUploadPhotos";
import ProgressBar from "@ramonak/react-progress-bar";
import { useTranslation } from "react-i18next";

const UploadModal = ({ isOpen, onClose, prev }) => {
  const { t } = useTranslation();
  const {
    selectedImages,
    preview,
    handleImageSelect,
    onSubmit,
    isLoading,
    progress,
    previous,
  } = useUploadPhotos(onClose, prev);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("admin.photo.uploadPhoto")}
    >
      {selectedImages.length > 0 && `Selected ${selectedImages.length} images`}
      <Input
        placeholder='Select Photos'
        type='file'
        multiple={prev ? false : true}
        label={
          prev
            ? `${t("admin.photo.choosePhoto")}`
            : `${t("admin.photo.maxSize")}`
        }
        isRequired
        name='images'
        // value={fields.fullName}
        onChange={handleImageSelect}
        // error={errors.fullName}
      />
      {previous && <p>{t("admin.photo.previous")}</p>}
      {previous && (
        <Flex>
          <img src={previous} alt='upload img' />
        </Flex>
      )}
      {preview && <p>{t("admin.photo.preview")}</p>}
      {preview.length > 0 && (
        <Flex>
          {preview.map((img, idx) => (
            <img src={img} alt='upload img' key={idx} />
          ))}
        </Flex>
      )}
      <div style={{ width: "100%", marginTop: "1rem" }}>
        <ProgressBar completed={progress} maxCompleted='100' />
      </div>
      <StyledButtonLeft>
        <Button
          isLoading={isLoading}
          isDisabled={selectedImages.length === 0}
          onClick={onSubmit}
        >
          {t("buttons.upload")}
        </Button>
      </StyledButtonLeft>
    </Modal>
  );
};

export default UploadModal;

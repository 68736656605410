import "draft-js/dist/Draft.css";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button } from "../../../Button";
import { Input } from "../../../Input";
import Modal from "../../../Modal/Modal";
import { StyledButtonLeft } from "../../comp.styles";
import { ImagesContainer } from "./event.styles";
import { useAddEvent } from "./useAddEvent";
import { SITE } from "../../../../utils/settings";

const AddNewEventModal = ({ isOpen, onClose, prev }) => {
  const {
    fields,
    onSubmit,
    handleFieldChange,
    errors,
    isDisabled,
    isLoading,
    editorState,
    handleEditorStateChange,
    previewImage,
    onSelectedImage,
    prevImage,
    onUpdateSubmit,
  } = useAddEvent(onClose, prev);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={prev ? `Edit ${prev?.title}` : "'Add New Event'"}
    >
      <Input
        placeholder='Enter event title'
        type='text'
        label='Event Title'
        isRequired
        error={errors.title}
        onChange={handleFieldChange}
        value={fields.title}
        name='title'
      />
      <Input
        placeholder='Enter event date'
        type='date'
        label='Event Date'
        isRequired
        error={errors.date}
        onChange={handleFieldChange}
        value={fields.date}
        name='date'
      />
      <Input
        placeholder='Enter event document link'
        type='text'
        label='Event Document Link'
        isRequired
        error={errors.documentLink}
        onChange={handleFieldChange}
        value={fields.documentLink}
        name='documentLink'
      />
      <Input
        placeholder='Select Event Image'
        type='file'
        label='Event Image'
        isRequired
        onChange={onSelectedImage}
      />
      <ImagesContainer>
        {previewImage && (
          <div className='container'>
            <p>Preview Image</p>
            <img src={previewImage} alt='preview' />
          </div>
        )}
        {prevImage && (
          <div className='container'>
            <p>Previous Image</p>
            <img src={`${SITE.url}${prevImage}`} alt='preview' />
          </div>
        )}
        {/* <img src={prevImage} /> */}
      </ImagesContainer>
      <Editor
        // ref={editor}
        editorState={editorState}
        // handleKeyCommand={handleKeyCommand
        onEditorStateChange={handleEditorStateChange}
      />

      <StyledButtonLeft>
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={prev ? onUpdateSubmit : onSubmit}
        >
          SAVE
        </Button>
      </StyledButtonLeft>
    </Modal>
  );
};

export default AddNewEventModal;

import React from "react";
import { useTranslation } from "react-i18next";
import { Group2Image } from "../../../assets/images";
import {
  PageHeader,
  SectionHeader,
  SEO,
  TimeTableData,
} from "../../../components";
import { useTimetable } from "../../../network/queries";

const Timetable = () => {
  const { data: srem } = useTimetable("Śrem");
  const { data: poznan } = useTimetable("Poznań");
  const { t } = useTranslation();
  return (
    <div>
      <SEO title={t("meta.title.timetable")} />
      <PageHeader title={t("navbar.timetable")} image={Group2Image} />
      <SectionHeader title='POZNAŃ' />

      <TimeTableData data={poznan?.timetable} />
      <SectionHeader title='ŚREM' />
      <TimeTableData data={srem?.timetable} />
    </div>
  );
};

export default Timetable;

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { GeneralService } from "../services";

export const useGetSingleInstructor = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(
    ["single_instructor", id],
    () => GeneralService.getSingleInstructor(id),
    {
      onError: (error) => {
        console.error(error);
      },
    },
  );
  return { data, isLoading };
};

import React from "react";
import {
  InstructorsCardItem,
  NoResult,
  PageHeader,
  SEO,
} from "../../../components";
import { Spinner } from "../../../components/Spinner";
import { useGetAllInstructors } from "../../../network/queries/useGetAllInstructors";
import { InstructorsContainer } from "./Instructors.styles";
import { useTranslation } from "react-i18next";

const Instructors = () => {
  const { data, isLoading } = useGetAllInstructors();
  const { t } = useTranslation();
  return (
    <div>
      <SEO title={t("meta.title.instructors")} />
      <PageHeader title={t("headers.instructor")} small={t("headers.our")} />
      {isLoading ? (
        <Spinner />
      ) : (
        <InstructorsContainer>
          {data?.instructors.length === 0 ? (
            <NoResult message='No Results For your Query.' />
          ) : (
            data?.instructors.map((inst, idx) => (
              <InstructorsCardItem key={idx} instructor={inst} />
            ))
          )}
        </InstructorsContainer>
      )}
    </div>
  );
};

export default Instructors;

import styled from "styled-components";
import { devices } from "../../styles";

export const StyledContainer = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.white_inverse};
  margin: 4rem 0px;
  height: 40vh;

  @media ${devices.tablet} {
    height: 20vh;
  }
`;

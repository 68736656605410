import React, { useCallback } from "react";
import {
  StyledAlternateHeaderContainer,
  StyledAlternateHeaderLeftContainer,
  StyledAlternateHeaderRightContainer,
} from "./AlternateHeader.styles";
import { useSpring, animated as animatedSpring } from "react-spring";

const AlternateHeader = ({ image, title, description, small, contain }) => {
  const [{ xy }, set] = useSpring(() => ({ st: 0, xy: [0, 0] }));
  const onMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      set.start({
        xy: [x - window.innerWidth / 2, y - window.innerHeight / 2],
      }),
    [set],
  );
  const interpImgMove = xy.to(
    (x, y) =>
      `perspective(400px) translateY(${x / 40}px) translateX(${-y / 40}px) `,
  );

  return (
    <div>
      {/* <StyledThinLine /> */}
      <StyledAlternateHeaderContainer>
        <StyledAlternateHeaderLeftContainer>
          <div className='content'>
            {small && (
              <div className='section_small_container'>
                <p className='section_small_title'>{small}</p>
              </div>
            )}
            <div>
              <h1>{title}</h1>
              {description && (
                <p className='section_description'>{description}</p>
              )}
            </div>
          </div>
        </StyledAlternateHeaderLeftContainer>
        <StyledAlternateHeaderRightContainer>
          <animatedSpring.img
            className={contain ? "contain" : ""}
            onMouseMove={onMove}
            style={{ transform: interpImgMove }}
            src={
              image !== undefined
                ? image
                : require("../../assets/images/group_pose.png")
            }
            alt='pageheader'
          />
        </StyledAlternateHeaderRightContainer>
      </StyledAlternateHeaderContainer>
    </div>
  );
};

export default AlternateHeader;

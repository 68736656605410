import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { GeneralService } from "../../../network/services";
import { validators } from "../../../utils/validator";

export const useContactUs = () => {
  const initialState = {
    fullName: "",
    email: "",
    message: "",
    subject: "",
  };

  const [fields, setFields] = useState(initialState);
  const [errors, setFormError] = useState(initialState);

  const { data, isLoading: isLoadingContact } = useQuery(["settings"], () =>
    GeneralService.getContactDetails(),
  );

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const error = validators[name](value);

    setFields((prev) => ({ ...prev, [name]: value }));
    setFormError((formError) => ({ ...formError, [name]: error }));
  };

  const { isLoading, mutate } = useMutation(
    () => GeneralService.contactUs(fields),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        setFields(initialState);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );
  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  const isDisabled = Object.values(errors).some(Boolean);

  return {
    fields,
    errors,
    isDisabled,
    isLoading,
    onSubmit,
    data,
    handleFieldChange,
  };
};

import styled, { keyframes } from "styled-components";
import { devices } from "../../../../../styles";

const scroll = keyframes`
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(-50%, 0);
  }
`;

export const StyledGallerySection = styled.section`
  .container {
    height: 35rem;
    position: relative;
    overflow: hidden;
    /* @media ${devices.tablet} {
      height: 20rem;
    } */
  }
  .gallery_container {
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
    white-space: nowrap;
    animation: ${scroll} 40s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      /* height: 1; */
      width: 20rem;
      display: inline-block;
      &:nth-of-type(even) {
        margin-top: -4rem;
      }
    }
  }
`;

import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.container};
  margin: 4rem 0px;
  border-radius: 1rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  height: ${({ small }) => (small ? "6vh" : "10vh")};
  h3 {
    color: ${({ theme }) => theme.primary};
    font-weight: bold;
    font-size: 1.3rem;
  }
`;

export const StyledAddButton = styled.div`
  margin: 3rem 0;
  button {
    padding: 1rem;
  }
`;

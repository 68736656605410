import { useState } from "react";
import { validators } from "../../utils/validator";
import { toast } from "react-toastify";
import { GeneralService } from "../../network/services";
import { useMutation } from "@tanstack/react-query";
import {
  useGetDanceStylesByFilter,
  useGetLocations,
} from "../../network/queries";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const useJoinUsNow = (onClose) => {
  const { i18n } = useTranslation();
  const initialState = {
    fullName: "",
    email: "",
    location: "",
    phoneNumber: "",
  };

  const [fields, setFields] = useState(initialState);
  const [errors, setFormError] = useState(initialState);

  const {
    data,
    isLoading: fetchDanceStyledLoading,
    refetch,
  } = useGetDanceStylesByFilter(fields.location);

  useEffect(() => {
    if (fields.location !== "") {
      refetch();
    }
  }, [fields.location, refetch]);

  const danceStylesOptions = data?.dances?.map((dance) => ({
    label: dance.name,
    value: dance._id,
  }));

  const [checkedState, setCheckedState] = useState();

  useEffect(() => {
    setCheckedState(new Array(danceStylesOptions?.length).fill(false));
  }, [danceStylesOptions?.length]);

  const [selectedValues, setSelectedValues] = useState([]);

  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item,
    );
    let arr = [];
    setCheckedState(updatedCheckedState);
    // eslint-disable-next-line array-callback-return
    updatedCheckedState.reduce((_sum, currentState, index) => {
      if (currentState === true) {
        arr.push(danceStylesOptions[index].value);
      }
      // return "";
    }, 0);
    setSelectedValues(arr);
  };

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    const error = validators[name](value);
    setFields({ ...fields, [name]: value });
    setFormError((formError) => ({ ...formError, [name]: error }));
  };

  const { isLoading, mutate } = useMutation(
    () =>
      GeneralService.joinusNow({
        ...fields,
        danceStyles: selectedValues,
        currentLanguage: i18n.language,
      }),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        onClose();
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  const isDisabled = Object.values(errors).some(Boolean);

  const { data: locations } = useGetLocations();

  const cityOptions = locations?.locations.map((loc) => ({
    label: loc.city,
    value: loc.city,
  }));

  const timeOptions = [
    { value: "7:00 AM - 12:00PM", label: "7:00 AM  - 12:00PM" },
    { value: "12:30 PM - 2:00PM", label: "12:30 PM - 2:00PM" },
    { value: "2:00 PM - 5:00PM", label: "2:00 PM - 5:00PM" },
  ];

  return {
    onSubmit,
    isLoading,
    errors,
    fields,
    handleFieldChange,
    isDisabled,
    danceStylesOptions,
    cityOptions,
    checkedState,
    timeOptions,
    handleCheckboxChange,
    fetchDanceStyledLoading,
  };
};

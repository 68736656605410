import styled from "styled-components";
import { devices } from "../../styles";

export const StyledSectionHeader = styled.div`
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0px;

  h3 {
    /* font-family: "Bubblegum Sans", cursive; */
    font-size: 1.5rem;
    color: ${({ theme }) => theme.primary};
    position: relative;
    :after {
      content: "";
      position: absolute;
      left: 10%;
      top: 100%;
      margin-top: 0.3rem;
      /* margin-top: 2rem; */
      width: 80%;
      height: 0.05rem;
      background: ${({ theme }) => theme.primary};
      @media ${devices.tablet} {
        left: 0;
        width: 4rem;
      }
    }
  }
`;

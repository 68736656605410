import { Link } from "react-router-dom";
import styled from "styled-components";
import { devices } from "../../../styles";

export const StyledEventPage = styled.section``;

export const StyledEventsContainer = styled.div`
  width: 100%;
  margin: 4rem auto;
  /* display: flex; */
  /* justify-content: center; */
  /* background: red; */
  & > .wrapper {
    width: 80%;
    margin: 0 auto;
    @media ${devices.tablet} {
      width: 80%;
    }
  }
  & > .container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    @media ${devices.tablet} {
      width: 95%;
    }
  }
  .sec_header {
    margin-top: 6rem;
  }
`;

export const StyledEvent = styled(Link)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  width: ${({ w }) => (w ? w : "28%")};
  margin: ${({ w }) => (w ? "" : "2rem")};
  position: relative;
  cursor: pointer;
  transition: 0.4s ease;
  background: ${({ theme }) => theme.container};
  box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
  max-height: 30rem;
  display: ${({ w }) => (w ? "flex" : "")};
  align-items: ${({ w }) => (w ? "center" : "")};

  @media ${devices.tablet} {
    width: 100%;
  }

  @media ${devices.mobileL} {
    flex-direction: column;
  }

  :hover {
    box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.3);
    img {
      filter: grayscale(0) !important;
      /* z-index: 10;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain; */
    }
    .evt_date {
      border-radius: 0.2rem;
      transform: scale(1.1);
    }
  }

  .evt_date {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.8rem;
    text-align: center;
    background-color: ${({ theme }) => theme.primary};
    color: white;
    transition: 0.4s ease;
    font-size: 0.8rem;
    border-radius: 100px;
    overflow: hidden;
    width: 4rem;
    height: 4rem;
    .date {
      /* font-size: 1rem; */
      margin-bottom: -0.3rem;
    }
    .month {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .year {
      /* font-size: 1rem; */
    }
  }

  .img_holder {
    width: ${({ w }) => (w ? "45%" : "100%")};
    height: 18rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(100%);
    }
    @media ${devices.mobileL} {
      width: 100%;
    }
  }

  .bottom {
    padding: 1.5rem;
    width: ${({ w }) => (w ? "50%" : "100%")};
    @media ${devices.mobileL} {
      width: 100%;
    }
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .content {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.gray};
    /* line-break: break-all !important; */
  }
`;

export const StyledEventContainer = styled.section`
  width: 100%;

  .content {
    width: 90%;
    margin: 3rem auto;
    display: flex;
    justify-content: space-between;
    @media ${devices.tablet} {
      flex-direction: column;
    }

    .gen {
      line-height: 1.4rem;
      letter-spacing: 0.1rem;
    }

    .left {
      width: 55%;
      @media ${devices.tablet} {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }

    .right {
      width: 43%;

      @media ${devices.tablet} {
        width: 100%;
      }

      h1.title {
        font-size: 2rem;
        font-weight: bold;

        margin-bottom: 2.4rem;
        @media ${devices.tablet} {
          margin-top: 2.5rem;
        }
      }
    }
  }
`;

export const StyledEventNavigateButton = styled.a`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.4rem;
  transition: 0.4s ease;
  margin-top: 3rem;
  padding: 10px;
  color: ${({ theme }) => theme.text};
  :hover {
    color: ${({ theme }) => theme.white};
    box-shadow: 0pc 0px 4px rgba(0, 0, 0, 0.3);
    /* border-radius: 1rem 0 1rem 0; */
    transform: scale(1.05);
    background: ${({ theme }) => theme.primary};
  }
  svg {
    font-size: 1.2rem;
  }
`;

import React from "react";
import { Spinner } from "../../../../../components/Spinner";
import { StyledContainer } from "./dashcard.styles";

export const DashCard = ({ item, isLoading }) => {
  return (
    <StyledContainer>
      <div className='left'>{item.icon}</div>
      <div className='right'>
        <h1>{item.title ?? ""}</h1>
        {isLoading ? <Spinner /> : <h3>{item.count ?? 0}</h3>}
      </div>
    </StyledContainer>
  );
};

export { DashboardContainer } from "./dashcard.styles";

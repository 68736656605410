import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  LinkR,
  NavLinkItem,
  NavbarContainer,
  NavLogo,
  StyledDropDownContainer,
  StyledLinkItem,
  SubMenuLinkR,
  StyledHamburger,
  StyledMenuMobile,
  StyledMenuOverlay,
  StyledMenuMobileContainer,
  StyledMobileMenuItem,
  ChildContainer,
  NavWrapper,
} from "./NavBar.styles";
import { FiChevronDown } from "react-icons/fi";
import { HamburgerIcon } from "../../assets/icons/HamburgerMenu";
import { useOnClickOutside, useTheme } from "../../hooks";
import { animated } from "@react-spring/web";
import useDimensions from "react-cool-dimensions";
import { ResizeObserver } from "@juggle/resize-observer";
import { useSpring } from "react-spring";
import { useSwipeable } from "react-swipeable";
import { ReactComponent as Logo } from "../../assets/images/good_vibes_logo.svg";
import { useGetAllInstructors } from "../../network/queries/useGetAllInstructors";
import { Switch } from "../Toggle";
import { useTranslation } from "react-i18next";
import LangSelect from "../LangSelect/LangSelect";
import { Pl, Ua, Us } from "react-flags-select";
import { useGetLocations } from "../../network/queries";

const NavBar = () => {
  const sideBarRef = React.createRef();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const handleShowNav = () => {
    setIsOpen((prev) => !prev);
  };
  const { theme, changeTheme } = useTheme();
  const [fixedHeader, setFixedHeader] = useState(false);
  const [openKey, setOpenKey] = useState();
  const { t } = useTranslation();

  const handleToggle = (key) => {
    setOpenKey(openKey !== key ? key : null);
  };
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => setIsOpen(true),
    onSwipedRight: () => setIsOpen(false),
  });

  const { data } = useGetAllInstructors();

  const cvtToFirstLetter = (alpha) => {
    const val = `${alpha.split(" ")[0]}`;
    return val.toLowerCase();
  };

  const instructorsDropDown = data?.instructors.map((ins) => ({
    title: `${cvtToFirstLetter(ins.fullName)}`,
    to: `instructors/${ins._id}/${cvtToFirstLetter(ins.fullName)}`,
    isSubActive: pathname === "/instructors/2/dianna",
  }));

  const { data: locations } = useGetLocations();

  const filteredLocations = locations?.locations.filter(
    (loc) => loc.imageUrl !== ("" || undefined),
  );
  const locationsDropDown =
    filteredLocations?.map((loc) => ({
      title: loc.city,
      to: `/studio/${loc.city}`,
      isSubActive: pathname === `/studio/${loc.city}`,
    })) ?? [];

  useOnClickOutside(sideBarRef, () => setIsOpen(false));
  const links = [
    { title: t("navbar.welcome"), to: "/", active: pathname === "/" },
    {
      title: t("navbar.studio"),
      to: "/studio",
      active: pathname.includes("/studio"),
      dropDownMenus: [
        ...locationsDropDown,
        // {
        //   title: "poznan 🏠",
        //   to: "/studio/poznan",
        //   isSubActive: pathname === "/studio/poznan",
        // },
        // {
        //   title: "srem",
        //   to: "/studio/srem",
        //   isSubActive: pathname === "/studio/srem",
        // },
        {
          title: "photo session",
          to: "/studio/photo_session",
          isSubActive: pathname === "/studio/photo_session",
        },
      ],
    },
    // {
    //   title: "classes",
    //   to: "/classes",
    //   active:
    //     pathname === "/classes" ||
    //     pathname === "/classes/individual_classes" ||
    //     pathname === "/classes/hip-hop" ||
    //     pathname === "/classes/Dancehall" ||
    //     pathname === "/classes/sexydance" ||
    //     pathname === "/classes/twerk",
    //   dropDownMenus: [
    //     {
    //       title: "individual classes",
    //       to: "/classes/individual_classes",
    //       isSubActive: pathname === "/classes/individual_classes",
    //     },
    //     {
    //       title: "Dancehall",
    //       to: "/classes/Dancehall",
    //       isSubActive: pathname === "/classes/Dancehall",
    //     },
    //     {
    //       title: "hip-hop",
    //       to: "/classes/hip-hop",
    //       isSubActive: pathname === "/classes/hip-hop",
    //     },
    //     {
    //       title: "sexy dance",
    //       to: "/classes/sexydance",
    //       isSubActive: pathname === "/classes/sexydance",
    //     },
    //     {
    //       title: "twerk",
    //       to: "/classes/twerk",
    //       isSubActive: pathname === "/classes/twerk",
    //     },
    //   ],
    // },
    {
      title: t("navbar.timetable"),
      to: "/timetables",
      active:
        pathname === "/timetables" ||
        pathname === "/timetables/poznan" ||
        pathname === "/timetables/srem",
      dropDownMenus: [
        {
          title: "poznań",
          to: "/timetables/poznan",
          isSubActive: pathname === "/timetables/poznan",
        },
        {
          title: "śrem",
          to: "/timetables/srem",
          isSubActive: pathname === "/timetables/srem",
        },
      ],
    },
    {
      title: t("navbar.instructors"),
      to: "/instructors",
      active:
        pathname === "/instructors" ||
        pathname === "/instructors/1/anna" ||
        pathname === "/instructors/2/dianna" ||
        pathname === "/instructors/3/milena" ||
        pathname === "/instructors/4/ola" ||
        pathname === "/instructors/5/suzie",
      dropDownMenus: instructorsDropDown,
    },
    {
      title: t("navbar.events"),
      to: "/events",
      active: pathname === "/events",
      // dropDownMenus: [
      //   {
      //     title: "competitions 🏆",
      //     to: "/events/competitions",
      //     isSubActive: pathname === "/events/competitions",
      //   },
      //   {
      //     title: "summer camps 🦩",
      //     to: "/events/summer-camps",
      //     isSubActive: pathname === "/events/summer-camps",
      //   },
      // ],
    },
    {
      title: t("navbar.pricing"),
      to: "/pricing",
      active: pathname === "/pricing",
    },
    {
      title: t("navbar.contact"),
      to: "/contact",
      active: pathname === "/contact",
    },
  ];

  const handleHeaderFixed = () => {
    if (window.scrollY >= 150) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  };

  window.addEventListener("scroll", handleHeaderFixed);

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    i18n.changeLanguage("pl");
  }, []);

  return (
    <NavWrapper className={fixedHeader ? "fixed" : ""}>
      <NavbarContainer {...handlers}>
        <NavLogo to='/'>
          {/* <img className='logo' src={logo} alt='logo' /> */}
          <Logo className='logo' />
        </NavLogo>
        <NavLinkItem>
          {links.map((link, _idx) => (
            <StyledLinkItem key={link.to.toString()}>
              <LinkR to={link.to} className={link.active && "active"}>
                {link.title}
                {link.dropDownMenus !== undefined && (
                  <FiChevronDown className='drop_down_icon' />
                )}
              </LinkR>

              {link.dropDownMenus !== undefined && (
                <StyledDropDownContainer className='dropdown'>
                  {link.dropDownMenus.map((menu, _idx) => (
                    <SubMenuLinkR
                      key={menu.to.toString()}
                      to={menu.to}
                      className={menu.isSubActive && "active"}
                    >
                      {menu.title}
                    </SubMenuLinkR>
                  ))}
                </StyledDropDownContainer>
              )}
            </StyledLinkItem>
          ))}
          <div className='flex-icons'>
            {fixedHeader && (
              <LangSelect
                options={[
                  { icon: <Pl />, value: "pl" },
                  { icon: <Us />, value: "en" },
                  { icon: <Ua />, value: "ua" },
                ]}
                onChange={(val) => {
                  changeLanguage(val);
                }}
              />
            )}
            {fixedHeader && (
              <Switch
                isChecked={theme === "dark"}
                onChange={() =>
                  changeTheme(theme === "light" ? "dark" : "light")
                }
              />
            )}
          </div>
        </NavLinkItem>
        <StyledHamburger>
          <div className='flex-icons'>
            {fixedHeader && (
              <LangSelect
                options={[
                  { icon: <Pl />, value: "pl" },
                  { icon: <Us />, value: "en" },
                  { icon: <Ua />, value: "ua" },
                ]}
                onChange={(val) => {
                  changeLanguage(val);
                }}
              />
            )}
            {fixedHeader && (
              <Switch
                isChecked={theme === "dark"}
                onChange={() =>
                  changeTheme(theme === "light" ? "dark" : "light")
                }
              />
            )}
          </div>
          <HamburgerIcon
            onClick={handleShowNav}
            className={isOpen ? "opened" : ""}
          />
        </StyledHamburger>
        <StyledMenuMobile className={isOpen ? "active" : ""}>
          <StyledMenuOverlay onClick={() => setIsOpen(false)} />
          <StyledMenuMobileContainer
            // ref={sideBarRef}
            className={isOpen ? "active" : ""}
          >
            {links.map((link, _idx) => (
              <StyledLinkItem key={link.to.toString()} noFlex>
                <StyledMobileMenuItem>
                  <LinkR
                    to={link.to}
                    onClick={() => setIsOpen(false)}
                    className={link.active && "active"}
                  >
                    {link.title}
                  </LinkR>
                  {link.dropDownMenus !== undefined && (
                    <FiChevronDown
                      className='drop_down_icon'
                      onClick={() => handleToggle(link.title)}
                    />
                  )}
                </StyledMobileMenuItem>
                {link.dropDownMenus !== undefined && (
                  <Expandable open={openKey === link.title}>
                    {link.dropDownMenus.map((menu, _idx) => (
                      <SubMenuLinkR
                        key={menu.to.toString()}
                        to={menu.to}
                        onClick={() => setIsOpen(false)}
                        className={menu.isSubActive && "active"}
                      >
                        {menu.title}
                      </SubMenuLinkR>
                    ))}
                  </Expandable>
                )}
              </StyledLinkItem>
            ))}
          </StyledMenuMobileContainer>
        </StyledMenuMobile>
      </NavbarContainer>
    </NavWrapper>
  );
};

export default NavBar;

export const Expandable = ({ open, children, pt }) => {
  const { observe, height } = useDimensions({
    useBorderBoxSize: true,
    polyfill: ResizeObserver,
  });

  const [spring, api] = useSpring(() => ({
    from: { opacity: 0, height, overflow: "hidden" },
    to: {
      height: 0,
      opacity: 0,
      overflow: "hidden",
    },
  }));
  useEffect(() => {
    api.start(
      open
        ? { opacity: 1, height, overflow: "unset" }
        : { opacity: 0, height: 0, overflow: "hidden" },
    );
  }, [open, height, api]);
  return (
    <animated.div
      style={{
        opacity: spring.opacity,
        height: spring.height,
        overflow: spring.overflow,
      }}
    >
      <ChildContainer ref={observe} pt={pt}>
        {children}
      </ChildContainer>
    </animated.div>
  );
};

import React from "react";
import { Helmet } from "react-helmet-async";

export const SEO = ({ title, description, name, type }) => {
  const keywords = [
    "Zajęcia indywidualne",
    "sexy dance",
    "Formacja",
    "Dancehall",
    "hip hop",
    "twerk",
    "high heels",
    "Ćwiczenia",
    "Born to dance",
    "Nauka tańca",
    "Zajęcia dla dzieci",
    "Studio tańca Poznań",
    "Zajęcia taneczne Poznań",
    "Szkoła tańca Poznań",
  ];
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title} | GOODVIBES STUDIOTANCA</title>
      <meta name='description' content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property='og:type' content={type} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name='twitter:creator' content={name} />
      <meta name='twitter:card' content={type} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='keywords' content={keywords.join(",")} />
      {/* End Twitter tags */}
    </Helmet>
  );
};

import React from "react";
import { PageHeader } from "../../../components";
import { useTranslation } from "react-i18next";
import { StyledSomContainer } from "./som.styles";
import ChildProtectionStandards from "../../../components/ChildProtectionStandards/ChildProtectionStandards";

export const SomScreen = () => {
  const { t } = useTranslation();
  return (
    <div>
      <PageHeader title={t("rules.header2")} image='' center />
      <StyledSomContainer>
        <ChildProtectionStandards />
      </StyledSomContainer>
    </div>
  );
};

import React from "react";
import { PageHeader } from "../../../components";
import { useTranslation } from "react-i18next";
import { StyledRulesContainer } from "./rules.styles";

export const RulesScreen = () => {
  const { t } = useTranslation();
  return (
    <div>
      <PageHeader title={t("rules.header")} image='' center />
      <StyledRulesContainer>
        <li>{t("rules.1")}</li>
        <li>{t("rules.2")}</li>
        <li>{t("rules.3")}</li>
        <li>{t("rules.4")}</li>
        <li>{t("rules.5")}</li>
        <li>{t("rules.6")}</li>
        <li>{t("rules.7")}</li>
        <li>{t("rules.8")}</li>
        <li>{t("rules.9")}</li>
        <li>{t("rules.10")}</li>
        <li>{t("rules.el")}</li>
        <li>{t("rules.ele")}</li>
        <li>{t("rules.elev")}</li>
        <li>{t("rules.eleve")}</li>
      </StyledRulesContainer>
    </div>
  );
};

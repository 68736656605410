import { AdminService } from "../../../network/services";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetDanceStyles = () => {
  const { data, isLoading } = useQuery(
    ["all_dance_styles"],
    () => AdminService.getAllDanceStyles(),
    {
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );
  return { data, isLoading };
};

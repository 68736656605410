import styled from "styled-components";
import { devices } from "../../styles";
import { LinkR, NavLogo } from "../Navbar/NavBar.styles";

export const StyledFooterContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.footer};
`;
export const StyledFooterContent = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: 4rem 0px;
`;

export const StyledNavigationLinksContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 3rem 0px;
  @media ${devices.laptop} {
    /* flex-direction: column;
    justify-content: center; */
    display: none;
  }
`;

export const StyledLinkItem = styled(LinkR)`
  @media ${devices.tablet} {
    margin: 1rem 0px;
    text-align: center;
  }
`;

export const StyledLogo = styled(NavLogo)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  .logo {
    width: 15rem;
  }

  @media ${devices.laptop} {
    font-size: 2.5rem;
  }
`;

export const StyledFooterBottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: ${({ theme }) => theme.primary};
  color: #fff;
`;

export const StyledRulesButton = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  a {
    color: ${({ theme }) => theme.gray};
    text-decoration: none;
    :hover {
      color: ${({ theme }) => theme.primary_bold};
      font-weight: bold;
    }
  }
`;

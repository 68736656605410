import { useQuery } from "@tanstack/react-query";
import { GeneralService } from "../services";

export const useTimetable = (filter) => {
  const { data, isLoading } = useQuery(
    ["timetable_by_filter", filter],
    () => GeneralService.getTimeTable(filter),

    {
      onSuccess: () => {
        console.log("Done");
      },
      onError: (err) => {
        console.log(err);
      },
    },
  );
  return { data, isLoading };
};

import styled, { keyframes } from "styled-components";
import { devices } from "../../../styles";
const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

export const StyledClassesSection = styled.section`
  width: 80%;
  margin: 0 auto;
`;

export const StyledFlexSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1rem 0;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-padding: 24px;
  user-select: none;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const StyledDanceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${({ theme }) => theme.primary};
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  min-width: 10rem;
  min-height: 10rem;
  position: relative;
  overflow: hidden;
  flex: 0 0 10rem;
  padding: 24px;
  scroll-snap-align: start;
  :first-child {
    margin-left: 3rem;
  }

  @media ${devices.tablet} {
    width: 100%;
    :first-child {
      margin-left: 300vw;
    }
  }

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  :hover {
    box-shadow: 7x 7px 25px ${({ theme }) => theme.shadow},
      7px 7px 30px ${({ theme }) => theme.white_inverse};
    animation: ${shake} 1.3s linear;
  }
`;

import React, { useEffect, useState } from "react";
import { BsArrowBarUp } from "react-icons/bs";
import { Outlet } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { ScrollToTopButton } from "./layout.styles";

const HomeLayout = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        setShow(window.pageYOffset > 100);
      });
    }
  });

  return (
    <div>
      <Header />

      <Outlet />
      <Footer />
      {show && (
        <ScrollToTopButton
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <BsArrowBarUp />
        </ScrollToTopButton>
      )}
    </div>
  );
};

export default HomeLayout;

import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
  text-align: center;
`;

const NoResult = ({ message }) => {
  return <StyledContainer>{message}</StyledContainer>;
};

export default NoResult;

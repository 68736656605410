import styled from "styled-components";
export const StyledContainer = styled.div`
  width: 80%;
  margin: 0 auto;

  .grid_masonry {
    display: flex;
    justify-content: space-around;
    gap: 1rem;

    img {
      background: ${({ theme }) => theme.container};
      width: 100%;
      display: block;
      border-radius: 0.8rem;
      margin: 1rem;
      padding: 1rem;
      cursor: pointer;
      :hover {
        transform: scale(1.05);
      }
    }
  }
  /* margin-left: -30px; */
`;

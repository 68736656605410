import moment from "moment";
import React from "react";
import { Button } from "../Button";
import {
  BtnContainer,
  STD,
  STH,
  STR,
  StyledTable,
  StyledTBody,
  StyledTHead,
} from "./table.styles";
import { SITE } from "../../utils/settings";
import { useTranslation } from "react-i18next";

const EventsTable = ({ isScreen, data, onEditClick, onDeleteClick }) => {
  const { t } = useTranslation();
  return (
    <StyledTable>
      <StyledTHead>
        <STR>
          <STH>NO</STH>
          <STH>bannerImage</STH>
          <STH>{t("admin.table.title")}</STH>
          <STH>{t("admin.table.date")}</STH>
          {isScreen && <STH>{t("admin.table.actions")}</STH>}
        </STR>
      </StyledTHead>
      <StyledTBody>
        {(data ?? []).map((event, _idx) => (
          <STR>
            <STD>{_idx + 1}</STD>
            <STD>
              <img
                src={`${SITE.url}${event?.bannerImage ?? ""}`}
                alt={event?.title}
              />
            </STD>
            <STD>{event?.title ?? "-/-"}</STD>
            <STD>{moment(event?.date).format("DD-MMM-YYYY") ?? "-/-"}</STD>
            {isScreen && (
              <STD asFlex>
                <BtnContainer>
                  <Button onClick={() => onEditClick(event)}>
                    {t("buttons.edit")}
                  </Button>
                </BtnContainer>
                <BtnContainer className='delete'>
                  <Button onClick={(e) => onDeleteClick(e, event._id)}>
                    {t("buttons.delete")}
                  </Button>
                </BtnContainer>
              </STD>
            )}
          </STR>
        ))}
      </StyledTBody>
    </StyledTable>
  );
};

export default EventsTable;

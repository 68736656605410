import React from "react";
import styled from "styled-components";
import { Spinner } from "../Spinner";

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageLoader = () => {
  return (
    <StyledContainer>
      <Spinner color="rgba(27, 184, 201)" />
    </StyledContainer>
  );
};

export default PageLoader;

import React from "react";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../../../components";
import { StyledVideoSection } from "./styles";

const VideoGallery = ({ data }) => {
  const { t } = useTranslation();
  return (
    <StyledVideoSection id='video-gallery'>
      <SectionHeader title={t("headers.videoGallery")} />
      <div className='vid_gallery'>
        {data.map((item, idx) => {
          return (
            <div className='video' key={item._id}>
              <iframe
                src={item.videoUrl}
                frameBorder='0'
                allow='autoplay; encrypted-media'
                allowFullScreen
                title='video'
              />
            </div>
          );
        })}
      </div>
    </StyledVideoSection>
  );
};

export default VideoGallery;

import React from "react";
import { Button } from "../../../Button";
import { Input } from "../../../Input";
import Modal from "../../../Modal/Modal";
import { StyledButtonLeft } from "../../comp.styles";
import { useAddHomeClass } from "./useAddHomeClass";
import { useTranslation } from "react-i18next";

const AddNewClassModal = ({ isOpen, onClose, prev }) => {
  const { t } = useTranslation();
  const {
    fields,
    onSubmit,
    handleFieldChange,
    errors,
    isDisabled,
    isLoading,
    onUpdateSubmit,
  } = useAddHomeClass(onClose, prev);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        prev
          ? `${t("buttons.edit")} ${prev?.name}`
          : `${t("buttons.adminTitles.addDanceStyle")}`
      }
    >
      <Input
        placeholder={t("placeholders.enterDanceStyle")}
        type='text'
        label={t("placeholders.danceStyle")}
        isRequired
        error={errors.name}
        onChange={handleFieldChange}
        value={fields.name}
        name='name'
      />

      <StyledButtonLeft>
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={prev ? onUpdateSubmit : onSubmit}
        >
          {t("buttons.save")}
        </Button>
      </StyledButtonLeft>
    </Modal>
  );
};

export default AddNewClassModal;

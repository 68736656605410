import React from "react";
import {
  StyledTextArea,
  StyledInputContainer,
  StyledInputError,
  StyledInputFlex,
  StyledLabel,
  StyledRequired,
} from "./input.styles";

const TextArea = ({
  alt,
  leftIcon,
  rightIcon,
  placeholder,
  value,
  onChange,
  name,
  label,
  type,
  error,
  isRequired,
}) => {
  return (
    <StyledInputContainer>
      <StyledLabel>
        {label} {isRequired && <StyledRequired>*</StyledRequired>}
      </StyledLabel>
      <StyledInputFlex alt={alt}>
        {leftIcon}
        <StyledTextArea
          required={isRequired}
          value={value}
          onChange={onChange}
          type={type}
          name={name}
          placeholder={placeholder}
        />
        {rightIcon}
      </StyledInputFlex>
      {error && <StyledInputError>{error}</StyledInputError>}
    </StyledInputContainer>
  );
};

export default TextArea;

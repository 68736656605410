import React from "react";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../components";
import {
  StyledClassesSection,
  StyledDanceItem,
  StyledFlexSection,
} from "./Classes.styles";

const Classes = ({ data }) => {
  const { t } = useTranslation();
  return (
    <StyledClassesSection>
      <SectionHeader title={t("headers.classes")} />
      <StyledFlexSection>
        {data.map((cla, _idx) => (
          <StyledDanceItem>{cla.name}</StyledDanceItem>
        ))}
      </StyledFlexSection>
    </StyledClassesSection>
  );
};

export default Classes;

import React from "react";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import { Spinner } from "../../../components/Spinner";
import { useGetSingleInstructor } from "../../../network/queries/useGetSingleInstructor";
import { SITE } from "../../../utils/settings";
import {
  InstructorSocialIcon,
  InstructorsSocial,
  StyledAboutInstructor,
  StyledInstructorContainer,
} from "./Instructors.styles";

const SingleInstructorPage = () => {
  const { data, isLoading } = useGetSingleInstructor();

  console.log(data);

  return (
    <StyledInstructorContainer>
      {isLoading ? (
        <Spinner />
      ) : (
        <StyledAboutInstructor>
          <div className='image_holder'>
            <img
              src={`${SITE.url}${data?.instructor.profileImage}`}
              alt={data?.instructor.fullName}
            />
          </div>
          <div className='about_instructor'>
            <h1 className='instructor_name'>{data?.instructor.fullName}</h1>
            <h2 className='instructor_style'>{data?.instructor.style}</h2>
            <p className='instructor_desc'>{data?.instructor.about}</p>

            <InstructorsSocial>
              {data?.instructor.facebookLink && (
                <InstructorSocialIcon
                  href={data?.instructor.facebookLink ?? ""}
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaFacebookF className='facebook' />
                </InstructorSocialIcon>
              )}
              {data?.instructor.instagramLink && (
                <InstructorSocialIcon
                  href={data?.instructor.instagramLink ?? ""}
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaInstagram className='instagram' />
                </InstructorSocialIcon>
              )}
              {data?.instructor.tiktokLink && (
                <InstructorSocialIcon
                  href={data?.instructor.tiktokLink ?? ""}
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaTiktok className='tiktok' />
                </InstructorSocialIcon>
              )}
            </InstructorsSocial>
          </div>
        </StyledAboutInstructor>
      )}
    </StyledInstructorContainer>
  );
};

export default SingleInstructorPage;

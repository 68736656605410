import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { AdminLayout, HomeLayout } from "../components/Layouts";
import {
  AdminPhotoSession,
  AdminPricingScreen,
  AdminVideoScreen,
  // Classes,
  ContactPage,
  DanceStylesScreen,
  DashboardScreen,
  EventDetails,
  EventPage,
  EventsPage,
  Forgotpassword,
  HomeClass,
  HomePage,
  Instructors,
  InstructorsScreen,
  LocationsScreen,
  LoginScreen,
  NotFoundPage,
  PhotoSession,
  PoznanTimeTable,
  PricingScreen,
  ResetPassword,
  RulesScreen,
  SomScreen,
  Settings,
  SingleInstructorPage,
  SremTimetable,
  StudentScreen,
  Studio,
  StudioItem,
  Timetable,
} from "../screens";
import VerifyAccount from "../screens/admin/LoginScreen/VerifyAccount";
import ScrollToTop from "./ScrollToTop";

const Private = () => {
  const token = localStorage.getItem("Access_Token");
  if (!token) return <Navigate to='/admin-secret' replace />;
  if (token) return <Outlet />;
};

const AppRouter = () => {
  return (
    <Router basename='/'>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<HomeLayout />}>
            <Route path='/' index element={<HomePage />} />
            <Route path='/studio' element={<Studio />} />
            <Route path='/studio/:name' element={<StudioItem />} />
            <Route path='/studio/photo_session' element={<PhotoSession />} />
            {/* <Route path='/classes' element={<Classes />} /> */}
            <Route path='/timetables' element={<Timetable />} />
            <Route path='/timetables/poznan' element={<PoznanTimeTable />} />
            <Route path='/timetables/srem' element={<SremTimetable />} />
            <Route path='/instructors' element={<Instructors />} />
            <Route
              path='/instructors/:id/:name'
              element={<SingleInstructorPage />}
            />
            <Route path='/events' element={<EventsPage />} />
            <Route path='/events/:id' element={<EventDetails />} />
            <Route path='/pricing' element={<PricingScreen />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/rules' element={<RulesScreen />} />
            <Route path='/som' element={<SomScreen />} />
            <Route path='*' element={<NotFoundPage />} />
          </Route>
          <Route path='/admin-secret' element={<LoginScreen />} />
          <Route path='/admin-secret/forgot' element={<Forgotpassword />} />
          <Route path='/admin-secret/reset' element={<ResetPassword />} />
          <Route path='/admin-secret/verify' element={<VerifyAccount />} />
          {/* ADMIN SCREENS */}
          <Route element={<Private />}>
            <Route path='/admin' element={<AdminLayout />}>
              <Route path='dashboard' element={<DashboardScreen />} />
              <Route path='students' element={<StudentScreen />} />
              <Route path='classes' element={<DanceStylesScreen />} />
              <Route path='events' element={<EventPage />} />
              <Route path='instructors' element={<InstructorsScreen />} />
              <Route path='locations' element={<LocationsScreen />} />
              <Route path='home-class' element={<HomeClass />} />
              <Route path='photo-session' element={<AdminPhotoSession />} />
              <Route path='settings' element={<Settings />} />
              <Route path='pricing' element={<AdminPricingScreen />} />
              <Route path='videos' element={<AdminVideoScreen />} />
            </Route>
          </Route>
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default AppRouter;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { AddNewDanceStyleModal, Button, DanceTable } from "../../../components";
import { Spinner } from "../../../components/Spinner";
import { useDisclosure } from "../../../hooks";
import { AdminService } from "../../../network/services";
import { AdminSecHeader, StyledAddButton } from "../components/AdminSecHeader";
import { useGetDanceStyles } from "../hooks";
import { useTranslation } from "react-i18next";

const DanceStyles = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetDanceStyles();
  const [prev, setPrev] = useState(null);
  const queryClient = useQueryClient();

  const { mutate } = useMutation((id) => AdminService.deleteClass(id), {
    onSuccess: (data) => {
      toast.success(`${data.message}`, {
        position: "top-right",
      });
      onClose();
      queryClient.invalidateQueries(["all_dance_styles"]);
    },
    onError: (err) => {
      toast.error(err.response.data, {
        position: "top-right",
      });
    },
  });

  const onDeleteSubmit = (e, id) => {
    e.preventDefault();
    mutate(id);
  };

  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <div>
      <AdminSecHeader title={t("admin.sidebar.timeTable")} />
      <StyledAddButton>
        <Button
          onClick={() => {
            onOpen();
            setPrev(null);
          }}
        >
          {t("buttons.adminTitles.addNewClass")}
        </Button>
      </StyledAddButton>
      {isLoading ? (
        <Spinner />
      ) : (
        <DanceTable
          data={data?.danceStyles}
          isScreen
          onEditClick={(obj) => {
            setPrev(obj);
            onOpen();
          }}
          onDeleteClick={(e, id) => onDeleteSubmit(e, id)}
        />
      )}
      <AddNewDanceStyleModal isOpen={isOpen} onClose={onClose} prev={prev} />
    </div>
  );
};

export default DanceStyles;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetLocations } from "../../../../network/queries/useGetLocations";
import { AdminService } from "../../../../network/services";
import { validators } from "../../../../utils/validator";

export const usePricing = (onClose, prev) => {
  const initialFields = {
    contractTitle: "",
    contractPrice: "",
    category: "",
    percentageSaved: "",
    oneTimePrice: "",
    categoryPriceTitle: "",
    categoryTitle: "",
    categoryHeader: "",
    location: "",
    pricingType: "",
  };

  const [fields, setFields] = useState(initialFields);
  const [errors, setFormError] = useState(initialFields);
  const [addedCategories, setAddedCategories] = useState([]);

  useEffect(() => {
    setFields({
      ...fields,
      categoryTitle:
        fields.categoryTitle === ""
          ? "Number of classes per week:"
          : fields.categoryTitle,
      categoryPriceTitle:
        fields.categoryPriceTitle === ""
          ? "price for one month"
          : fields.categoryPriceTitle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const genId = () => {
    return Math.floor(100000 + Math.random() * 900000);
  };

  useEffect(() => {
    setFields(prev ? prev : initialFields);
    setAddedCategories(prev ? prev.contracts : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const { data: locations } = useGetLocations();

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    const error = validators[name](value);
    setFields({ ...fields, [name]: value });
    setFormError((formError) => ({ ...formError, [name]: error }));
  };

  const onAddClick = () => {
    if (fields.contractTitle && fields.contractPrice) {
      setAddedCategories((prev) => [
        ...prev,
        {
          id: genId(),
          title: fields.contractTitle,
          price: fields.contractPrice,
        },
      ]);
    }
    setFields((prev) => ({ ...prev, contractPrice: "", contractTitle: "" }));
  };

  const onDeleteClick = (id) => {
    const filtered = addedCategories.filter((cat) => cat.id !== id);
    setAddedCategories(filtered);
  };

  const queryClient = useQueryClient();
  const {
    category,
    oneTimePrice,
    percentageSaved,
    categoryHeader,
    categoryPriceTitle,
    categoryTitle,
    location,
    pricingType,
  } = fields;

  const [isFullPass, setIsFUllPass] = useState(false);

  const handleCheckBoxChange = () => {
    setIsFUllPass((prev) => !prev);
  };

  const { isLoading: isSaveLoading, mutate } = useMutation(
    () =>
      AdminService.createPricing({
        category,
        oneTimePrice,
        percentageSaved,
        contracts: addedCategories,
        categoryHeader,
        categoryPriceTitle,
        categoryTitle,
        isFullPass,
        location,
        pricingType,
      }),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        queryClient.invalidateQueries(["all_pricing"]);
        setFields(initialFields);
        setAddedCategories([]);
        onClose();
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const { isLoading: isUpdateLoading, mutate: update } = useMutation(
    () =>
      AdminService.editPricing(
        {
          category,
          oneTimePrice,
          percentageSaved,
          contracts: addedCategories,
          categoryHeader,
          categoryPriceTitle,
          categoryTitle,
          isFullPass,
          location,
          pricingType,
        },
        prev._id,
      ),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["all_pricing"]);
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        onClose();
        setFields(initialFields);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };
  const onUpdateSubmit = (e) => {
    e.preventDefault();
    update();
  };

  const isLoading = isSaveLoading || isUpdateLoading;
  const isDisabled = Object.values(errors).some(Boolean);

  const cityOptions = locations?.locations.map((loc) => ({
    label: loc.city,
    value: loc.city,
  }));

  return {
    fields,
    isDisabled,
    isLoading,
    onUpdateSubmit,
    onSubmit,
    errors,
    handleFieldChange,
    onAddClick,
    addedCategories,
    onDeleteClick,
    cityOptions,
    isFullPass,
    handleCheckBoxChange,
  };
};

import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GeneralService } from "../../../network/services";
import { validators } from "../../../utils/validator";

export const useLogin = () => {
  const navigate = useNavigate();
  const initialState = {
    email: "",
    password: "",
  };

  const [show, setShow] = useState(false);
  const [fields, setFields] = useState(initialState);
  const [errors, setFormError] = useState(initialState);

  const handleShowToggle = () => setShow((prev) => !prev);

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    const error = validators[name](value);
    setFields({ ...fields, [name]: value });
    setFormError((formError) => ({ ...formError, [name]: error }));
  };

  const { isLoading, mutate } = useMutation(
    () => GeneralService.login(fields),
    {
      onSuccess: (data) => {
        if (data?.user.type === "admin") {
          navigate("/admin/dashboard", { replace: true });
          localStorage.setItem("Access_Token", data?.accessToken);
          window.location.reload();
        }
        toast.success(`${data.message}`, {
          position: "top-right",
        });
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  const isDisabled = Object.values(errors).some(Boolean);

  return {
    fields,
    errors,
    handleFieldChange,
    onSubmit,
    isLoading,
    isDisabled,
    show,
    handleShowToggle,
  };
};

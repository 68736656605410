import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { GeneralService } from "../../../network/services";

export const useEvents = () => {
  const { isLoading, data } = useQuery(["all_events"], () =>
    GeneralService.getAllEvents(),
  );

  return { data, isLoading };
};

export const useSingleEvent = () => {
  const { id } = useParams();

  const { isLoading, data } = useQuery(["event", id], () =>
    GeneralService.getSingleEvent(id),
  );

  return { data, isLoading };
};

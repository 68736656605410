import React from "react";
import { Pl, Ua, Us } from "react-flags-select";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../hooks";
import LangSelect from "../LangSelect/LangSelect";
import { Switch } from "../Toggle";
import { StyledSiteSettingContainer } from "./SiteHeaderSettings.styles";

// import { BsSun } from "react-icons/bs";

const SiteHeaderSettings = () => {
  const { theme, changeTheme } = useTheme();
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <StyledSiteSettingContainer>
      {/* <StyledThemeToggler>awesome toggler</StyledThemeToggler> */}
      <LangSelect
        options={[
          { icon: <Pl />, value: "pl" },
          { icon: <Us />, value: "en" },
          { icon: <Ua />, value: "ua" },
        ]}
        onChange={(val) => {
          changeLanguage(val);
        }}
      />
      <Switch
        isChecked={theme === "dark"}
        onChange={() => changeTheme(theme === "light" ? "dark" : "light")}
      />
    </StyledSiteSettingContainer>
  );
};

export default SiteHeaderSettings;

import styled from "styled-components";
import { devices } from "../../../styles";

export const LoginPageContainer = styled.div`
  width: 100%;
`;

export const StyledLoginForm = styled.form`
  width: 30%;
  margin: 10rem auto;
  padding: 2rem;
  background: ${({ theme }) => theme.container};
  border-radius: 0.5rem;
  .formTitle {
    font-size: 2rem;
    margin-bottom: 3rem;
    text-align: center;
    color: ${({ theme }) => theme.primary};
    text-transform: uppercase;
  }
  @media ${devices.tablet} {
    width: 97%;
  }
`;

import React from "react";
import { useTranslation } from "react-i18next";
import { Group2Image } from "../../../assets/images";
import { PageHeader, TimeTableData } from "../../../components";
import { useTimetable } from "../../../network/queries";

const PoznanTimeTable = () => {
  const { data: poznan } = useTimetable("Poznań");
  const { t } = useTranslation();
  return (
    <div>
      <PageHeader
        title={`${t("navbar.timetable")} (${t("cities.poznan")})`}
        image={Group2Image}
      />
      <TimeTableData data={poznan?.timetable} />
    </div>
  );
};

export default PoznanTimeTable;

import React from "react";
import { Button } from "../Button";
import {
  BtnContainer,
  STD,
  STH,
  STR,
  StyledTable,
  StyledTBody,
  StyledTHead,
} from "./table.styles";
import { SITE } from "../../utils/settings";
import { useTranslation } from "react-i18next";

const InstructorTable = ({ isScreen, data, onEditClick, onDeleteClick }) => {
  const { t } = useTranslation();
  return (
    <StyledTable>
      <StyledTHead>
        <STR>
          <STH>NO</STH>
          <STH>{t("admin.table.image")}</STH>
          <STH>{t("admin.table.fullName")}</STH>
          <STH>{t("admin.table.email")}</STH>
          {/* <STH>{t("admin.table.danceStyle")}</STH> */}
          <STH>{t("admin.table.location")}</STH>
          <STH>Tiktok</STH>
          <STH>Facebook</STH>
          <STH>Instagram</STH>
          {isScreen && <STH>{t("admin.table.actions")}</STH>}
        </STR>
      </StyledTHead>
      <StyledTBody>
        {(data ?? []).map((ins, _idx) => (
          <STR>
            <STD>{_idx + 1}</STD>
            <STD>
              <img
                src={`${SITE.url}${ins?.profileImage ?? ""}`}
                alt={ins?.title}
              />
            </STD>
            <STD>{ins?.fullName ?? "-/-"}</STD>
            <STD>{ins?.email ?? "-/-"}</STD>
            {/* <STD>
              {ins?.danceStyles.length > 0
                ? ins?.danceStyles?.map((sty, idx) => (
                    <p key={idx}>{sty.name}</p>
                  ))
                : "-/-"}
            </STD> */}
            <STD>{ins?.location ?? "-/-"}</STD>
            <STD>
              <a href={ins?.tiktokLink} target='_blank' rel='noreferrer'>
                {t("buttons.view")}
              </a>
            </STD>
            <STD>
              <a href={ins?.facebookLink} target='_blank' rel='noreferrer'>
                {t("buttons.view")}
              </a>
            </STD>
            <STD>
              <a href={ins?.instagramLink} target='_blank' rel='noreferrer'>
                {t("buttons.view")}
              </a>
            </STD>
            {isScreen && (
              <STD asFlex>
                <BtnContainer>
                  <Button onClick={() => onEditClick(ins)}>
                    {t("buttons.edit")}
                  </Button>
                </BtnContainer>
                <BtnContainer className='delete'>
                  <Button onClick={(e) => onDeleteClick(e, ins._id)}>
                    {t("buttons.delete")}
                  </Button>
                </BtnContainer>
              </STD>
            )}
          </STR>
        ))}
      </StyledTBody>
    </StyledTable>
  );
};

export default InstructorTable;

import styled from "styled-components";

export const StyledSiteSettingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 90%;
  margin: 1rem auto;
`;

export const StyledThemeToggler = styled.div`
  margin-right: 0.8rem;
`;

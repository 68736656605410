import styled, { keyframes } from "styled-components";
import { devices } from "../../styles";

const blink = keyframes`
    0% { opacity: 1 }
    100% { opacity: 0 }
`;

export const TimeTableContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: ${({ theme }) => theme.container};
  width: 80%;
  margin: 3rem auto;
  /* border-left: 2px solid ${({ theme }) => theme.border};
  border-right: 2px solid ${({ theme }) => theme.border}; */
  @media ${devices.laptopL} {
    overflow-x: scroll;
    width: 95%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }
`;

export const StyleColumnContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  margin: 0 0.2vh;

  h3.title {
    text-align: center;
    position: relative;
    color: ${({ theme }) => theme.primary};
    background-color: transparent !important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 100%;
    padding: 1.5rem 0.5rem;

    ::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: currentcolor;
      /* opacity: 0.125; */
      opacity: 0.05;
    }
  }
`;

export const ClassesContainer = styled.div`
  text-align: left;
  .class {
    flex-direction: column;
    box-shadow: 0 0 20px 0 transparent;
    padding: 1rem 2.4rem;
    position: relative;
    flex-shrink: 0;
    flex: 1;
    background: ${({ theme }) => theme.body};
    margin: 0.2rem 0;
    font-size: 90%;
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    align-items: stretch;
    width: 100%;
    min-height: 10rem;
    transition: all 120ms ease-in-out;
    transition-delay: 10ms;

    @media ${devices.tablet} {
      padding: 1rem;
    }

    :hover {
      transform: translateY(-25px) scale(1.09);
      border-radius: 0.1rem;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
      z-index: 100;
      small {
        color: ${({ theme }) => theme.primary};
      }
    }

    small {
      display: block;
      width: 100%;
      font-size: 0.9rem;
      font-weight: bold;
      transition: 100ms ease;
    }
    p.time {
      margin-top: 0.51rem;
      font-size: 0.8rem;
      span {
        &.blink {
          margin: 0 0.1rem;
          animation: ${blink} 1.2s ease infinite;
        }
        &.seperator {
          margin: 0 0.4rem;
        }
      }
    }
    .instructors {
      margin: 1rem 0px;
      p {
        color: ${({ theme }) => theme.gray};
        font-size: 0.9rem;
        margin: 0.2rem 0px;
      }
    }
  }
`;

import React from "react";
import { StyledSectionHeader } from "./sectionheader.styles";

const SectionHeader = ({ title }) => {
  return (
    <StyledSectionHeader>
      <h3>{title}</h3>
    </StyledSectionHeader>
  );
};

export default SectionHeader;

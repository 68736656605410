import React from "react";
import { Button } from "../../../Button";
import { Input } from "../../../Input";
import Modal from "../../../Modal/Modal";
import { StyledButtonLeft } from "../../comp.styles";
import { useVideo } from "./useVideo";
import { useTranslation } from "react-i18next";

const AddVideoModal = ({ isOpen, onClose, prev }) => {
  const { t } = useTranslation();
  const {
    fields,
    handleFieldChange,
    errors,
    isLoading,
    isDisabled,
    onUpdateSubmit,
    onSubmit,
  } = useVideo(onClose, prev);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("buttons.adminTitles.addNewVideo")}
    >
      <Input
        placeholder={t("placeholders.entVidUrl")}
        type='text'
        label={t("placeholders.videoUrl")}
        isRequired
        name='videoUrl'
        value={fields.videoUrl}
        onChange={handleFieldChange}
        error={errors.videoUrl}
      />

      <StyledButtonLeft>
        <Button
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={prev ? onUpdateSubmit : onSubmit}
        >
          {t("buttons.save")}
        </Button>
      </StyledButtonLeft>
    </Modal>
  );
};

export default AddVideoModal;

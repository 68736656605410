import { useQuery } from "@tanstack/react-query";
import { AdminService } from "../../../network/services";

export const useGetDashboardStats = () => {
  const { data, isLoading } = useQuery(["dashboard-stats"], () =>
    AdminService.getDashboardStats(),
  );
  const { data: info, isLoading: isLoadingInfo } = useQuery(
    ["dashboard-info"],
    () => AdminService.getDashboardInfo(),
  );
  return { data, isLoading, info, isLoadingInfo };
};

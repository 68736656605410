import React from "react";
import {
  StyledInputContainer,
  StyledInputError,
  StyledInputFlex,
  StyledLabel,
  StyledRequired,
  StyledSelect,
} from "./input.styles";

const Select = ({
  disabled,
  placeholder,
  value,
  onChange,
  name,
  label,
  error,
  isRequired,
  options,
}) => {
  const remappedOptions = options ?? [];
  return (
    <StyledInputContainer disabled={disabled}>
      <StyledLabel>
        {label} {isRequired && <StyledRequired>*</StyledRequired>}
      </StyledLabel>
      <StyledInputFlex>
        <StyledSelect
          required={isRequired}
          value={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
        >
          <option value=''>{placeholder}</option>
          {remappedOptions.map((option, _idx) => (
            <option key={_idx} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
      </StyledInputFlex>
      {error && <StyledInputError>{error}</StyledInputError>}
    </StyledInputContainer>
  );
};

export default Select;

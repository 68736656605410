import React from "react";
import { useNavigate } from "react-router-dom";
import {
  NotFoundContainer,
  NotFoundContent,
  StyledAside,
  StyledMain,
} from "./NotFoundPage.styles";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <NotFoundContainer>
      <NotFoundContent>
        <StyledAside>
          <img
            src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/Mirror.png'
            alt='404-not-found'
          />
        </StyledAside>
        <StyledMain>
          <h1>Sorry!</h1>
          <p>
            <em>. . . Opps.</em>
            <br />
            This page does not exist on our server
          </p>
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            GO BACK!
          </button>
        </StyledMain>
      </NotFoundContent>
    </NotFoundContainer>
  );
};

export default NotFoundPage;

import React from "react";
import { Button } from "../Button";
import {
  BtnContainer,
  STD,
  STH,
  STR,
  StyledTable,
  StyledTBody,
  StyledTHead,
} from "./table.styles";
import { useTranslation } from "react-i18next";

const DanceTable = ({ isScreen, data, onEditClick, onDeleteClick }) => {
  const { t } = useTranslation();
  return (
    <StyledTable>
      <StyledTHead>
        <STR>
          <STH>NO</STH>
          <STH>{t("admin.table.class")}</STH>
          <STH>{t("admin.table.location")}</STH>
          <STH>{t("admin.table.day")}</STH>
          <STH>{t("admin.table.beginTime")}</STH>
          <STH>{t("admin.table.endTime")}</STH>
          {isScreen && <STH>{t("admin.table.actions")}</STH>}
        </STR>
      </StyledTHead>
      <StyledTBody>
        {(data ?? []).map((dance, _idx) => (
          <STR>
            <STD>{_idx + 1}</STD>
            <STD>{dance?.name ?? "-/-"}</STD>
            <STD>{dance?.location ?? "-/-"}</STD>
            <STD>{dance?.day ?? "-/-"}</STD>
            <STD>{dance?.beginTime ?? "-/-"}</STD>
            <STD>{dance?.endTime ?? "-/-"}</STD>
            {isScreen && (
              <STD asFlex>
                <BtnContainer>
                  <Button onClick={() => onEditClick(dance)}>
                    {t("buttons.edit")}
                  </Button>
                </BtnContainer>
                <BtnContainer className='delete'>
                  <Button onClick={(e) => onDeleteClick(e, dance._id)}>
                    {t("buttons.delete")}
                  </Button>
                </BtnContainer>
              </STD>
            )}
          </STR>
        ))}
      </StyledTBody>
    </StyledTable>
  );
};

export default DanceTable;

import axios from "axios";

const httpClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://api.goodvibes-studiotanca.pl/v1"
      : "http://localhost:5000/v1",
  headers: {
    "Content-type": "application/json",
  },
});

export default httpClient;

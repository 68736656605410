const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validators = {
  email: (email) => {
    if (email === "") {
      return "Email is required";
    }
    if (!EMAIL_PATTERN.test(email)) {
      return "Email is invalid";
    }
  },
  password: (password) => {
    if (password === "") {
      return "Password is required";
    }
    if (password.length < 6) {
      return "Password must be at least 6 characters";
    }
  },
  fullName: (fullName) => {
    if (fullName === "") {
      return "Full name is required";
    }
  },
  phoneNumber: (phoneNumber) => {
    if (phoneNumber === "") {
      return "Phone number is required";
    }
    if (phoneNumber.length < 9) {
      return "Phone number must be at least 9 characters";
    }
  },
  location: (location) => {
    if (location === "") {
      return "location is required";
    }
  },
  danceStyle: (danceStyle) => {
    if (danceStyle === "") {
      return "Please select a dance style";
    }
  },
  name: (name) => {
    if (name === "") {
      return "Please enter class name";
    }
  },
  day: (day) => {
    if (day === "") {
      return "please select a day";
    }
  },
  beginTime: (beginTime) => {
    if (beginTime === "") {
      return "Please choose start time";
    }
  },
  endTime: (endTime) => {
    if (endTime === "") {
      return "please choose end time";
    }
  },
  instructors: (instructors) => {
    if (instructors === "") {
      return "";
    }
  },
  title: (title) => {
    if (title === "") {
      return "";
    }
  },
  date: (date) => {
    if (date === "") {
      return "";
    }
  },
  documentLink: (documentLink) => {
    if (documentLink === "") {
      return "";
    }
  },
  facebookLink: (facebookLink) => {
    if (facebookLink === "") {
      return "facebook link is required";
    }
  },
  instagramLink: (instagramLink) => {
    if (instagramLink === "") {
      return "Instagram link is required";
    }
  },
  tiktokLink: (tiktokLink) => {
    if (tiktokLink === "") {
      return "Tiktok link is required";
    }
  },
  about: (about) => {
    if (about === "") {
      return "about is required";
    }
  },
  message: (message) => {
    if (message === "") {
      return "Please provide a message";
    }
  },
  subject: (subject) => {
    if (subject === "") {
      return "Please provide a subject for the email";
    }
  },
  aboutUs: (aboutUs) => {
    if (aboutUs === "") {
      return "";
    }
  },
  twitterLink: (twitterLink) => {
    if (twitterLink === "") {
      return "";
    }
  },
  linkedInLink: (linkedInLink) => {
    if (linkedInLink === "") {
      return "";
    }
  },
  city: (city) => {
    if (city === "") {
      return "city is required";
    }
  },
  address: (address) => {
    if (address === "") {
      return "address is required";
    }
  },
  contractTitle: (contractTitle) => {
    if (contractTitle === "") {
      return "";
    }
  },

  contractPrice: (contractPrice) => {
    if (contractPrice === "") {
      return "";
    }
  },
  category: (category) => {
    if (category === "") {
      return "";
    }
  },
  percentageSaved: (percentageSaved) => {
    if (percentageSaved === "") {
      return "";
    }
  },
  oneTimePrice: (oneTimePrice) => {
    if (oneTimePrice === "") {
      return "";
    }
  },
  videoUrl: (videoUrl) => {
    if (videoUrl === "") {
      return "";
    }
  },

  categoryPriceTitle: (val) => {
    if (val === "") {
      return "";
    }
  },
  categoryTitle: (val) => {
    if (val === "") {
      return "";
    }
  },
  categoryHeader: (val) => {
    if (val === "") {
      return "";
    }
  },
  pricingType: (val) => {
    if (val === "") {
      return "";
    }
  },
  code: (code) => {
    if (code === "") {
      return "Code is required";
    }
  },
};

const validateFormSubmit = (formData) => {
  let errors = {};
  let isvalid = true;
  Object.keys(formData).forEach((key) => {
    const error = validators[key](formData[key]);
    errors[key] = error;
    if (error) {
      isvalid = false;
    }
  });
  return { errors, isvalid };
};

export const checkFormData = (formData, setFormError) => {
  const { errors, isvalid } = validateFormSubmit(formData);
  if (setFormError) {
    setFormError(errors);
  }
  return isvalid;
};

import React, { useCallback } from "react";
import {
  StyledPoznanContainer,
  StyledPoznanLeftContainer,
  StyledPoznanRightContainer,
} from "./studioItem.styles";
import { useSpring, animated as animatedSpring } from "react-spring";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { GeneralService } from "../../../../network/services";
import { SITE } from "../../../../utils/settings";

const StudioItem = ({ city, image }) => {
  const { name } = useParams();
  const [{ xy }, set] = useSpring(() => ({ st: 0, xy: [0, 0] }));
  const onMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      set.start({
        xy: [x - window.innerWidth / 2, y - window.innerHeight / 2],
      }),
    [set],
  );
  const interpImgMove = xy.to(
    (x, y) =>
      `perspective(400px) translateY(${x / 10}px) translateX(${-y / 10}px) `,
  );
  const { t } = useTranslation();

  const { data } = useQuery(["studio", name], () =>
    GeneralService.getLocation(name),
  );

  return (
    <StyledPoznanContainer>
      <StyledPoznanLeftContainer>
        <div>
          <h1 className='bigger'>{city ? city : data?.location?.city}</h1>
          <h1 className='smaller'>Studio</h1>
          <div className='main_instructor'>
            <div>
              <em>{t("danceWithUs")}</em>
            </div>
          </div>
        </div>
      </StyledPoznanLeftContainer>

      <StyledPoznanRightContainer>
        <animatedSpring.img
          onMouseMove={onMove}
          style={{ transform: interpImgMove }}
          src={image ? image : `${SITE.url}${data?.location?.imageUrl}`}
          alt='city'
        />
      </StyledPoznanRightContainer>
    </StyledPoznanContainer>
  );
};

export default StudioItem;

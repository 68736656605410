import styled from "styled-components";

export const StyledContainerGroup = styled.div`
  width: 100%;
  .content {
    display: flex;
    width: 100%;
    align-items: center;
  }
  button {
    width: 100%;
  }
`;

export const StyledTable = styled.div`
  margin-top: 1rem;
  border: 1px solid ${({ theme }) => theme.border};
  .tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.border};
  }
  .th {
    background: ${({ theme }) => theme.primary};
    border-bottom: 1px solid ${({ theme }) => theme.primary};
    :first-of-type {
      border-right: 1px solid #fff;
    }
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete_icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: ${({ theme }) => theme.mainRed};
    opacity: 0.5;
    transition: 0.3s ease-in-out;
    :hover {
      opacity: 1;
    }
  }
  .td {
    :first-of-type {
      border-right: 1px solid ${({ theme }) => theme.border};
    }
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

import styled from "styled-components";

export const StyledInputContainer = styled.div`
  width: 100%;
  margin: 1rem 0px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "")};
  :hover {
    .label {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

export const StyledInputFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: 0.3s ease;
  border-top: ${({ alt, theme }) =>
    !alt ? `0.01rem solid ${theme.border}` : "none"};
  border-bottom: ${({ alt, theme }) =>
    !alt ? `0.01rem solid ${theme.border}` : `0.01rem solid ${theme.border}`};
  border-left: ${({ alt, theme }) =>
    !alt ? `0.01rem solid ${theme.border}` : `0.01rem solid ${theme.border}`};
  border-right: ${({ alt, theme }) =>
    !alt ? `0.01rem solid ${theme.border}` : "none"};
  border-radius: ${({ alt, theme }) => (!alt ? "4px" : "0px")};
  padding: 1rem;
  :hover,
  :focus {
    border-color: ${({ theme }) => theme.primary};
    border-radius: ${({ alt }) => (!alt ? "0.8rem 0 0.8rem 0.8rem" : "none")};
  }
`;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 10px; */
`;

export const StyledInput = styled.input`
  width: 90%;
  /* padding: 1rem; */
  background: transparent;
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  border: none;
  background: none;
  outline: none;
  ::placeholder {
    opacity: 0.4;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 90%;
  /* padding: 1rem; */
  background: transparent;
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  border: none;
  background: none;
  outline: none;
  ::placeholder {
    opacity: 0.4;
  }
`;

export const StyledSelect = styled.select`
  width: 100%;
  /* padding: 1rem; */
  background: transparent;
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  border: none;
  background: none;
  outline: none;

  ::placeholder {
    opacity: 0.4;
  }
  option {
    background: ${({ theme }) => theme.white_inverse};
    color: ${({ theme }) => theme.text};
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  font-size: 0.8rem;
  transition: 0.3s ease;
`;

export const StyledRequired = styled.span`
  margin-left: 0.2rem;
  color: red;
  font-size: 1rem;
  margin-top: 0.3rem;
`;

export const StyledInputError = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;

import React from "react";
import { AiOutlineForm } from "react-icons/ai";
import { PageHeader } from "../../../components";
import { Spinner } from "../../../components/Spinner";
import { SITE } from "../../../utils/settings";
import {
  StyledEventContainer,
  StyledEventNavigateButton,
} from "./EventsPage.styles";
import { useSingleEvent } from "./useEvents";

const EventDetails = () => {
  const { data, isLoading } = useSingleEvent();

  const createMarkup = (tags) => {
    return {
      __html: tags,
    };
  };

  return (
    <StyledEventContainer>
      <PageHeader title={data?.event.title ?? "Event"} />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='content'>
          <div className='left'>
            <img
              src={`${SITE.url}${data?.event.bannerImage ?? ""}`}
              alt='event_name'
            />
          </div>
          <div className='right'>
            <h1 className='title'>{data?.event.title}</h1>
            <div
              className='gen'
              dangerouslySetInnerHTML={createMarkup(data?.event.content)}
            />
            {data?.event.documentLink && (
              <StyledEventNavigateButton
                href={data?.event.documentLink}
                target='_blank'
                rel='noreferrer'
              >
                <AiOutlineForm />
              </StyledEventNavigateButton>
            )}
          </div>
        </div>
      )}
    </StyledEventContainer>
  );
};

export default EventDetails;

import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.3rem;
  overflow: hidden;
`;

export const STR = styled.tr`
  /* background: red; */
  /* max-height: 2rem !important; */
  :nth-of-type(even) {
    background: ${({ theme }) => theme.container};
  }
`;
export const STH = styled.th`
  /* background: pink; */
  padding: 1rem 0;
`;

export const StyledTHead = styled.thead`
  background: ${({ theme }) => theme.primary};
`;

export const StyledTBody = styled.tbody`
  /* background: yellow;
  margin-top: 1rem; */
`;
export const STD = styled.td`
  padding: 1rem 0;
  text-align: center;
  display: ${({ asFlex }) => (asFlex ? "flex" : "")};
  align-items: ${({ asFlex }) => (asFlex ? "center" : "")};
  justify-content: ${({ asFlex }) => (asFlex ? "center" : "")};
  /* background: red; */
  /* max-width: 20%; */
  img {
    width: 4rem;
  }
`;

export const BtnContainer = styled.div`
  button {
    padding: 0.6rem 2rem;
    margin-right: 1rem;
  }

  &.delete {
    button {
      background: ${({ theme }) => theme.mainRed};
    }
  }
`;

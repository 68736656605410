import styled from "styled-components";
import { devices } from "../../../../styles";
export const StyledPoznanContainer = styled.section`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: space-around;
  @media ${devices.tablet} {
    min-height: 40vh;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const StyledPoznanLeftContainer = styled.div`
  width: 39%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: red; */

  @media ${devices.laptop} {
    width: 100%;
    margin-top: 4rem;
  }

  h1 {
    font-size: 3.5rem;
    text-transform: uppercase;
    &.bigger {
      font-weight: bolder;
      /* font-family: "Bubblegum Sans", csursive; */
      color: ${({ theme }) => theme.body_inverse};
    }
    &.smaller {
      font-size: 2.7rem;
      color: ${({ theme }) => theme.primary};
    }
  }
  p {
    font-size: 2.2rem;
    color: ${({ theme }) => theme.primary_bold};
  }
  .main_instructor {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    div {
      width: 50%;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
    }
    em {
      font-size: 1rem;
      color: ${({ theme }) => theme.primary_bold};
      font-weight: bold;
      /* width: 80%; */
      text-align: center;
      position: relative;
      :after {
        content: "";
        position: absolute;
        background: ${({ theme }) => theme.primary};
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        width: 7rem;
        margin-left: 2rem;
        height: 0.1rem;
        @media ${devices.laptop} {
          left: -30%;
          top: 120%;
          bottom: 0;
        }
      }
    }
  }
`;

export const StyledPoznanRightContainer = styled.div`
  width: 59%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media ${devices.laptop} {
    width: 100%;
    padding-top: 4rem;
    /* overflow: auto; */
  }
  img {
    border-radius: 10px;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.2);
    width: 75%;
    height: 75%;
    /* opacity: 0.3; */
    object-fit: contain;
    transition: 0.4s ease;
  }
`;

// content: '';
// display: inline-block;
// width: 6.25rem;
// position: relative;
// border-bottom: 1px solid currentColor;
// margin-left: 1rem;
// top: -0.25rem;

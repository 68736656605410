import styled from "styled-components";

export const DashboardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledContainer = styled.div`
  background: ${({ theme, color }) => (color ? color : theme.container)};
  padding: 2.4rem 2rem;
  border-radius: 0.5rem;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    text-align: right;
  }
  svg {
    font-size: 2rem;
  }
`;

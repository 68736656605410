import { useQuery } from "@tanstack/react-query";
import React from "react";
import { NoResult, PageHeader, SEO } from "../../../components";
import { GeneralService } from "../../../network/services";
import {
  Container,
  StyledPriceContainer,
  StyledPricingPageContainer,
} from "./PricePage.styles";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "../../../utils/constants";

const PricePage = () => {
  const { t } = useTranslation();

  const { data } = useQuery(["all_pricing_home"], () =>
    GeneralService.getAllPricing(),
  );

  const token = "zł";

  return (
    <StyledPricingPageContainer>
      <SEO title={t("meta.title.pricing")} />
      <PageHeader title={t("headers.pricing")} />
      <Container>
        {data?.pricings.length === 0 ? (
          <NoResult message='Unable to load pricing information' />
        ) : (
          data?.pricings.map((item, _idx) => {
            return (
              <StyledPriceContainer
                key={item._id}
                hideOnIndividual={item.pricingType === CONSTANTS.individual}
                height={
                  item.pricingType === CONSTANTS.fullPass ||
                  item.pricingType === CONSTANTS.individual ||
                  item.pricingType === CONSTANTS.formations
                }
                flexEnd={item.contracts.length === 0}
              >
                <div className='title_container'>
                  <h2>{item.category}</h2>
                  <p className='location'>{item.location}</p>
                </div>
                <div className='middle'>
                  {item.oneTimePrice &&
                    item.pricingType !== CONSTANTS.fullPass &&
                    item.pricingType !== CONSTANTS.individual && (
                      <>
                        <p className='one_time_price_title'>
                          {t("prices.oneTimeClass")}
                        </p>
                        <p className='one_time_price'>
                          {item.oneTimePrice}
                          <span>{token}</span>
                        </p>
                      </>
                    )}
                  {item.categoryHeader && (
                    <h3 className='category_header'>{item.categoryHeader}</h3>
                  )}
                  <div className='bottom'>
                    {item.pricingType !== CONSTANTS.individual && (
                      <p className='table_header_title'>
                        {t("prices.monthly")}
                      </p>
                    )}

                    {item.contracts.length > 0 &&
                      item.pricingType !== CONSTANTS.fullPass &&
                      item.pricingType !== CONSTANTS.individual && (
                        <div className='table_header_container'>
                          <p>{item.categoryTitle}</p>
                          <p>{item.categoryPriceTitle}</p>
                        </div>
                      )}
                    {item.contracts.length > 0 &&
                      item.contracts.map((contract, _idx) => (
                        <div className='table_body_container'>
                          <p>{contract.title}</p>
                          <p className='price'>
                            {contract.price}
                            <span>{token}</span>
                          </p>
                        </div>
                      ))}
                    {(item.pricingType === CONSTANTS.fullPass ||
                      item.pricingType === CONSTANTS.individual) && (
                      <div className='full_pass_price'>
                        {item.oneTimePrice}
                        <span>{token}</span>
                      </div>
                    )}
                  </div>
                </div>
              </StyledPriceContainer>
            );
          })
        )}
      </Container>
    </StyledPricingPageContainer>
  );
};

export default PricePage;
// ${({ flexEnd }) => (flexEnd ? "start" : "space-between")}

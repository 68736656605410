import styled from "styled-components";
import { devices } from "../../../../../styles";

export const StyledINstructorsScrollHolder = styled.div`
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 0 6rem;
  @media ${devices.tablet} {
    width: 100%;
    padding: 0 1rem;
  }
`;

export const StyledHomeInstructorsContainer = styled.div`
  width: 100%;
  height: auto;
  overflow-x: scroll;
  padding: 24px;
  scroll-behavior: smooth;
  /* margin: 0 auto; */
  display: flex;
  scrollbar-width: none;
  gap: 2rem;
  scroll-snap-type: x mandatory;
  scroll-padding: 24px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledHomeInstructorItem = styled.div`
  flex: 0 0 30%;
  padding: 24px;
  height: 20rem;
  /* background: ${({ theme }) => theme.white_inverse}; */
  margin-top: ${({ space }) => (space ? space : 0)};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  scroll-snap-align: start;

  /* padding: 1rem; */

  :first-child {
    margin-left: 3rem;
  }

  :hover {
    /* z-index: 5; */
    img {
      transform: scale(1.1);
    }
    /* .about {
      opacity: 1;
      padding: 1rem 0px;
    } */
    .about {
      .title {
        color: ${({ theme }) => theme.primary_bold};
      }
    }
  }

  /* :nth-child(even) {
    margin-top: 3rem;
  } */

  img {
    width: 100%;
    border-radius: 1rem;
    object-fit: contain;
    /* object-position: top; */
    overflow: hidden;
  }
  .about {
    padding: 1rem 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: rgba(0, 0, 0, 0.3); */

    .title {
      color: ${({ theme }) => theme.gray};
      font-size: 1rem;
      margin-bottom: 0.2rem;
      text-align: center;
      transition: 0.3s ease;
      text-transform: capitalize;
      font-weight: bold;
    }

    .skill {
      font-size: 0.7rem;
      text-align: center;
    }
  }

  @media ${devices.tablet} {
    min-width: 100%;
    margin: 0 0.5rem;
    :first-child {
      margin-left: 100vw;
    }
    /* :nth-child(even) {
      margin-top: none;
    } */
  }
`;

export const StyledArrowContainer = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  /* background-color: green; */
`;

export const StyledArrow = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2rem;
  cursor: pointer;

  transition: opacity 0.4s ease-out;
  opacity: 1;

  /* :hover {
    opacity: 1;
    background: ${({ left, theme }) =>
    left
      ? `linear-gradient(to right,${theme.primary}, rgba(0, 0, 0, 0))`
      : `linear-gradient(to right, rgba(0, 0, 0, 0),${theme.primary})`};
  } */
  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${({ theme }) => theme.text};
  }

  @media ${devices.tablet} {
    padding: 0.3rem;
  }
`;

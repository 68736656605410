import React from "react";
import { useTranslation } from "react-i18next";
import {
  ClassesContainer,
  StyleColumnContainer,
  TimeTableContainer,
} from "./timetable.styles";

const TimeTableData = ({ data }) => {
  const { t } = useTranslation();
  return (
    <TimeTableContainer>
      {data?.length > 0 &&
        data?.map((header, _idx) => (
          <StyleColumnContainer key={_idx}>
            <h3 className='title'>{t(`days.${header.day}`)}</h3>
            <ClassesContainer>
              {header.classes.map((danceClass, _idx) => (
                <div className='class' key={_idx}>
                  <small>{danceClass.name}</small>
                  <p className='time'>
                    {danceClass.beginTime.split(":")[0]}
                    <span className='blink'>:</span>
                    {danceClass.beginTime.split(":")[1]}

                    <span className='seperator'>-</span>
                    {danceClass.endTime.split(":")[0]}
                    <span className='blink'>:</span>
                    {danceClass.endTime.split(":")[1]}
                  </p>
                  <div className='instructors'>
                    {danceClass.instructors.map((instructor) => (
                      <p>{instructor.fullName}</p>
                    ))}
                  </div>
                </div>
              ))}
            </ClassesContainer>
          </StyleColumnContainer>
        ))}
    </TimeTableContainer>
  );
};

export default TimeTableData;

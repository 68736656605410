import { useQuery } from "@tanstack/react-query";
import { GeneralService } from "../services";

export const useGetLocations = (filter) => {
  const { data, isLoading } = useQuery(
    ["all_locations", filter ?? "Poznań"],
    () => GeneralService.getAllLocations(),

    {
      onSuccess: (data) => {
        console.log("Done");
      },
      onError: (err) => {
        console.log(err);
      },
    },
  );
  return { data, isLoading };
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  AddNewInstructorModal,
  Button,
  InstructorTable,
} from "../../../components";
import { Spinner } from "../../../components/Spinner";
import { useDisclosure } from "../../../hooks";
import { AdminService } from "../../../network/services";
import { AdminSecHeader, StyledAddButton } from "../components/AdminSecHeader";
import { useGetAllInstructors } from "../hooks";
import { useTranslation } from "react-i18next";

const Instructors = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data, isLoading } = useGetAllInstructors();
  const [prev, setPrev] = useState(null);

  const queryClient = useQueryClient();

  const { mutate } = useMutation((id) => AdminService.deleteInstructor(id), {
    onSuccess: (data) => {
      toast.success(`${data.message}`, {
        position: "top-right",
      });
      onClose();
      queryClient.invalidateQueries(["all_instructors"]);
    },
    onError: (err) => {
      toast.error(err.response.data, {
        position: "top-right",
      });
    },
  });

  const onDeleteSubmit = (e, id) => {
    e.preventDefault();
    mutate(id);
  };

  return (
    <div>
      <AdminSecHeader title={t("admin.sidebar.allInstructors")} />
      <StyledAddButton>
        <Button
          onClick={() => {
            onOpen();
            setPrev(null);
          }}
        >
          {t("buttons.adminTitles.addNewInstructor")}
        </Button>
      </StyledAddButton>
      {isLoading ? (
        <Spinner />
      ) : (
        <InstructorTable
          data={data?.instructors}
          isScreen
          onEditClick={(obj) => {
            setPrev(obj);
            onOpen();
          }}
          onDeleteClick={(e, id) => onDeleteSubmit(e, id)}
        />
      )}
      <AddNewInstructorModal isOpen={isOpen} onClose={onClose} prev={prev} />
    </div>
  );
};

export default Instructors;

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    transition: all 0.1s linear;
  }
  html {
    scroll-behavior: smooth;
  }

  body a svg#logo #container #studio-container path {
    fill: ${({ theme }) => theme.gray} !important;
    font-weight: bold !important;
    ${"" /* transform: translate(395px, 452.9px) scale(1.22, 1) !important; */}
  }
  body.modal-open {
    overflow: hidden;
  }
  
  body {
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.body};
  }
`;

import React from "react";
import { useTranslation } from "react-i18next";
import { SuzProfile } from "../../../assets/images";
import { PageHeader, TimeTableData } from "../../../components";
import { useTimetable } from "../../../network/queries";

const SremTimetable = () => {
  const { data: srem } = useTimetable("Śrem");
  const { t } = useTranslation();
  return (
    <div>
      <PageHeader
        title={`${t("navbar.timetable")} (${t("cities.srem")})`}
        image={SuzProfile}
        contain
      />
      <TimeTableData data={srem?.timetable} />
    </div>
  );
};

export default SremTimetable;

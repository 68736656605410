import React from "react";
import { Button, Input } from "../../../components";
import { StyledButtonLeft } from "../../../components/ModalComponents/comp.styles";
import { LoginPageContainer, StyledLoginForm } from "./styles";
import { useForgotPassword } from "./useForgotPassword";

const Forgotpassword = () => {
  const { fields, isLoading, onSubmit, handleFieldChange, errors, isDisabled } =
    useForgotPassword();
  return (
    <LoginPageContainer>
      <StyledLoginForm>
        <h1 className='formTitle'>Reset your account.</h1>
        <Input
          name='email'
          type='email'
          placeholder='Enter your email address'
          isRequired
          label='Email Address'
          onChange={handleFieldChange}
          error={errors.email}
          value={fields.email}
        />
        {/* <Input
          name='password'
          rightIcon={
            show ? (
              <AiOutlineEyeInvisible onClick={handleShowToggle} />
            ) : (
              <AiOutlineEye onClick={handleShowToggle} />
            )
          }
          type={show ? "text" : "password"}
          placeholder='Enter your password'
          isRequired
          label='Password'
          value={fields.password}
          onChange={handleFieldChange}
          error={errors.password}
        /> */}

        <StyledButtonLeft>
          <Button
            isLoading={isLoading}
            onClick={onSubmit}
            isDisabled={isDisabled}
          >
            RESET ACCOUNT
          </Button>
        </StyledButtonLeft>
      </StyledLoginForm>
    </LoginPageContainer>
  );
};

export default Forgotpassword;

import styled from "styled-components";
import { devices } from "../../../styles";

export const StyledSomContainer = styled.ol`
  width: 60%;
  margin: 4rem auto;
  font-size: 0.9rem;
  li {
    margin: 1rem 0px;
  }
  @media ${devices.tablet} {
    width: 80%;
  }
  @media ${devices.mobileM} {
    width: 80%;
  }
`;

import styled from "styled-components";

export const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
  .container {
    width: 49%;
    p {
      text-transform: uppercase;
      font-size: 0.8rem;
      margin-bottom: 1rem;
    }
    img {
      display: block;
      width: 100%;
    }
  }
`;

import React from "react";
import { Spinner } from "../Spinner";
import { StyledButton } from "./button.styles";

const Button = ({ children, onClick, isLoading, isDisabled, spinnerColor }) => {
  return (
    <StyledButton onClick={onClick} disabled={isLoading || isDisabled}>
      {isLoading ? <Spinner color={spinnerColor} /> : children}
    </StyledButton>
  );
};

export default Button;

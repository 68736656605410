import AdminService from "../../../network/services/AdminService";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetStudents = () => {
  const { isLoading, data } = useQuery(
    ["all_students"],
    () => AdminService.getAllStudents(),
    {
      onSuccess: () => {
        console.log("DONE");
      },
      onError: (e) => {
        toast.error(e.response.data);
      },
    },
  );
  return { data, isLoading };
};

import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { BsPeople } from "react-icons/bs";
import { MdOutlineEmojiPeople } from "react-icons/md";
import { AdminSecHeader } from "../components/AdminSecHeader";
import { useGetDashboardStats } from "../hooks";
import { DashboardContainer, DashCard } from "./components/DashCard";
import { DanceTable, InstructorTable, StudentTable } from "../../../components";
import { useTranslation } from "react-i18next";

const DashboardScreen = () => {
  const { t } = useTranslation();
  const { data, isLoading, info, isLoadingInfo } = useGetDashboardStats();

  const stats = [
    {
      count: data?.stats.students,
      icon: <BsPeople />,
      title: `${t("admin.sidebar.students")}`,
    },
    {
      count: data?.stats.instructors,
      icon: <AiOutlineUser />,
      title: `${t("admin.sidebar.instructors")}`,
    },
    {
      count: data?.stats.classes,
      icon: <MdOutlineEmojiPeople />,
      title: `${t("admin.sidebar.classes")}`,
    },
  ];

  return (
    <div>
      <DashboardContainer>
        {stats.map((item, _idx) => (
          <DashCard item={item} isLoading={isLoading} />
        ))}
      </DashboardContainer>
      <AdminSecHeader title={t("admin.sidebar.students")} />
      <StudentTable data={info?.students} />
      <AdminSecHeader title={t("admin.sidebar.instructors")} />
      <InstructorTable data={info?.instructors} />
      <AdminSecHeader title={t("admin.sidebar.classes")} />
      <DanceTable data={info?.classes} />
    </div>
  );
};

export default DashboardScreen;

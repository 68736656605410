import React, { useEffect, useState } from "react";
import {
  StlyedOption,
  StyledDropDownHeader,
  StyledDropDownListContainer,
  StyledLangSelectContainer,
} from "./LangSelect.styles";
import { FiChevronDown } from "react-icons/fi";
import { useOnClickOutside } from "../../hooks";
import { useTranslation } from "react-i18next";
import { Pl } from "react-flags-select";

const LangSelect = ({ options, placeholder, onChange }) => {
  const LangSelectRef = React.useRef();
  useOnClickOutside(LangSelectRef, () => setShowDropDown(false));
  const [showDropDown, setShowDropDown] = useState(false);

  const [currentlyLangSelected, setCurrentlyLangSelected] = useState("");

  const { i18n } = useTranslation();

  const prevLang = options.findIndex((lang) => {
    return lang.value === i18n.language;
  });

  const [selectedIndex, setSelectedIndex] = useState(prevLang);

  console.log(selectedIndex);

  useEffect(() => {
    setCurrentlyLangSelected(
      placeholder !== "" && placeholder !== undefined ? (
        placeholder
      ) : options[selectedIndex] ? (
        options[selectedIndex]?.icon
      ) : (
        <Pl />
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledLangSelectContainer
      ref={LangSelectRef}
      onClick={() => setShowDropDown((prev) => !prev)}
    >
      <StyledDropDownHeader>
        {currentlyLangSelected}
        <FiChevronDown className='drop_down_icon' />
      </StyledDropDownHeader>
      {showDropDown && (
        <StyledDropDownListContainer>
          {options.map((opt, idx) => (
            <StlyedOption
              onClick={() => {
                setCurrentlyLangSelected(opt.icon);
                setSelectedIndex(idx);
                onChange(opt.value);
              }}
              key={idx}
              value={opt.value}
            >
              {opt.icon}
            </StlyedOption>
          ))}
        </StyledDropDownListContainer>
      )}
    </StyledLangSelectContainer>
  );
};

export default LangSelect;

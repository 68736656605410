import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import httpClient from "../../../../network/httpclient";

export const useUploadPhotos = (onClose, prev) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [preview, setPreview] = useState([]);
  const [progress, setProgress] = useState(0);
  const [previous, setPrevious] = useState("");

  useEffect(() => {
    setPrevious(prev ? prev.imageUrl : "");
  }, [prev]);

  const handleImageSelect = (e) => {
    let images = [];
    setSelectedImages(e.target.files);
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }
    setPreview(images);
  };

  const formData = new FormData();
  Object.values(selectedImages).forEach((file) => {
    formData.append("images", file);
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    () =>
      httpClient.post(`/admin/upload_photos`, formData, {
        headers: {
          ContentType: "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("Access_Token") ?? ""}`,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      }),
    {
      onSuccess: (data) => {
        toast.success(`${data?.data.message}`, {
          position: "top-right",
        });
        onClose();
        queryClient.invalidateQueries(["all_photo_sessions"]);
        setProgress(0);
        setSelectedImages([]);
        setPreview([]);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  return {
    selectedImages,
    preview,
    handleImageSelect,
    onSubmit,
    isLoading,
    progress,
    previous,
  };
};

import { lighten } from "polished";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { devices, sizes } from "../../styles";

export const LinkR = styled(Link)`
  color: ${({ theme }) => theme.gray};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: ${sizes.sm};
  text-decoration: none;
  letter-spacing: 0.08rem;
  :hover {
    color: ${({ theme }) => theme.primary_bold};
    font-weight: bold;
  }
  &.active {
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    .drop_down_icon {
      color: ${({ theme }) => theme.primary} !important;
    }
  }
  .drop_down_icon {
    margin-left: 0.3rem;
    color: ${({ theme }) => theme.gray_50} !important;
    font-size: ${sizes.sm};
  }
  @media ${devices.laptopL} {
    font-size: 0.7rem;
  }
  @media ${devices.laptop} {
    font-size: 1rem;
  }
  @media ${devices.laptop} {
    justify-content: space-between;
    width: 100%;
  }
`;

export const StyledLinkItem = styled.div`
  position: relative;
  display: ${({ noFlex }) => (noFlex ? "" : "inline-flex")};
  padding: 0.6rem 0px;
  margin: 0 ${sizes.md};
  :hover {
    .dropdown {
      display: block;
    }
  }
  @media ${devices.laptopL} {
    margin: 0 ${sizes.xs};
    /* background: red; */
  }
  @media ${devices.laptop} {
    margin: 0 5px;
    /* background: yellow; */
  }
`;

export const StyledThemeToggle = styled.div`
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-70%) !important;
  @media ${devices.tablet} {
    display: none;
  }
`;

export const NavWrapper = styled.div`
  width: 100%;
  transition: 0.4s ease;
  &.fixed {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    background: ${({ theme }) => theme.body};
    padding: 01rem 0;
    box-shadow: 0px 1px 2px rgba(27, 184, 201, 0.8);
    svg.logo {
      width: 10rem;
      @media ${devices.mobileM} {
        width: 7rem;
      }
    }
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  @media ${devices.mobileS} {
    width: 97%;
  }
`;

export const NavLinkItem = styled.div`
  /* background: red; */
  display: flex;
  align-items: center;
  @media ${devices.laptop} {
    display: none;
  }

  .flex-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 1.2rem;
    label {
      margin-left: 0.6rem;
    }
  }
`;

export const SubMenuLinkR = styled(Link)`
  padding: 0.3rem;
  display: block;
  text-decoration: none;
  text-transform: capitalize;
  color: ${({ theme }) => theme.gray_50};
  transition: 0.3s ease;
  margin: 0.1rem;
  font-size: ${sizes.sm};
  /* border-radius: 0.2rem; */
  padding: 0.3rem 0.2rem;
  border-bottom: 0.2px solid ${({ theme }) => lighten(0.5, theme.border)};
  :last-of-type {
    border-bottom: none;
  }
  :hover {
    background: ${({ theme }) => theme.primary};
    color: #fff !important;
  }
  &.active {
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
  }
`;

export const NavLogo = styled(Link)`
  .logo {
    width: 12rem;
  }
  img {
    width: 12rem;
  }
  /* color: ${({ theme }) => theme.primary_bold}; */
  /* text-decoration: none;
  font-size: ${sizes.lg};
  font-weight: 600;
  font-family: "Bubblegum Sans", cursive; */
  @media ${devices.laptop} {
    position: relative;
    z-index: 100;
    /* font-size: ${sizes.md}; */
  }
  @media ${devices.mobileM} {
    .logo {
      width: 6rem;
    }
  }
`;

export const StyledDropDownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: -35%;
  width: 180px;
  min-height: auto;
  max-height: 250px;
  display: none;
  background: ${({ theme }) => theme.body};
  transition: 0.3s ease;
  cursor: default;
  border-radius: 0.2rem;
  padding: 0.7rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 30;
`;

export const StyledHamburger = styled.div`
  display: none;
  @media ${devices.laptop} {
    z-index: 100;
    display: flex;
    align-items: center;

    .flex-icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 1.2rem;
      label {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
      }
    }
  }
  .line {
    fill: none;
    stroke: ${({ theme }) => theme.primary};
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
`;

export const StyledMenuMobile = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: none;
  &.active {
    display: block;
  }
`;

export const StyledMenuOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 3;
`;

export const StyledMenuMobileContainer = styled.div`
  width: 0px;
  transition: 0.4s ease;
  position: relative;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 0px 1rem;
  padding-top: 7rem;

  &.active {
    width: 70%;
    height: 100%;
    background: ${({ theme }) => theme.body};

    @media ${devices.laptop} {
      width: 50%;
      padding-top: 10rem;
    }
    @media ${devices.fold} {
      padding-top: 7rem;
    }
    @media ${devices.mobileL} {
      width: 100%;
    }
    @media ${devices.mobileM} {
      width: 100%;
    }
  }
`;

export const StyledMobileMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.4rem 0px;
  @media ${devices.fold} {
    margin: 0.2rem 0px;
  }
  ${LinkR} {
    font-size: ${sizes.md};
    @media ${devices.fold} {
      font-size: ${sizes.sm};
    }
  }
  &.active {
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    .drop_down_icon {
      color: ${({ theme }) => theme.primary} !important;
    }
  }
  .drop_down_icon {
    margin-left: 1rem;
    color: ${({ theme }) => theme.gray_50} !important;
    font-size: ${sizes.md};
    @media ${devices.fold} {
      font-size: ${sizes.sm};
    }
  }
`;

export const ChildContainer = styled.div`
  padding-top: 1rem;
  &:before {
    margin-top: -1px;
    height: 0;
  }
  &:after {
    margin-bottom: -1px;
    height: 0;
  }

  ${({ pt }) =>
    pt &&
    css`
      padding-top: 1rem;
    `}

  ${SubMenuLinkR} {
    font-size: ${sizes.md};
    @media ${devices.fold} {
      font-size: ${sizes.sm};
    }
  }
`;

import styled from "styled-components";

export const StyledLangSelectContainer = styled.div`
  width: auto;
  padding: 0.1rem 0.2rem;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

export const StyledDropDownHeader = styled.div`
  color: ${({ theme }) => theme.gray_50};
  display: flex;
  align-items: center;
  .drop_down_icon {
    margin-left: 0.3rem;
  }
`;

export const StyledDropDownListContainer = styled.div`
  position: absolute;
  z-index: 300;
  width: 100%;
  left: 0;
  top: 100%;
  background: ${({ theme }) => theme.body};
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
`;

export const StlyedOption = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0px;
  transition: 0.3s ease;
  cursor: pointer;
  user-select: none;

  :hover {
    background: ${({ theme }) => theme.primary};
  }
`;

import React from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Button, Input } from "../../../components";
import { StyledButtonLeft } from "../../../components/ModalComponents/comp.styles";
import { LoginPageContainer, StyledLoginForm } from "./styles";
import { useResetPassword } from "./useResetPassword";

const ResetPassword = () => {
  const {
    show,
    handleFieldChange,
    fields,
    errors,
    onSubmit,
    isLoading,
    isDisabled,
    handleShowToggle,
  } = useResetPassword();
  return (
    <LoginPageContainer>
      <StyledLoginForm>
        <h1 className='formTitle'>Reset your account.</h1>
        <Input
          name='email'
          type='email'
          placeholder='Enter your email address'
          isRequired
          label='Email Address'
          onChange={handleFieldChange}
          error={errors.email}
          value={fields.email}
        />
        <Input
          name='password'
          rightIcon={
            show ? (
              <AiOutlineEyeInvisible onClick={handleShowToggle} />
            ) : (
              <AiOutlineEye onClick={handleShowToggle} />
            )
          }
          type={show ? "text" : "password"}
          placeholder='Enter your new password'
          isRequired
          label='New Password'
          value={fields.password}
          onChange={handleFieldChange}
          error={errors.password}
        />

        <StyledButtonLeft>
          <Button
            isLoading={isLoading}
            onClick={onSubmit}
            isDisabled={isDisabled}
          >
            RESET ACCOUNT
          </Button>
        </StyledButtonLeft>
      </StyledLoginForm>
    </LoginPageContainer>
  );
};

export default ResetPassword;

import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GeneralService } from "../../../network/services";
import { validators } from "../../../utils/validator";

export const useVerifyCode = () => {
  const navigate = useNavigate();
  const initialFIelds = {
    code: "",
  };

  const [fields, setFields] = useState(initialFIelds);
  const [errors, setFormError] = useState(initialFIelds);

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    const error = validators[name](value);
    setFields({ ...fields, [name]: value });
    setFormError((formError) => ({ ...formError, [name]: error }));
  };

  const { isLoading, mutate } = useMutation(
    () => GeneralService.verifyCode(fields),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        navigate("/admin-secret/reset");
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  const isDisabled = Object.values(errors).some(Boolean);

  return {
    fields,
    isLoading,
    onSubmit,
    handleFieldChange,
    errors,
    isDisabled,
  };
};

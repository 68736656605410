import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../../../components";
import { GeneralService } from "../../../../../network/services";
import { SITE } from "../../../../../utils/settings";
import { StyledGallerySection } from "./styles";

const PhotoGallery = () => {
  const { data } = useQuery(["all_home_photos_sessions"], () =>
    GeneralService.getAllPhotos(),
  );
  const { t } = useTranslation();

  return (
    <StyledGallerySection>
      {data?.photos.length > 0 && (
        <>
          <SectionHeader title={t("headers.photoGallery")} />
          <div className='container'>
            <div className='gallery_container'>
              {(data?.photos ?? []).map((photo) => (
                <img
                  className='scroller'
                  alt={photo._id}
                  src={`${SITE.url}${photo.imageUrl}`}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </StyledGallerySection>
  );
};

export default PhotoGallery;

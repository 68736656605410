import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { AddNewEventModal, Button, EventsTable } from "../../../components";
import { Spinner } from "../../../components/Spinner";
import { useDisclosure } from "../../../hooks";
import { AdminService } from "../../../network/services";
import { useEvents } from "../../general/EventsSection/useEvents";
import { AdminSecHeader, StyledAddButton } from "../components/AdminSecHeader";
import { useTranslation } from "react-i18next";

const EventPage = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [prev, setPrev] = useState(null);
  const { isLoading, data } = useEvents();
  const queryClient = useQueryClient();

  const { mutate } = useMutation((id) => AdminService.deleteEvent(id), {
    onSuccess: (data) => {
      toast.success(`${data.message}`, {
        position: "top-right",
      });
      onClose();
      queryClient.invalidateQueries(["all_events"]);
    },
    onError: (err) => {
      toast.error(err.response.data, {
        position: "top-right",
      });
    },
  });

  const onDeleteSubmit = (e, id) => {
    e.preventDefault();
    mutate(id);
  };

  return (
    <div>
      <AdminSecHeader title={t("admin.sidebar.events")} />
      <StyledAddButton>
        <Button onClick={onOpen}>{t("buttons.adminTitles.addNewEvent")}</Button>
      </StyledAddButton>
      {isLoading ? (
        <Spinner />
      ) : (
        <EventsTable
          data={data?.events}
          isScreen
          onEditClick={(obj) => {
            setPrev(obj);
            onOpen();
          }}
          onDeleteClick={(e, id) => onDeleteSubmit(e, id)}
        />
      )}
      <AddNewEventModal isOpen={isOpen} onClose={onClose} prev={prev} />
    </div>
  );
};

export default EventPage;

import httpClient from "../httpclient";

export default class GeneralService {
  static async joinusNow(input) {
    const { data } = await httpClient.post("/join_us_now", input);
    return data;
  }
  static async getDanceStylesByFilter(filter) {
    const { data } = await httpClient.get(
      `/get_dance_styles_by_filter/${filter}`,
    );
    return data;
  }
  static async getAllEvents() {
    const { data } = await httpClient.get("/get_events");
    return data;
  }
  static async getLocation(name) {
    const { data } = await httpClient.get(`/get_single_location/${name}`);
    return data;
  }
  static async getAllLocations() {
    const { data } = await httpClient.get("/get_all_locations");
    return data;
  }
  static async fetchAllHomeDance() {
    const { data } = await httpClient.get("/get_all_home_dance");
    return data;
  }
  static async getTimeTable(filter) {
    const { data } = await httpClient.get(`/get_timetable/${filter}`);
    return data;
  }
  static async getSingleEvent(id) {
    const { data } = await httpClient.get(`/get_single_event/${id}`);
    return data;
  }
  static async login(input) {
    const { data } = await httpClient.post("/auth/admin_login", input);
    return data;
  }
  static async forgotPassword(input) {
    const { data } = await httpClient.post("/auth/forgot_password", input);
    return data;
  }
  static async verifyCode(input) {
    const email = localStorage.getItem("user_email");
    const { data } = await httpClient.post("/auth/verify_code", {
      ...input,
      email,
    });
    return data;
  }
  static async resetPassword(input) {
    const { data } = await httpClient.post("/auth/reset_password", input);
    return data;
  }
  static async getAllInstructors() {
    const { data } = await httpClient.get("/get_all_instructors");
    return data;
  }
  static async getSingleInstructor(id) {
    const { data } = await httpClient.get(`/get_single_instructors/${id}`);
    return data;
  }
  static async contactUs(input) {
    const { data } = await httpClient.post(`/contact_us`, input);
    return data;
  }
  static async getAllPhotos() {
    const { data } = await httpClient.get(`/get_all_photos`);
    return data;
  }
  static async getAllPhotosNoLimit() {
    const { data } = await httpClient.get(`/get_all_photos_no_limit`);
    return data;
  }
  static async getContactDetails() {
    const { data } = await httpClient.get(`/get_site_setting`);
    return data;
  }
  static async getAllPricing() {
    const { data } = await httpClient.get(`/get_all_pricing`);
    return data;
  }
  static async getAllVideos() {
    const { data } = await httpClient.get(`/get_all_videos`);
    return data;
  }
}

import styled from "styled-components";
import { devices } from "../../../styles";

export const StyledPricingPageContainer = styled.div`
  width: 100%;
`;

export const Container = styled.section`
  width: 80%;
  margin: 4rem auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  @media ${devices.tablet} {
    width: 95%;
  }
`;

export const StyledPriceContainer = styled.div`
  width: 28%;
  border-radius: 50px;
  background: ${({ theme }) => theme.container};
  display: flex;
  flex-direction: column;
  justify-content: start;
  box-shadow: 1px 1px 1px ${({ theme }) => theme.shadow};
  @media ${devices.laptopL} {
    width: 40%;
  }
  @media ${devices.tablet} {
    width: 70%;
  }

  @media ${devices.mobileM} {
    width: 100%;
  }

  :hover {
    transform: scale(1.04);
    box-shadow: 2px 2px 25px ${({ theme }) => theme.shadow};
    .title_container {
      .location {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .title_container {
    border-radius: 50px;
    background: ${({ theme }) => theme.primary};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    min-height: ${({ height }) => (height ? "12rem" : "6rem")};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    z-index: 3;
    h2 {
      font-size: 1.4rem;
      text-align: center;
      max-width: 70%;
      @media ${devices.mobileL} {
        font-size: 1.1rem;
      }
    }
    .location {
      margin-top: 0.7rem;
      opacity: 0;
      transition: 0.3s ease;
      transform: translateY(-2rem);
    }
  }

  .middle {
    padding-top: 2rem;
    margin: 0px 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-top: -3rem;
    margin-bottom: 1rem;
    background: ${({ theme }) => theme.secContainer};
  }

  .one_time_price_title {
    text-align: center;
    margin-top: 3rem;
  }
  .one_time_price {
    text-align: center;
    margin: 0.7rem 0px;
    font-size: 3rem;
    font-weight: 700;
    color: ${({ theme }) => theme.primary};
    span {
      font-size: 1.5rem;
      margin-left: 0.5rem;
    }
  }

  .category_header {
    margin-top: ${({ height }) => (height ? "4rem" : "1rem")};
    margin-bottom: 1rem;
    font-size: 1.1rem;
    text-align: center;
  }

  .table_header_title {
    text-align: center;
    margin: 0.2rem;
    padding-bottom: 0.7rem;
    opacity: 0.6;
  }

  .bottom {
    background: ${({ theme }) => theme.primary_30};
    padding: ${({ height }) => (height ? "2rem 0" : "0.8rem 0")};
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.3);
    margin-top: ${({ height }) => (height ? "4rem" : "1rem")};
  }

  .table_header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.border_inverse};
    border-top: 1px solid ${({ theme }) => theme.border_inverse};
    p {
      font-size: 0.7rem;
      text-align: center;
      padding: 1rem;
      opacity: 0.6;
    }
  }

  .table_body_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid ${({ theme }) => theme.border_inverse};

    p {
      padding: 1rem;
      &.price {
        color: ${({ theme }) => theme.primary};
        font-size: 1.4rem;
        span {
          font-size: 0.9rem;
          margin-left: 0.4rem;
        }
      }
    }
  }

  .full_pass_price {
    border-top: ${({ hideOnIndividual }) =>
      hideOnIndividual
        ? "none"
        : `1px solid ${({ theme }) => theme.border_inverse}`};
    padding: 2rem;
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    color: ${({ theme }) => theme.primary};
    span {
      font-size: 1rem;
      margin-left: 0.4rem;
    }
  }
`;

import { t } from "i18next";
import React from "react";
import { SITE } from "../../utils/settings";
import { Button } from "../Button";
import {
  BtnContainer,
  STD,
  STH,
  STR,
  StyledTable,
  StyledTBody,
  StyledTHead,
} from "./table.styles";
import { useTranslation } from "react-i18next";

const LocationTable = ({ isScreen, data, onEditClick, onDeleteClick }) => {
  const { t } = useTranslation();
  return (
    <StyledTable>
      <StyledTHead>
        <STR>
          <STH>NO</STH>
          <STH>{t("admin.table.photo")}</STH>
          <STH>{t("admin.table.city")}</STH>
          <STH>{t("admin.table.address")}</STH>
          {isScreen && <STH>{t("admin.table.actions")}</STH>}
        </STR>
      </StyledTHead>
      <StyledTBody>
        {(data ?? []).map((location, _idx) => (
          <STR>
            <STD>{_idx + 1}</STD>
            <STD>
              <img
                src={`${SITE.url}${location?.imageUrl ?? ""}`}
                alt={location?.city}
              />
            </STD>
            <STD>{location?.city ?? "-/-"}</STD>
            <STD>{location?.address ?? "-/-"}</STD>

            {isScreen && (
              <STD asFlex>
                <BtnContainer>
                  <Button onClick={() => onEditClick(location)}>
                    {t("buttons.edit")}
                  </Button>
                </BtnContainer>
                <BtnContainer className='delete'>
                  <Button onClick={(e) => onDeleteClick(e, location._id)}>
                    {t("buttons.delete")}
                  </Button>
                </BtnContainer>
              </STD>
            )}
          </STR>
        ))}
      </StyledTBody>
    </StyledTable>
  );
};

export default LocationTable;

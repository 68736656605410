import React from "react";
import SOM from "../../assets/pdf/SOM-GV.pdf";

const ChildProtectionStandards = () => {
  return (
    <div className='child-protection-standards'>
      <div>
        <iframe
          src={SOM}
          title='Standardy ochrony małoletnich - Studio tanca Good vibes'
          width='100%'
          height='700px'
        />
      </div>
    </div>
  );
};

export default ChildProtectionStandards;

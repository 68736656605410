import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GeneralService } from "../services";

export const useGetAllInstructors = () => {
  const { data, isLoading } = useQuery(
    ["all_instructors_user"],
    () => GeneralService.getAllInstructors(),
    {
      onError: (err) => {
        console.log(err);
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );
  return { data, isLoading };
};

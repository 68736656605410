import httpClient from "../httpclient";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("Access_Token") ?? ""}`,
  },
};

export default class AdminService {
  static async getAllDanceStyles() {
    const { data } = await httpClient.get(
      `/admin/get_all_dance_styles`,

      config,
    );
    return data;
  }

  static async createLocation(input) {
    const { data } = await httpClient.post(
      `/admin/create_new_location`,
      input,
      config,
    );
    return data;
  }

  static async deleteLocation(id) {
    const { data } = await httpClient.delete(
      `/admin/delete_location/${id}`,
      config,
    );
    return data;
  }

  static async updateLocation(input, id) {
    const { data } = await httpClient.put(
      `/admin/update_location/${id}`,
      input,
      config,
    );
    return data;
  }

  static async addNewInstructor(input) {
    const { data } = await httpClient.post(
      `/admin/add_new_instructor`,
      input,
      config,
    );
    return data;
  }
  static async getClasses() {
    const { data } = await httpClient.get(`/admin/fetch_all_classes`, config);
    return data;
  }
  static async getAllPhotoSession() {
    const { data } = await httpClient.get(`/admin/get_all_photos`, config);
    return data;
  }

  static async getAllInstructors() {
    const { data } = await httpClient.get(`/admin/get_all_instructors`, config);
    return data;
  }

  static async createClass(input) {
    const { data } = await httpClient.post(
      `/admin/create_new_dance_style`,
      input,
      config,
    );
    return data;
  }

  static async createClass2(input) {
    const { data } = await httpClient.post(
      `/admin/create_new_class`,
      input,
      config,
    );
    return data;
  }

  static async updateClass2(input, id) {
    const { data } = await httpClient.put(
      `/admin/edit_class_2/${id}`,
      input,
      config,
    );
    return data;
  }

  static async updateClass(input, id) {
    const { data } = await httpClient.put(
      `/admin/edit_class/${id}`,
      input,
      config,
    );
    return data;
  }

  static async deleteClass(id) {
    const { data } = await httpClient.delete(
      `/admin/delete_class/${id}`,
      config,
    );
    return data;
  }

  static async deletePhoto(id) {
    const { data } = await httpClient.delete(
      `/admin/delete_photo/${id}`,
      config,
    );
    return data;
  }

  static async deleteClass2(id) {
    const { data } = await httpClient.delete(
      `/admin/delete_class_2/${id}`,
      config,
    );
    return data;
  }

  static async updateEvent(input, id) {
    const { data } = await httpClient.put(
      `/admin/edit_event/${id}`,
      input,
      config,
    );
    return data;
  }
  static async updateInstructor(input, id) {
    const { data } = await httpClient.put(
      `/admin/edit_instructor/${id}`,
      input,
      config,
    );
    return data;
  }
  static async deleteInstructor(id) {
    const { data } = await httpClient.delete(
      `/admin/delete_instructor/${id}`,
      config,
    );
    return data;
  }
  static async deleteStudent(id) {
    const { data } = await httpClient.delete(
      `/admin/delete_student/${id}`,
      config,
    );
    return data;
  }

  static async deleteEvent(id) {
    const { data } = await httpClient.delete(
      `/admin/delete_event/${id}`,
      config,
    );
    return data;
  }

  static async addEvent(input) {
    const { data } = await httpClient.post(
      `/admin/create_event`,
      input,
      config,
    );
    return data;
  }

  static async getAllStudents() {
    const { data } = await httpClient.get("/admin/get_all_students", config);
    return data;
  }

  static async getDashboardStats() {
    const { data } = await httpClient.get("/admin/get_dashboard_stats", config);
    return data;
  }

  static async getDashboardInfo() {
    const { data } = await httpClient.get("/admin/get_dashboard_info", config);
    return data;
  }

  static async editSettings(input) {
    const { data } = await httpClient.put(
      "/admin/edit_settings",
      input,
      config,
    );
    return data;
  }

  static async createPricing(input) {
    const { data } = await httpClient.post(
      "/admin/create_pricing",
      input,
      config,
    );
    return data;
  }
  static async editPricing(input, id) {
    const { data } = await httpClient.put(
      `/admin/edit_pricing/${id}`,
      input,
      config,
    );
    return data;
  }
  static async deletePricing(id) {
    const { data } = await httpClient.delete(
      `/admin/delete_pricing/${id}`,
      config,
    );
    return data;
  }
  static async getAllPricing() {
    const { data } = await httpClient.get(`/admin/get_all_pricing`, config);
    return data;
  }
  static async createVideo(input) {
    const { data } = await httpClient.post(
      `/admin/create_new_video`,
      input,
      config,
    );
    return data;
  }
  static async editVideo(input, id) {
    const { data } = await httpClient.put(
      `/admin/edit_video/${id}`,
      input,
      config,
    );
    return data;
  }
  static async deleteVideo(id) {
    const { data } = await httpClient.delete(
      `/admin/delete_video/${id}`,
      config,
    );
    return data;
  }
  static async getAllVideos() {
    const { data } = await httpClient.get(`/admin/get_all_videos`, config);
    return data;
  }
}

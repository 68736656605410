import React from "react";
import { Button } from "../Button";
import {
  BtnContainer,
  STD,
  STH,
  STR,
  StyledTable,
  StyledTBody,
  StyledTHead,
} from "./table.styles";
import { useTranslation } from "react-i18next";

const StudentTable = ({ isScreen, data, onDeleteClick }) => {
  const { t } = useTranslation();
  return (
    <StyledTable>
      <StyledTHead>
        <STR>
          <STH>NO</STH>
          <STH>{t("admin.table.fullName")}</STH>
          <STH>{t("admin.table.email")}</STH>
          <STH>{t("admin.table.location")}</STH>
          <STH>{t("admin.table.danceStyle")}</STH>
          {isScreen && <STH>{t("admin.table.actions")}</STH>}
        </STR>
      </StyledTHead>
      <StyledTBody>
        {(data ?? []).map((stud, _idx) => (
          <STR>
            <STD>{_idx + 1}</STD>
            <STD>{stud?.fullName ?? "-/-"}</STD>
            <STD>{stud?.email ?? "-/-"}</STD>
            <STD>{stud?.location ?? "-/-"}</STD>
            <STD>
              {stud?.danceStyles?.map((sty, idx) => (
                <p key={idx}>{sty.name}</p>
              )) ?? "-/-"}
            </STD>
            {isScreen && (
              <STD>
                <STD asFlex>
                  {/* <BtnContainer>
                    <Button>Edit</Button>
                  </BtnContainer> */}
                  <BtnContainer className='delete'>
                    <Button onClick={(e) => onDeleteClick(e, stud._id)}>
                      {t("buttons.delete")}
                    </Button>
                  </BtnContainer>
                </STD>
              </STD>
            )}
          </STR>
        ))}
      </StyledTBody>
    </StyledTable>
  );
};

export default StudentTable;

import React from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Button, Input } from "../../../components";
import { StyledButtonLeft } from "../../../components/ModalComponents/comp.styles";
import { LoginPageContainer, StyledLoginForm } from "./styles";
import { useLogin } from "./useLogin";

const LoginScreen = () => {
  const {
    show,
    handleFieldChange,
    fields,
    errors,
    onSubmit,
    isLoading,
    isDisabled,
    handleShowToggle,
  } = useLogin();

  return (
    <LoginPageContainer>
      {/* <PageHeader title='Login' /> */}
      <StyledLoginForm>
        <h1 className='formTitle'>Login to your account.</h1>
        <Input
          name='email'
          type='email'
          placeholder='Enter your email address'
          isRequired
          label='Email Address'
          onChange={handleFieldChange}
          error={errors.email}
          value={fields.email}
        />
        <Input
          name='password'
          rightIcon={
            show ? (
              <AiOutlineEyeInvisible onClick={handleShowToggle} />
            ) : (
              <AiOutlineEye onClick={handleShowToggle} />
            )
          }
          type={show ? "text" : "password"}
          placeholder='Enter your password'
          isRequired
          label='Password'
          value={fields.password}
          onChange={handleFieldChange}
          error={errors.password}
        />
        <Link
          to='/admin-secret/forgot'
          style={{ textDecoration: "none", color: "black" }}
        >
          Forgot Password?
        </Link>
        <StyledButtonLeft>
          <Button
            isLoading={isLoading}
            onClick={onSubmit}
            isDisabled={isDisabled}
          >
            LOGIN
          </Button>
        </StyledButtonLeft>
      </StyledLoginForm>
    </LoginPageContainer>
  );
};

export default LoginScreen;

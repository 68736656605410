import styled, { keyframes } from "styled-components";
import { devices } from "../../styles";

const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

export const StyledInstructorCardContainer = styled.div`
  display: flex;
  align-items: center;
  width: 32%;
  height: 270px;
  padding: 1rem;
  border-radius: 10px;
  margin: 2rem 0px;
  box-shadow: 2px 2px 10px ${({ theme }) => theme.shadow},
    -2px -2px 10px ${({ theme }) => theme.white_inverse};
  :hover {
    box-shadow: 8px 8px 30px ${({ theme }) => theme.shadow},
      -8px -8px 30px ${({ theme }) => theme.white_inverse};
    img {
      filter: grayScale(0) !important;
      transform: scale(1.4);
      animation: ${shake} 1.3s linear;
    }
  }

  @media ${devices.laptopL} {
    width: 30%;
  }

  .instructor_name {
    font-size: 1.3rem;
    color: ${({ theme }) => theme.primary};
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 10px;
    @media ${devices.mobileL} {
      font-size: 0.9rem;
      font-weight: 100;
    }
  }
  .instructor_style {
    font-size: 0.9rem;
    /* margin-bottom: 2rem; */
    font-weight: 100;
    opacity: 0.7;
    @media ${devices.mobileL} {
      font-size: 0.6rem;
      font-weight: 100;
    }
  }

  .image_holder {
    width: 10rem;
    min-width: 5rem;
    height: 100%;
    margin-right: 3rem;
    img {
      width: 100%;
      filter: grayScale(100%);
      height: 100%;
      object-fit: contain;
      transition: 0.3s ease;
    }
    @media ${devices.tablet} {
      width: 6rem;
    }
    @media ${devices.mobileL} {
      min-width: 5rem;
    }
    @media ${devices.fold} {
      margin-right: 0.8rem;
    }
  }
  button {
    background: none;
    border: none;
    border-radius: 0.3rem;
    /* font-family: "Bubblegum Sans", cursive; */
    display: flex;
    align-items: center;
    min-width: 6rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    justify-content: center;
    color: #fff;
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.body};
    svg {
      display: none;
      margin-left: 1.5rem;
    }
    :hover {
      svg {
        display: block;
      }
    }
    @media ${devices.tablet} {
      min-width: 10rem;
    }
    @media ${devices.mobileL} {
      min-width: 5rem;
    }
  }

  @media ${devices.laptop} {
    width: 90%;
  }
  @media ${devices.tablet} {
    height: 250px;
  }
`;

export const InstructorsSocial = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0px;
`;
export const InstructorSocialIcon = styled.a`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  padding: 0.5rem;
  cursor: pointer;
  :hover {
    svg {
      &.facebook {
        fill: #4267b2;
      }
      &.instagram {
        fill: #d33e52;
      }
      &.tiktok {
        fill: #00f2ea;
      }
      fill: ${({ theme }) => theme.primary_bold};
    }
  }
  svg {
    margin: 0px 0.1rem;
    transition: 0.4s ease;
    color: ${({ theme }) => theme.gray_50};
  }
`;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AdminService } from "../../../../network/services";
import { validators } from "../../../../utils/validator";

export const useLocation = (onClose, prev) => {
  const initialFields = {
    city: "",
    address: "",
  };

  const [fields, setFields] = useState(initialFields);
  const [errors, setFormError] = useState(initialFields);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [prevImage, setPrevImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    setPrevImage(prev ? prev.imageUrl : "");
    setFields(prev ? prev : initialFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const onSelectedImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  useEffect(() => {
    if (!selectedImage) {
      setPreviewImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    const error = validators[name](value);
    setFields({ ...fields, [name]: value });
    setFormError((formError) => ({ ...formError, [name]: error }));
  };

  const queryClient = useQueryClient();

  const formData = new FormData();
  formData.append("city", fields.city);
  formData.append("address", fields.address);
  formData.append("image", selectedImage);

  const { isLoading: isSaveLoading, mutate } = useMutation(
    () => AdminService.createLocation(formData),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        queryClient.invalidateQueries(["all_locations"]);
        setFields(initialFields);
        onClose();
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const { isLoading: isUpdateLoading, mutate: update } = useMutation(
    () => AdminService.updateLocation(formData, prev._id),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        onClose();
        setFields(initialFields);

        queryClient.invalidateQueries(["all_locations"]);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };
  const onUpdateSubmit = (e) => {
    e.preventDefault();
    update();
  };

  const isLoading = isSaveLoading || isUpdateLoading;
  const isDisabled = Object.values(errors).some(Boolean);

  return {
    fields,
    isDisabled,
    isLoading,
    onUpdateSubmit,
    onSubmit,
    errors,
    handleFieldChange,
    onSelectedImage,
    previewImage,
    prevImage,
  };
};

import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  StyledFooterBottom,
  StyledFooterContainer,
  StyledFooterContent,
  StyledLinkItem,
  StyledLogo,
  StyledNavigationLinksContainer,
  StyledRulesButton,
} from "./footer.styles";
import { ReactComponent as Logo } from "../../assets/images/good_vibes_logo.svg";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const year = new Date().getFullYear();

  const { pathname } = useLocation();
  const { t } = useTranslation();

  const links = [
    { title: t("navbar.welcome"), to: "/", active: pathname === "/" },
    {
      title: t("navbar.studio"),
      to: "/studio",
      active:
        pathname === "/studio" ||
        pathname === "/studio/srem" ||
        pathname === "/studio/poznan",
    },
    {
      title: t("navbar.timetable"),
      to: "/timetables",
      active:
        pathname === "/timetables" ||
        pathname === "/timetables/poznan" ||
        pathname === "/timetables/srem",
    },
    {
      title: t("navbar.instructors"),
      to: "/instructors",
      active:
        pathname === "/instructors" ||
        pathname === "/instructors/anna" ||
        pathname === "/instructors/dianna" ||
        pathname === "/instructors/milena" ||
        pathname === "/instructors/ola" ||
        pathname === "/instructors/suzie",
    },
    {
      title: t("navbar.events"),
      to: "/events",
      active: pathname === "/events",
    },
    {
      title: t("navbar.pricing"),
      to: "/pricing",
      active: pathname === "/pricing",
    },
    {
      title: t("navbar.contact"),
      to: "/contact",
      active: pathname === "/contact",
    },
  ];

  return (
    <StyledFooterContainer>
      <StyledFooterContent>
        <StyledLogo to='/'>
          <Logo className='logo' />
          {/* <img className='logo' src={logo} alt='logo' /> */}
        </StyledLogo>
        <StyledNavigationLinksContainer>
          {links.map((link, idx) => (
            <StyledLinkItem
              to={link.to}
              className={link.active ? "active" : ""}
              key={idx}
            >
              {link.title}
            </StyledLinkItem>
          ))}
        </StyledNavigationLinksContainer>
        <StyledRulesButton>
          <Link to='/rules'>{t("rules.link")}</Link>
        </StyledRulesButton>
        <StyledRulesButton>
          <Link to='/som'>{`Standardy ochrony małoletnich`}</Link>
        </StyledRulesButton>
      </StyledFooterContent>
      <StyledFooterBottom>
        &copy;{year}. All rights reserved.
      </StyledFooterBottom>
    </StyledFooterContainer>
  );
};

export default Footer;

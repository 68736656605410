import * as React from "react";

export const ThemeContext = React.createContext(null);

export const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = React.useState(
    localStorage.getItem("theme") ?? "light",
  );

  const changeTheme = (theme) => {
    setTheme(theme);
    localStorage.setItem("theme", theme);
  };

  const memoedData = React.useMemo(
    () => ({
      theme,
      changeTheme,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme],
  );

  return (
    <ThemeContext.Provider value={memoedData}>{children}</ThemeContext.Provider>
  );
};

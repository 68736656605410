import styled from "styled-components";
import { devices } from "../../../styles";

export const NotFoundContainer = styled.div`
  * {
    box-sizing: 0;
    margin: 0;
    padding: 0;
    cursor: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/cursors-edge.png"),
      auto;
  }

  background: linear-gradient(to right, white 50%, #383838 50%);
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: white;

  @media ${devices.laptop} {
    background: #383838;
    font-size: 16px;
  }
`;

export const NotFoundContent = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 1000px;
  width: calc(100% - 4rem);
  margin: 0 auto;
  > * {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-width: 500px;
    width: 100%;
    padding: 2.5rem;
  }
  @media ${devices.laptop} {
    flex-flow: column;
    > * {
      max-width: 700px;
      height: 100%;
    }
  }
`;

export const StyledAside = styled.aside`
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/right-edges.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 25px 100%;
  img {
    display: block;
    height: auto;
    width: 100%;
  }
  @media ${devices.laptop} {
    background-image: none;
    background-color: white;
    img {
      max-width: 300px;
    }
  }
`;

export const StyledMain = styled.main`
  text-align: center;
  h1 {
    font-family: "Fontdiner Swanky", cursive;
    font-size: 4rem;
    color: ${({ theme }) => theme.lime};
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 2.5rem;
    em {
      font-style: italic;
      color: ${({ theme }) => theme.lime};
    }
  }
  button {
    font-family: "Fontdiner Swanky", cursive;
    font-size: 1rem;
    color: #383838;
    border: none;
    background-color: #f36a6f;
    padding: 1rem 2.5rem;
    transform: skew(-5deg);
    transition: all 0.1s ease;
    cursor: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/cursors-eye.png"),
      auto;
    &:hover {
      background-color: ${({ theme }) => theme.lime};
      transform: scale(1.15);
    }
  }
`;

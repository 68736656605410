import styled from "styled-components";
import { devices } from "../../styles";

export const StyledBannerContainer = styled.section`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: space-around;
  @media ${devices.laptop} {
    flex-direction: column;
  }
`;

export const StyledBannerLeftContainer = styled.div`
  width: 39%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 3.5rem;
    text-transform: uppercase;
    @media ${devices.tablet} {
      text-align: center;
      font-size: 2.5rem;
    }
    &.bigger {
      font-weight: bolder;
      /* font-family: "Bubblegum Sans", cursive; */
      color: ${({ theme }) => theme.body_inverse};
      @media ${devices.tablet} {
        margin-top: 3rem;
      }
    }
    &.smaller {
      font-size: 2.7rem;
      @media ${devices.tablet} {
        font-size: 2rem;
      }
      color: ${({ theme }) => theme.primary};
    }
  }
  p {
    font-size: 2.2rem;
    color: ${({ theme }) => theme.primary_bold};
  }
  .main_instructor {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    div {
      width: 50%;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
      @media ${devices.tablet} {
        justify-content: center;
      }
    }
    em {
      font-size: 1rem;
      color: ${({ theme }) => theme.primary_bold};
      font-weight: bold;
      /* width: 80%; */
      text-align: center;
      position: relative;
      :after {
        content: "";
        position: absolute;
        background: ${({ theme }) => theme.primary};
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        width: 7rem;
        margin-left: 2rem;
        height: 0.1rem;
        @media ${devices.tablet} {
          display: none;
        }
      }
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    @media ${devices.tablet} {
      justify-content: center;
      button {
        padding: 1.2rem;
        width: 100%;
        margin-bottom: 3rem;
      }
    }
  }

  @media ${devices.laptop} {
    width: 100%;
    padding: 0 1rem;
  }
`;

export const StyledBannerRightContainer = styled.div`
  width: 59%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 0.4s ease;
  }
  @media ${devices.laptop} {
    width: 100%;
    /* min-height: 60vh; */
    /* img {
      width: 180%;
    } */
  }
`;

// content: '';
// display: inline-block;
// width: 6.25rem;
// position: relative;
// border-bottom: 1px solid currentColor;
// margin-left: 1rem;
// top: -0.25rem;

import React from "react";
import {
  InstructorSocialIcon,
  InstructorsSocial,
  StyledInstructorCardContainer,
} from "./instructor.styles";
import { BsArrowBarRight } from "react-icons/bs";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SITE } from "../../utils/settings";

const InstructorsCardItem = ({ instructor }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const cvtToFirstLetter = (alpha) => {
    const val = `${alpha.split(" ")[0]}`;
    return val.toLowerCase();
  };

  return (
    <StyledInstructorCardContainer>
      <div className='image_holder'>
        <img src={`${SITE.url}${instructor.profileImage}`} alt='instructor1' />
      </div>
      <div>
        <h1 className='instructor_name'>{instructor.fullName}</h1>
        <h3 className='instructor_style'>
          {instructor?.danceStyle?.map((ins, idx) => `${ins.name}|`)}
        </h3>
        <InstructorsSocial>
          {instructor.facebookLink && (
            <InstructorSocialIcon
              href={instructor.facebookLink}
              target='_blank'
              rel='noreferrer'
            >
              <FaFacebookF className='facebook' />
            </InstructorSocialIcon>
          )}
          {instructor.instagramLink && (
            <InstructorSocialIcon
              href={instructor.instagramLink}
              target='_blank'
              rel='noreferrer'
            >
              <FaInstagram className='instagram' />
            </InstructorSocialIcon>
          )}
          {instructor.tiktokLink && (
            <InstructorSocialIcon
              href={instructor.tiktokLink}
              target='_blank'
              rel='noreferrer'
            >
              <FaTiktok className='tiktok' />
            </InstructorSocialIcon>
          )}
        </InstructorsSocial>
        <button
          onClick={() =>
            navigate(
              `/instructors/${instructor._id}/${cvtToFirstLetter(
                instructor.fullName,
              )}`,
            )
          }
        >
          {t("buttons.knowMore")} <BsArrowBarRight />
        </button>
      </div>
    </StyledInstructorCardContainer>
  );
};

export default InstructorsCardItem;

/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Button } from "../Button";
import {
  BtnContainer,
  STD,
  STH,
  STR,
  StyledTable,
  StyledTBody,
  StyledTHead,
} from "./table.styles";
import { SITE } from "../../utils/settings";
import { t } from "i18next";

const PhotoSessionTable = ({ isScreen, data, onEditClick, onDeleteClick }) => {
  return (
    <StyledTable>
      <StyledTHead>
        <STR>
          <STH>NO</STH>
          <STH>{t("admin.table.photo")}</STH>
          {isScreen && <STH>{t("admin.table.actions")}</STH>}
        </STR>
      </StyledTHead>
      <StyledTBody>
        {(data ?? []).map((photo, _idx) => (
          <STR>
            <STD>{_idx + 1}</STD>
            <STD>
              <img
                src={`${SITE.url}${photo?.imageUrl ?? ""}`}
                alt={photo?._id}
              />
            </STD>

            {isScreen && (
              <STD asFlex>
                {/* <BtnContainer>
                  <Button onClick={() => onEditClick(photo)}>Edit</Button>
                </BtnContainer> */}
                <BtnContainer className='delete'>
                  <Button onClick={(e) => onDeleteClick(e, photo._id)}>
                    {t("buttons.delete")}
                  </Button>
                </BtnContainer>
              </STD>
            )}
          </STR>
        ))}
      </StyledTBody>
    </StyledTable>
  );
};

export default PhotoSessionTable;

import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { Bar, SEO } from "../../../components";
import { Banner } from "../../../components/Banner";
import { useGetAllInstructors } from "../../../network/queries/useGetAllInstructors";
import { GeneralService } from "../../../network/services";
import { Classes } from "../DanceClasses";
import Instructors from "./Sections/Instructors";
import PhotoGallery from "./Sections/PhotoGallery";
import VideoGallery from "./Sections/VideoGallery";

const HomePage = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery(["all_classes"], () =>
    GeneralService.fetchAllHomeDance(),
  );

  const { data: instructors, isLoading: isLoadingInst } =
    useGetAllInstructors();
  const { data: videos, isLoading: isLoadingVideos } = useQuery(
    ["all_videos"],
    () => GeneralService.getAllVideos(),
  );

  return (
    <div>
      <SEO
        title={t("meta.title.home")}
        description='Goodvibes studio tanca is a dance studio in poland'
      />
      <Banner />
      <Bar />
      {data?.classes.length > 0 && !isLoading && (
        <Classes data={data?.classes} />
      )}
      {instructors?.instructors.length > 0 && !isLoadingInst && (
        <Instructors data={instructors?.instructors} />
      )}
      {videos?.videos.length > 0 && !isLoadingVideos && (
        <VideoGallery data={videos?.videos} />
      )}

      <PhotoGallery />
    </div>
  );
};

export default HomePage;

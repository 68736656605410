import styled from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.1rem;
  transition: 0.4s ease;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  padding: 0.4rem 1.5rem;
  border: none;
  background: ${({ theme }) => theme.primary};
  color: #fff;

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  :hover {
    border-radius: 1rem 0 1rem 1rem;
    /* background: ${({ theme }) => theme.pink}; */
  }
`;

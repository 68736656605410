import React, { useCallback } from "react";
import {
  StyledBannerContainer,
  StyledBannerLeftContainer,
  StyledBannerRightContainer,
} from "./Banner.styles";
import { useSpring, animated as animatedSpring } from "react-spring";
import { Button } from "../Button";
import { JoinUsModal } from "../ModalComponents";
import { useDisclosure } from "../../hooks";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const [{ xy }, set] = useSpring(() => ({ st: 0, xy: [0, 0] }));
  const onMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      set.start({
        xy: [x - window.innerWidth / 2, y - window.innerHeight / 2],
      }),
    [set],
  );
  const interpImgMove = xy.to(
    (x, y) =>
      `perspective(400px) translateY(${x / 10}px) translateX(${-y / 10}px) `,
  );

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();

  return (
    <StyledBannerContainer>
      <StyledBannerLeftContainer>
        <div>
          <h1 className='bigger'>Good Vibes</h1>
          <h1 className='smaller'>{t("hero.title")}</h1>
          <div className='main_instructor'>
            <div>
              <em>{t("hero.with")} SUZIE 🦩</em>
            </div>
          </div>
          <div className='btn'>
            <Button onClick={onOpen}>{t("hero.joinNow")}</Button>
          </div>
        </div>
      </StyledBannerLeftContainer>

      <StyledBannerRightContainer>
        <animatedSpring.img
          onMouseMove={onMove}
          style={{ transform: interpImgMove }}
          src={require("../../assets/images/suzi-pose_outline_blue.png")}
          alt='dance_banner'
        />
      </StyledBannerRightContainer>
      <JoinUsModal isOpen={isOpen} onClose={onClose} />
    </StyledBannerContainer>
  );
};

export default Banner;

import React, { useCallback } from "react";
import {
  StyledpageHeaderContainer,
  StyledPageHeaderLeftContainer,
  StyledPageHeaderRightContainer,
} from "./pageheader.styles";
import { useSpring, animated as animatedSpring } from "react-spring";

const PageHeader = ({ image, title, description, small, contain, center }) => {
  const [{ xy }, set] = useSpring(() => ({ st: 0, xy: [0, 0] }));
  const onMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      set.start({
        xy: [x - window.innerWidth / 2, y - window.innerHeight / 2],
      }),
    [set],
  );
  const interpImgMove = xy.to(
    (x, y) =>
      `perspective(400px) translateY(${x / 40}px) translateX(${-y / 40}px) `,
  );

  return (
    <StyledpageHeaderContainer>
      <StyledPageHeaderLeftContainer noImage={!image} center={center}>
        <div className='content'>
          {small && (
            <div className='section_small_container'>
              <p className='section_small_title'>{small}</p>
            </div>
          )}
          <div>
            <h1>{title}</h1>
            {description && (
              <p className='section_description'>{description}</p>
            )}
          </div>
        </div>
      </StyledPageHeaderLeftContainer>
      {image && (
        <StyledPageHeaderRightContainer>
          <animatedSpring.img
            className={contain ? "contain" : ""}
            onMouseMove={onMove}
            style={{ transform: interpImgMove }}
            src={
              image !== undefined
                ? image
                : require("../../assets/images/group_pose.png")
            }
            alt='pageheader'
          />
        </StyledPageHeaderRightContainer>
      )}
    </StyledpageHeaderContainer>
  );
};

export default PageHeader;

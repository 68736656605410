import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import React from "react";
import { toast } from "react-toastify";
import { StudentTable } from "../../../components";
import { Spinner } from "../../../components/Spinner";
import { AdminService } from "../../../network/services";
import { AdminSecHeader } from "../components/AdminSecHeader";
import { useGetStudents } from "./useGetStudents";
import { useTranslation } from "react-i18next";

const StudentsScreen = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetStudents();

  const queryClient = useQueryClient();

  const { mutate } = useMutation((id) => AdminService.deleteStudent(id), {
    onSuccess: (data) => {
      toast.success(`${data.message}`, {
        position: "top-right",
      });
      queryClient.invalidateQueries(["all_students"]);
    },
    onError: (err) => {
      toast.error(err.response.data, {
        position: "top-right",
      });
    },
  });

  const onDeleteSubmit = (e, id) => {
    e.preventDefault();
    mutate(id);
  };

  return (
    <div>
      <AdminSecHeader title={t("admin.sidebar.allStudents")} />
      {isLoading ? (
        <Spinner />
      ) : (
        <StudentTable
          data={data?.students}
          isScreen
          onDeleteClick={(e, id) => onDeleteSubmit(e, id)}
        />
      )}
    </div>
  );
};

export default StudentsScreen;

import styled from "styled-components";
import { devices } from "../../../styles";

export const StyledContactContainer = styled.section`
  display: flex;
  width: 80%;
  margin: 6rem auto;
  justify-content: space-between;

  @media ${devices.tablet} {
    flex-direction: column-reverse;
  }

  .leftContent {
    width: 58%;
    @media ${devices.tablet} {
      width: 100%;
    }
  }

  .rightContent {
    width: 40%;
    @media ${devices.tablet} {
      width: 100%;
    }
  }
`;

export const StyledAboutUsContainer = styled.div`
  width: 100%;
  padding: 2rem;

  @media ${devices.tablet} {
    padding: 0;
  }

  .top {
    display: flex;
    align-items: center;
    border-bottom: ${({ theme }) => theme.border};
    .image_holder {
      width: 6rem;
      height: 6rem;
      border: 2px solid ${({ theme }) => theme.primary};
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
    div {
      margin-left: 1rem;
      p {
        &.title {
          font-size: 1.1rem;
          font-weight: bold;
          color: ${({ theme }) => theme.text};
        }
        color: ${({ theme }) => theme.gray};
      }
    }
  }

  .description {
    font-size: 0.82rem;
    margin: 2rem 0px;
    color: ${({ theme }) => theme.gray};
    line-height: 1.4rem;
    letter-spacing: 0.07rem;
  }

  .info_container {
    margin: 0.8rem 0px;
    .title {
      font-size: 0.9rem;
      font-weight: bold;
      color: ${({ theme }) => theme.primary};
      margin-bottom: 0.4rem;
    }
    font-size: 0.9rem;
    color: ${({ theme }) => theme.gray};
  }
`;

export const StyledIconFlex = styled.div`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  a {
    transition: 0.3s ease;
    margin: 0 1rem;
    :first-of-type {
      margin: 0 1rem 0 0;
    }
    color: ${({ theme }) => theme.primary};
    opacity: 0.8;

    :hover {
      opacity: 1;
      cursor: pointer;
    }
    svg {
      font-size: 1.5rem;
    }
  }
`;

import { useState } from "react";

export const useDisclosure = (initState = false) => {
  const [state, setState] = useState(initState);
  const isOpen = state;

  const onOpen = () => {
    setState(true);
  };

  const onToggle = () => {
    setState((prev) => !prev);
  };

  const onClose = () => {
    setState(false);
  };

  return { isOpen, onClose, onToggle, onOpen };
};

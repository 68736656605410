import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  img {
    margin: 0 10px;
    object-fit: contain;
    width: 8rem;
    height: 8rem;
  }
`;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useGetDanceStylesByFilter,
  useGetLocations,
} from "../../../../network/queries";
import { AdminService } from "../../../../network/services";
import { validators } from "../../../../utils/validator";

export const useAddNewInstructor = (onClose, prev) => {
  const initialState = {
    fullName: "",
    email: "",
    facebookLink: "",
    instagramLink: "",
    tiktokLink: "",
    about: "",
    location: "",
  };

  const [previewImage, setPreviewImage] = useState(undefined);
  const [selectedImage, setSelectedImage] = useState(null);
  const [prevImage, setPrevImage] = useState("");

  const [fields, setFields] = useState(initialState);
  const [errors, setFormError] = useState(initialState);

  const {
    data,
    isLoading: fetchDanceStyledLoading,
    refetch,
  } = useGetDanceStylesByFilter(fields.location);

  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    setFields(prev ? prev : initialState);
    setPrevImage(prev ? prev.profileImage : "");
    setSelectedValues(prev ? prev.danceStyles?.map((dance) => dance._id) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  useEffect(() => {
    if (fields.location !== "") {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.location]);

  const danceStylesOptions =
    data?.dances?.map((dance) => ({
      label: dance.name,
      value: dance._id,
    })) ?? [];
  const prevdanceStylesOptions = prev
    ? prev?.danceStyles?.map((dance) => ({
        label: dance.name,
        value: dance._id,
      }))
    : [];

  const [checkedState, setCheckedState] = useState();

  // useEffect(() => {
  //   setCheckedState()
  // }, [])

  const onSelectedImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  useEffect(() => {
    if (!selectedImage) {
      setPreviewImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  const remappedStylesOptions = [...danceStylesOptions];

  const isCheckedOnPrev = () => {};

  useEffect(() => {
    const fetchedValues = new Array(danceStylesOptions?.length).fill(false);
    const prevValues = new Array(prevdanceStylesOptions?.length).fill(true);
    setCheckedState([...prevValues, ...fetchedValues]);
  }, [danceStylesOptions?.length, prevdanceStylesOptions?.length]);

  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item,
    );
    setCheckedState(updatedCheckedState);
    // eslint-disable-next-line array-callback-return
    updatedCheckedState.reduce((_sum, currentState, index) => {
      if (
        currentState === true &&
        !selectedValues.includes(danceStylesOptions[index].value)
      ) {
        setSelectedValues((prev) => [...prev, danceStylesOptions[index].value]);
      }
      // return "";
    }, 0);
  };

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    const error = validators[name](value);
    setFields({ ...fields, [name]: value });
    setFormError((formError) => ({ ...formError, [name]: error }));
  };

  const {
    email,
    fullName,
    location,
    about,
    facebookLink,
    instagramLink,
    tiktokLink,
  } = fields;

  const formData = new FormData();
  formData.append("image", selectedImage);
  formData.append("email", email);
  formData.append("fullName", fullName);
  formData.append("facebookLink", facebookLink);
  formData.append("tiktokLink", tiktokLink);
  formData.append("instagramLink", instagramLink);
  formData.append("location", location);
  formData.append("about", about);
  // formData.append("danceStyles", JSON.stringify(selectedValues));

  const queryClient = useQueryClient();

  const { isLoading: isSaveLoading, mutate } = useMutation(
    () => AdminService.addNewInstructor(formData),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        queryClient.invalidateQueries(["all_instructors"]);
        onClose();
        setFields(initialState);
        setSelectedImage(undefined);
        setPreviewImage("");
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const { isLoading: isLoadingUpdate, mutate: update } = useMutation(
    () => AdminService.updateInstructor(formData, prev?._id),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        onClose();
        setFields(initialState);
        setSelectedImage(undefined);
        setPreviewImage("");
        queryClient.invalidateQueries(["all_instructors"]);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  const onUpdateSubmit = (e) => {
    e.preventDefault();
    update();
  };

  const isDisabled = Object.values(errors).some(Boolean);

  const { data: locations } = useGetLocations();

  const cityOptions = locations?.locations.map((loc) => ({
    label: loc.city,
    value: loc.city,
  }));

  const timeOptions = [
    { value: "7:00 AM - 12:00PM", label: "7:00 AM  - 12:00PM" },
    { value: "12:30 PM - 2:00PM", label: "12:30 PM - 2:00PM" },
    { value: "2:00 PM - 5:00PM", label: "2:00 PM - 5:00PM" },
  ];

  const isLoading = isSaveLoading || isLoadingUpdate;

  return {
    onSubmit,
    isLoading,
    errors,
    fields,
    handleFieldChange,
    isDisabled,
    remappedStylesOptions,
    cityOptions,
    checkedState,
    timeOptions,
    handleCheckboxChange,
    fetchDanceStyledLoading,
    previewImage,
    prevImage,
    onSelectedImage,
    onUpdateSubmit,
  };
};

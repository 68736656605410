import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { GeneralService } from "../../../../network/services";
import { StyledContainer } from "./PhotoSession.styles";
import Masonry from "react-masonry-css";
import { useDisclosure } from "../../../../hooks";
import { ImagePreviewModal } from "../../../../components/ModalComponents";
import { SITE } from "../../../../utils/settings";
import { SEO } from "../../../../components";
import { useTranslation } from "react-i18next";

const PhotoSession = () => {
  const { data } = useQuery(["all_home_photos_sessions_no_limit"], () =>
    GeneralService.getAllPhotosNoLimit(),
  );

  const breakpointColumnsObj = {
    default: 4,
    3000: 6,
    2000: 4,
    1200: 3,
    1000: 2,
    500: 1,
  };
  const [preview, setPreview] = useState(null);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleClose = () => {
    setPreview(null);
    onClose();
  };

  const { t } = useTranslation();

  return (
    <div>
      <SEO title={t("meta.title.photoSessions")} />
      <StyledContainer>
        <Masonry breakpointCols={breakpointColumnsObj} className='grid_masonry'>
          {(data?.photos ?? []).map((photo) => (
            <img
              key={photo._id}
              src={`${SITE.url}${photo.imageUrl}`}
              alt=''
              onClick={() => {
                setPreview(`${SITE.url}${photo.imageUrl}`);
                onOpen();
              }}
            />
          ))}
        </Masonry>
      </StyledContainer>
      <ImagePreviewModal
        isOpen={isOpen}
        onClose={handleClose}
        preview={preview}
      />
    </div>
  );
};

export default PhotoSession;

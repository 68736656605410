import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useGetLocations } from "../../../../network/queries";
import { AdminService } from "../../../../network/services";
import { useGetAllInstructors } from "../../../../screens/admin/hooks";
import { validators } from "../../../../utils/validator";

export const useAddClass = (onClose, prev) => {
  const initialFields = {
    name: "",
    location: "",
    day: "",
    beginTime: "",
    endTime: "",
    instructors: "",
  };

  const [fields, setFields] = useState(initialFields);
  const [errors, setFormError] = useState(initialFields);
  const [instructorsArr, setInstructorsArr] = useState([]);

  useEffect(() => {
    setFields(prev ? prev : initialFields);
    setInstructorsArr(prev ? prev?.instructors.map((ins) => ins._id) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev]);

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    const error = validators[name](value);
    setFields({ ...fields, [name]: value });
    setFormError((formError) => ({ ...formError, [name]: error }));

    if (name === "instructors") {
      if (!instructorsArr.includes(value)) {
        setInstructorsArr((prev) => [...prev, value]);
        setFields((prev) => ({ ...prev, instructors: "" }));
      } else {
        toast.error("Instructor already added", {
          position: "top-right",
        });
      }
    }
  };

  const handleInstructorRemove = (id) => {
    setInstructorsArr(instructorsArr.filter((ins) => ins !== id));
  };

  const { data: fetchedInstructors, isLoading: isLoadingInstructors } =
    useGetAllInstructors();

  const getInsName = (id) => {
    const ins = fetchedInstructors?.instructors.filter((ins) => ins._id === id);
    return ins[0].fullName ?? "";
  };

  const queryClient = useQueryClient();

  const { isLoading: isSaveLoading, mutate } = useMutation(
    () =>
      AdminService.createClass({
        ...fields,
        instructors: instructorsArr,
      }),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        onClose();
        queryClient.invalidateQueries(["all_dance_styles"]);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );
  const { isLoading: isLoadingUpdate, mutate: update } = useMutation(
    () =>
      AdminService.updateClass(
        {
          ...fields,
          instructors: instructorsArr,
        },
        prev?._id,
      ),
    {
      onSuccess: (data) => {
        toast.success(`${data.message}`, {
          position: "top-right",
        });
        onClose();
        queryClient.invalidateQueries(["all_dance_styles"]);
      },
      onError: (err) => {
        toast.error(err.response.data, {
          position: "top-right",
        });
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  const onUpdateSubmit = (e) => {
    e.preventDefault();
    update();
  };

  const { data: locations } = useGetLocations();

  const cityOptions = locations?.locations.map((loc) => ({
    label: loc.city,
    value: loc.city,
  }));

  const isDisabled = Object.values(errors).some(Boolean);
  const disabledSelect = isLoadingInstructors;

  const instructorOptions = fetchedInstructors?.instructors.map(
    (ins, _idx) => ({
      label: ins.fullName,
      value: ins._id,
    }),
  );
  const isLoading = isSaveLoading || isLoadingUpdate;

  return {
    fields,
    onSubmit,
    isPoznan: fields.location === "Poznań",
    handleFieldChange,
    errors,
    isDisabled,
    isLoading,
    handleInstructorRemove,
    disabledSelect,
    instructorOptions,
    selectedInstructors: instructorsArr,
    onUpdateSubmit,
    getInsName,
    cityOptions,
  };
};
